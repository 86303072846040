import React, { Component } from 'react';
import * as actions from '../../../redux/actions';
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import "../Profil.css";
import Moment from 'moment';

const wait2 = require("../../images/turkhavak.gif");

const formatDate = Moment().format('DD-MM-YYYY');

const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function getStorageValue(key) {
    // getting stored value
    if (typeof window !== "undefined") {
      const saved = sessionStorage.getItem(key);
      const initial = saved !== null ? JSON.parse(saved) : null;
      return initial;
    }
  }  

class Bilgilerim extends Component {

    constructor(props) {
        super(props);
        this.state = {
          yukleniyor: false,
          Adres: false,
          EPosta: false,
          adres1: "",
          eposta1:"",
        };
    }
    
    componentDidMount(){
        if (this.props.auth.token != null){
            this.props.auth.token = getStorageValue('user');
        }
        this.customerDetail();
    }

    customerDetail = () => {
        this.openYukleniyorModal();
        this.props.customerDetail(this.props.auth.token);

        let deneme = 0;
        let timerOffers = setInterval(() => {
  
        if (this.props.customer.customerDetail.kimlikNo != null)
        {
            if (this.props.customer.customerDetail.kimlikNo.length > 9) {
            deneme = 10;
          } 
        }
        if (deneme == 10)
        {
          clearInterval(timerOffers);
          this.closeYukleniyorModal();
        }

          deneme++;
        }, 500);

     }

    tarihChange = (tarih) => {
         let yil = tarih.substring(0, 4);
        let ay = tarih.substring(5, 7);
        let gun = tarih.substring(8, 10);
        return gun + ' / ' + ay + ' / ' + yil
      }


    
      Adres = (kaydet) => {
       this.setState({ Adres: kaydet });
       if (kaydet){
        this.setState({ adres1: this.props.customer.customerDetail.adres });
        document.getElementById("adres").focus();
    }
  }

      EPosta = (kaydet) => {
        this.setState({ EPosta: kaydet });
        if (kaydet){
            this.setState({ eposta1: this.props.customer.customerDetail.eposta });
            document.getElementById("eposta").focus();
        }
       }

       updateEPosta() {
        let customer = {
          "kimlikNo": this.props.customer.customerDetail.kimlikNo,
          "adres": this.props.customer.customerDetail.adres,
          "ePosta": this.state.eposta1         
        };
        this.props.customerUpdate(customer,this.props.auth.token);
        this.props.customer.customerDetail.eposta =this.state.eposta1;
        this.EPosta(false);
      }

      updateAdres() {
        let customer = {
          "kimlikNo": this.props.customer.customerDetail.kimlikNo,
          "adres": this.state.adres1,
          "ePosta": this.props.customer.customerDetail.eposta         
        };
        this.props.customerUpdate(customer,this.props.auth.token);
        this.props.customer.customerDetail.adres = this.state.adres1;
        this.Adres(false);
      }

      openYukleniyorModal = () => {
        this.setState({ yukleniyor: true });
      };
    
      closeYukleniyorModal = () => {
        this.setState({ yukleniyor: false });
      };

  render() {
    return (
        <div>
         <Modal
          isOpen={this.state.yukleniyor}
          onRequestClose={() => this.closeYukleniyorModal()}
          className="yukleniyor-modal-box"
          style={customStyles}
          ariaHideApp={false}
        >
          <div id="yukleniyor">
                        <img src={wait2} alt="" />
                      </div>
                      <label id="yukleniyorLabel">Lütfen Bekleyiniz...</label>
        </Modal>
            <div className='profil-bilgi-box'>
                <div className='title-box'>
                    <label className='title'>Ad Soyad</label>
                </div>
                <div className='text-box'>
                    <p className='text'>{this.props.customer.customerDetail.adi} {this.props.customer.customerDetail.soyadi}</p>
                </div>
            </div>
            <div className='profil-bilgi-box'>
                <div className='title-box'>
                    <label className='title'>TC Kimlik Numarası</label>
                </div>
                <div className='text-box'>
                    <p className='text'>{this.props.customer.customerDetail.kimlikNo}</p>
                </div>
            </div>
            <div className='profil-bilgi-box'>
                <div className='title-box'>
                    <label className='title'>Doğum Tarihi</label>
                </div>
                <div className='text-box'>
                    <p className='text'>{Moment(this.props.customer.customerDetail.dogumTarihi).format('DD.MM.YYYY')}</p>
                </div>
            </div>
            <div className='profil-bilgi-box'>
                <div className='title-box'>
                    <label className='title'>Gsm Numarası</label>
                </div>
                <div className='text-box'>
                    <p className='text'>+90 {this.props.customer.customerDetail.cep}</p>
                </div>
            </div>
            <div className='profil-bilgi-box'>
                <div className='title-box'>
                    <label className='title'>E - posta</label>
                </div>
                <div className='text-box'>
                    <p className='text' hidden={this.state.EPosta}>{this.props.customer.customerDetail.eposta}</p>
                    <input type="text" id='eposta' className="input-box" hidden={!this.state.EPosta} value={this.state.eposta1} onChange={(event) => this.setState({eposta1: event.target.value})}/>
                </div>
                <div className='button-box'>
                <Link to="#" disabled={true} hidden={this.state.EPosta} className="button" onClick={() => this.EPosta(true)} >
                Düzenle
                </Link>
                <Link to="#" disabled={true} hidden={!this.state.EPosta} className="button" onClick={() => this.updateEPosta()} >
                Kaydet
                </Link>
                </div>
            </div>
            <div className='profil-bilgi-box'>
                <div className='title-box'>
                    <label className='title'>Adres</label>
                </div>

                <div className='text-box'>
                    <p className='text' hidden={this.state.Adres}>{this.props.customer.customerDetail.adres}</p>
                    <input type="text" id='adres' className="input-box" hidden={!this.state.Adres} value={this.state.adres1} onChange={(event) => this.setState({adres1: event.target.value})}/>
                </div>
                <div className='button-box'>
                <Link to="#" disabled={true} hidden={this.state.Adres} className="button" onClick={() => this.Adres(true) } >
                Düzenle
                </Link>
                <Link to="#" disabled={true} hidden={!this.state.Adres} className="button" onClick={() => this.updateAdres() } >
                Kaydet
                </Link>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = state => {
    
    return {
        auth: state.auth,
        customer: state.customer,
    }
}


export default connect(mapStateToProps,actions)(Bilgilerim)