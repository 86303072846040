import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import "./Hakkimizda.css";

export default class Hakkimizda extends Component {
  render() {
    return (
        <div className='Hakkimizda'>
        <Container>
          <h1 className='HakkimizdaTitle'>32 Yıldır Güven Sağlıyoruz</h1>
        </Container>
      </div>
    )
  }
}
