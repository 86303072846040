import React, { Component } from 'react'

export class Kasko extends Component {
  render() {
    return (
      <div>
          <p><b>Araç Kasko Sigortası Nedir?</b></p>
          <p>Kasko, hasar ve kaza durumlarında aracında ortaya çıkan zarar masraflarını karşılar. Bu sayede yüksek hasar masraflarına karşı bütçeni korur. Kasko sigortan aşağıdaki durumlarda devreye girer;</p>
          <ul>
              <li>- Aracının başka bir araçla çarpışması</li>
              <li>- Aracına başka bir aracın ya da yabancı bir maddenin çarpması</li>
              <li>- Aracını iraden dışında bir yere çarpman</li>
              <li>- Üçüncü kişilerin aracına zarar vermesi</li>
              <li>- Aracının ve parçalarının çalınması</li>
              <li>- Aracının yanması</li>
          </ul>
          <p><b>Online Kasko Sigortası Nasıl Alınır?</b></p>
          <p>Kasko sigortanı internet üzerinden hızlı bir şekilde satın alabilirsin. Online kasko teklifi almak için T.C. kimlik ve cep telefonu numaranı yukarıda bulunan teklif alma aracına girmen yeterlidir. Sadece 2 dakika içerisinde kasko tekliflerini tek bir ekranda karşılaştırabilirsin.</p>
          <p>Türk Havak Sigorta olarak kasko teklifleri arasından tercih yapmanı daha da kolaylaştırmak için sana özel ürün önerileri sunuyoruz. “En Kapsamlı”, “En Ucuz” ve “Önerilen Teklif” başlıklarıyla senin için öne çıkardığımız kasko ürünlerini inceleyebilirsin.</p>
          <p>Anlaşmalı sigorta şirketlerinin teklifleri arasından aracını en geniş kapsamla koruma altına almak istiyorsan en geniş teminatlı kaskoyu seçebilir, fiyat benim için tek kriter diyorsan en ucuz kasko önerisini tercih edebilirsin. En uygun fiyata maksimum teminatlara sahip kaskoyu almak için de önerilen teklif seçeneği tam sana göre olacaktır. Sen de ihtiyaçlarına uygun teklifleri inceleyerek internet üzerinden kolayca kasko yaptırabilirsin. Satın alma işlemin tamamlandıktan sonra poliçen e-mail adresine gönderilecektir.</p>
          <p><b>Kredi Kartı ile Online Kasko Alınır Mı?</b></p>
          <p>turkhavaksigorta.com aracılığıyla online olarak kasko sigortası alabilir, ödemelerini banka kartı veya kredi kartı ile gerçekleştirebilirsin. Dilersen kredi kartın ile yaptığın ödemelerde taksitli kasko fırsatlarından da yararlanabilirsin.</p>
          <p><b>Kasko Sigortası Nasıl Hesaplanır?</b></p>
          <p>Araç kasko fiyatları hesaplanırken aracının markası, modeli, üretim yılı, plakası gibi unsurlar dikkate alınır. Aynı zamanda ehliyet süren ve mesleğin de fiyatları etkiler.</p>
          <p>Aracınla daha önce herhangi bir kazaya karışıp karışmadığın da kasko fiyatının hesaplanmasında etkili bir unsurdur. Hasarsız geçirdiğin bir yılın sonunda poliçeni yenilerken hasarsızlık indirimi alabilir, kasko poliçeni daha uygun bir fiyata yaptırabilirsin.</p>
          <p>Kasko fiyat hesaplamasında en önemli kriterlerden biri de teminat kapsamıdır. Çarpma, çarpılma gibi ana teminatların yanında kasko sigortana birçok ek teminat ekletebilirsin. Örneğin mini onarım, manevi tazminat, orijinal cam değişimi, yol yardım ya da ihtiyari mali mesuliyet ek teminatları olan bir kasko sigortasının fiyatı, ek teminatları olmayan bir kaskoya göre daha yüksek olabilir.</p>
      </div>
    )
  }
}

export default Kasko