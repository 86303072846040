import React, { Component } from 'react';
import "./Konut.css";

export default class KonutBanner extends Component {
  render() {
    return (
        <div className="konut-banner">
          <div className="container konut-banner-content">
              <div className="konut-banner-title">
                  <h1>Konut Poliçeniz için</h1>
                  <h1>En uygun teklifler</h1>
                  <h1>sunalım</h1>
              </div>
          </div>
      </div>
    )
  }
}
