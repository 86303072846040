import React, { Component } from 'react';
import {Carousel} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import "./Hero.css";
import * as actions from '../../../redux/actions';
import { API_URL } from '../../../redux/actions/definitions';

class Hero extends Component {
  componentDidMount(){
    setTimeout(()=> {
      this.props.getSlideList();
  })
   
  }
  render() {
    return (
        <div>
          <Carousel className='web'>
            {this.props.admin.slideList!=null&&
            this.props.admin.slideList.map(e=> 
            
              <Carousel.Item >
                <Link to={e.link}>
                  <img
                    className="d-block w-100 hero"
                    src={API_URL  + '/api/Admin/Getimage?link=' +  e.selectedFileWeb}
                    alt="First slide"
                  />
                </Link>
              </Carousel.Item>
            
            )}
          </Carousel>
          <Carousel  className='mobile'>
            {this.props.admin.slideList!=null&&
            this.props.admin.slideList.map(e=>
              <Carousel.Item >
                <Link to={e.link}>
                  <img
                    className="d-block w-100 hero"
                    src={API_URL  + '/api/Admin/Getimage?link=' +  e.selectedFileMobile}
                    alt="First slide"
                  />
                </Link>
              </Carousel.Item>
            )}
          </Carousel>
        </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    admin: state.admin
  }
}

export default connect(mapStateToProps, actions)(Hero);