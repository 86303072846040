import axios from "axios";
import { API_URL } from "./definitions";
import { GET_BLOG_LIST, GET_SLIDE_LIST, GET_CONTENT_LIST } from './types';

export const getBlogList = () => {
    return (dispatch) => {
        axios.get(API_URL + '/api/Admin/GetBlogList')
        .then(response => {
            dispatch({
                type: GET_BLOG_LIST,
                payload: response.data
            })
        }).catch(error => {
            dispatch({
                type: GET_BLOG_LIST,
                payload: error
            })
         })

    }
}

export const getSlideList = () => {
    return (dispatch) => {
        axios.get(API_URL + '/api/Admin/GetSlideList')
        .then(response => {
            dispatch({
                type: GET_SLIDE_LIST,
                payload: response.data
            })
        }).catch(error => {
            dispatch({
                type: GET_SLIDE_LIST,
                payload: error
            })
         })

    }
}

export const getContentList = () => {
    return (dispatch) => {
        axios.get(API_URL + '/api/Admin/GetContentList')
        .then(response => {
            dispatch({
                type: GET_CONTENT_LIST,
                payload: response.data
            })
        }).catch(error => {
            dispatch({
                type: GET_CONTENT_LIST,
                payload: error
            })
         })

    }
}