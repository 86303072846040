/* eslint-disable import/no-anonymous-default-export */
import { GET_JOB_LIST, GET_COMPANY_LIST, GET_GUARANTEE_LIST, GET_BUILD_TYPE_LIST, GET_BUILD_USE_TYPE_LIST, GET_BUILD_YEAR_LIST, GET_TOTAL_FLOOR_LIST, GET_HEALTH_PLAN_LIST, GET_HEALTH_PLAN_TYPE_LIST, GET_INSURED_TYPE_LIST, GET_GUARANTEE_ALL_LIST, REASON_FOR_TRAVEL, GET_DISTRICT, GET_VILLAGE, GET_NEIGHBOURHOOD, GET_STREET, GET_BUILD, GET_INSIDE_DOOR, CALL_ME, POLICE_DEGISIKLIGI, POLICE_IADE, CREDIT_CARD  } from "../actions/types";

const INITIAL_STATE = {
    jobs: [],
    companies: [],
    guarantees: [],
    guaranteeAll: {},
    buildTypes: [],
    buildUseTypes: [],
    buildYears: [],
    totalFloors: [],
    healthPlans: [],
    healthPlanTypes: [],
    insuredTypes: [],
    reasonForTravels: [],
    districts: [],
    villages: [],
    neighbourhoods: [],
    streets: [],
    builds: [],
    insideDoors: [],
    callMe: {},
    policeDegisikligi: {},
    policeIade: {},
    taksit: {}
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_JOB_LIST:
            return {...state, jobs: action.payload}
        case GET_COMPANY_LIST:
            return {...state, companies: action.payload}
        case GET_GUARANTEE_LIST:
            return {...state, guarantees: action.payload}
        case GET_GUARANTEE_ALL_LIST:
            return {...state, guaranteeAll: action.payload}
        case GET_BUILD_TYPE_LIST:
            return {...state, buildTypes: action.payload}
        case GET_BUILD_USE_TYPE_LIST:
            return {...state, buildUseTypes: action.payload}
        case GET_BUILD_YEAR_LIST:
            return {...state, buildYears: action.payload}
        case GET_TOTAL_FLOOR_LIST:
            return {...state, totalFloors: action.payload}
        case GET_HEALTH_PLAN_LIST:
            return {...state, healthPlans: action.payload}
        case GET_HEALTH_PLAN_TYPE_LIST:
            return {...state, healthPlanTypes: action.payload}
        case GET_INSURED_TYPE_LIST:
            return {...state, insuredTypes: action.payload }
        case REASON_FOR_TRAVEL:
            return {...state, reasonForTravels: action.payload}
        case GET_DISTRICT:
            return {...state, districts: action.payload}
        case GET_VILLAGE:
            return {...state, villages: action.payload}
        case GET_NEIGHBOURHOOD:
            return {...state, neighbourhoods: action.payload}
        case GET_STREET:
            return {...state, streets: action.payload}
        case GET_BUILD:
            return {...state, builds: action.payload}
        case GET_INSIDE_DOOR:
            return {...state, insideDoors: action.payload}
        case CALL_ME:
            return {...state, callMe: action.payload}
        case POLICE_DEGISIKLIGI:
            return {...state, policeDegisikligi: action.payload}
        case POLICE_IADE:
            return {...state, policeIade: action.payload}
        case CREDIT_CARD:
            return {...state, taksit: action.payload}
        default:
            return state;
    }
}