import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import "./Kampanya.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import { API_URL } from '../../../redux/actions/definitions';

class Kampanya extends Component {
    componentDidMount (){
        setTimeout(()=> {
            this.props.getContentList();
        },1000)
    }
  render() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        autoplaySpeed:3000
    };
    return (
        <div className='Kampanya'>
            <Container>
            <h1 className='KampanyaTitle'>İndirim. Kampanya. Haber.</h1>
            <div className='KampanyaContainer container'>
                <Slider {...settings}>
                    {this.props.admin.contentList.map(e=> 
                        <div>
                            <div style={{display: 'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', flexWrap: 'wrap'}}>
                                <div style={{width: 550}}>
                                    <img src={API_URL + '/api/Admin/Getimage?link=' + e.selectedFile} alt='Slide 1' className='SlideImage'/>
                                </div>
                                <div style={{width: 450, marginLeft: 30}}>
                                    <div className='KampanyaContent'>
                                        <h3 className='KampanyaSubTitle'>{e.title}</h3>
                                        <p className='KampanyaText'>
                                            {e.text}
                                        </p>
                                        <Link to={'/kampanya/' + e.link} className='KampanyaButton'>Detaylı Bilgi {'>'}{'>'}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Slider>
            </div>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        admin: state.admin
    }
}

export default connect(mapStateToProps, actions)(Kampanya);
