import axios from 'axios';
import React, { Component } from 'react';
import { API_URL } from '../../redux/actions/definitions';

export class OfferLink extends Component {
    constructor (props) {
        super(props);
        this.state = {
            text: "",
            link:""
        }
    }
    componentDidMount(){
        let params = window.location.pathname;
        params=params.replace("/kampanya/","");
        this.setState({link: params})
       setTimeout(()=>{
        this.windowContent();
       },1000)
    }
    windowContent=()=>{
            axios.get(API_URL + '/api/Admin/GetContentByLink?link=' + this.state.link)
            .then(response=>{
                let a = response.data.mainText;
                    this.setState({text: a})
            })
    }
    render() {
        return (
        <div dangerouslySetInnerHTML={{__html: this.state.text}}></div>
        )
    }
}


export default OfferLink;