/* eslint-disable no-undef */
import React, { Component } from 'react';
import "./Header.css";
import { Link, Router, NavLink } from 'react-router-dom';
import { NavDropdown, } from 'react-bootstrap';
import { Button, Input } from 'reactstrap';
import Modal from 'react-modal';
import * as actions from '../../../redux/actions';
import {connect} from 'react-redux';

const logo = require('../../images/turkhavak1.png');
const user = require('../../images/icon/user.png');
const userB = require('../../images/icon/userB.png');
const menu = require('../../images/icon/menu.png');
const close = require('../../images/icon/close.png');
const robot = require('../../images/robotdegilim.png');
const wait2 = require("../../images/turkhavak.gif");
const trafik = require('../../images/icon/trafik_beyaz.png');
const kasko = require('../../images/icon/kasko_beyaz.png');
const dask = require('../../images/icon/dask_beyaz.png');
const tsaglik = require('../../images/icon/tsaglik_beyaz.png');
const seyahat = require('../../images/icon/seyahat_beyaz.png');

const typeChange = [
  require('../../images/icon/show.png'),
  require('../../images/icon/hide.png')
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function getStorageValue(key) {
  // getting stored value
  if (typeof window !== "undefined") {
    const saved = sessionStorage.getItem(key);
    const token = saved != null ? JSON.parse(saved) : null;
    return token;
  }
}

class Header extends Component {
    constructor (props) {
        super(props);
        // this.props.auth.token = getStorageValue('user');
        // this.props.auth.username = getStorageValue('username');
        this.state = {
          openSideBar: false,
          hataModalIsOpen: false,
          openModalLogin: false,
          openModalRegister: false,
          openModalSMS: false,
          openSifremiUnuttum: false,
          openModalSifremiUnuttum: false,
          openModalDogrulama: false,
          yukleniyor: false,
          email: "",
          tc: "",
          vergiNo: "",
          userName: "",
          phoneNumber: "",
          password: "",
          firstName: "",
          lastName: "",
          smsCode: '',
          dogumTarihi: "",
          arabanizShow: false,
          sagliginizShow:false,
          evinizShow:false,
          isinizShow:false,
          digerShow: false,
          hata: "",
          hatabaslik: "",
        }
    }

    componentDidMount() {
      if (this.props.auth.token == null) {
        this.props.auth.token = getStorageValue('user');
      }

      if (this.props.auth.token != null) {
        this.props.getUserName(this.props.auth.token);
      }
    }

    openSideBar = () => {
        this.setState({openSideBar: true})
    }

    closeSideBar = () => {
        this.setState({openSideBar: false})
    }

    openModalLogin = () => {
      this.setState({
        email: "",
        tc: "",
        vergiNo: "",
        userName: "",
        phoneNumber: "",
        password: "",
        errorMessage: "",
      });
        this.setState({openModalLogin: true,openModalRegister: false,})
    }

    openModalRegister = () => {
        this.setState({openModalRegister: true, openModalLogin: false})
    }

    openModalDogrulama = () => {
      this.setState({openModalDogrulama: true, openModalRegister: false})
    }

    openHataModal = (baslik,problem) => {
      this.setState({ hataModalIsOpen: true });
      this.setState({ hata: problem });
      this.setState({ hatabaslik: baslik });
    };

    closeModal = () => {
        this.setState({hataModalIsOpen:false, openModalRegister: false, openModalLogin: false, openModalSMS: false, openModalSifremiUnuttum: false, openModalDogrulama: false})
    }

    closeHataModal = () => {
      this.setState({hataModalIsOpen:false});
  }

    login = () => {

      let problem = "";

      if (this.state.phoneNumber == "" ||
        this.state.phoneNumber.length < 16 ||
        this.state.phoneNumber.length > 16
      ) {
        problem = "Telefon numaranız hatalı? </br>"; 
      }

      if (this.state.password == "") {
        problem += "Şifre boş geçilemez?</br>";
      }

      if (problem != "") {
        this.openHataModal("Eksik Bilgi!",problem);
      }
      else {
        this.openYukleniyorModal();
      let user = {
        email: this.state.email,
        tc: this.state.tc,
        vergiNo: this.state.vergiNo,
        userName: this.state.userName,
        phoneNumber: this.state.phoneNumber.replace(' ', '').replace('(', '').replace(')', '').replace('-','').replace('  ', ''),
        password: this.state.password,
        errorMessage: ''
      }
      
      this.props.userLogin(user);

      let timerOffers = setInterval(() => {
          clearInterval(timerOffers);
          this.closeYukleniyorModal();
          if (user.errorMessage != ""){
            this.openHataModal("Uyarı!",user.errorMessage);
           this.setState({openSifremiUnuttum:true}); 
          }
           else 
           {
          this.props.getUserName(this.props.auth.token);
           this.setState({openModalLogin: false})
           this.setState({
            email: "",
            tc: "",
            vergiNo: "",
            userName: "",
            phoneNumber: "",
            password: "",
            errorMessage: "",
          });

           }
      }, 1000);

    }
  }

  sifremiUnuttum = () => {

    let problem = "";
    if (this.state.phoneNumber == "" ||
      this.state.phoneNumber.length < 16 ||
      this.state.phoneNumber.length > 16
    ) {
      problem = "Telefon numaranız hatalı? </br>"; 
    }

    if (problem != "") {
      this.openHataModal("Eksik Bilgi!",problem);
    }
    else {
      this.openYukleniyorModal();
    let user = {
      mobileNumber: this.state.phoneNumber.replace(' ', '').replace('(', '').replace(')', '').replace('-','').replace('  ', ''),
    }
    
    this.props.forgetPasswordSms(user);

    let timerOffers = setInterval(() => {
      clearInterval(timerOffers);
      this.closeYukleniyorModal();
      if (this.props.auth.sms.isSuccess){
        this.openHataModal("Bilgi!", "Şifre değişikliği için telefonunuza doğrulama kodu gönderilmiştir.");
        this.setState({openModalSifremiUnuttum:true,password:""});
      }

  }, 2000);


  }
}

sifremiDegistir = () => {

  let problem = "";
  if (this.state.smsCode == "") {
    problem = "Onay kodu boş geçilemez?</br>";
  }

  if (this.state.password == "") {
    problem += "Şifre boş geçilemez?</br>";
  }

  if (problem != "") {
    this.openHataModal("Eksik Bilgi!",problem);
  }
  else {
    this.openYukleniyorModal();
  let user = {
    mobileNumber: this.state.phoneNumber.replace(' ', '').replace('(', '').replace(')', '').replace('-','').replace('  ', ''),
    smsKey: this.state.smsCode,
    password: this.state.password,
}
  
  this.props.changePassword(user);

  let timerOffers = setInterval(() => {
    clearInterval(timerOffers);
    this.closeYukleniyorModal();
    if (this.props.auth.pass.isSuccess){
      this.setState({openModalSifremiUnuttum:false});
      this.openHataModal("Bilgi!", "Şifreniz başarılı olarak değiştirilmiştir, giriş yapabilirsiniz.");
    }

}, 1000);


}
}

    register = () => {

      let problem = "";

      if (this.state.smsCode == "") {
        problem = problem + "Doğrulama Kodu boş geçilemez? </br>";
      }
        if (this.state.password == "") {
        problem = problem + "Şifre boş geçilemez? </br>";
      }
      else if (this.state.password.length < 6) 
      {
       problem = problem + "Şifre 6 karakterden küçük olamaz! </br>";
      }
    if (this.state.firstName == "") {
      problem = problem + "Adı boş geçilemez? </br>";
    }
    if (this.state.lastName == "") {
      problem = problem + "Soyadı boş geçilemez? </br>";
    }
    if (this.state.dogumTarihi == "") {
      problem = problem + "Doğum tarihi hatalı? </br>";
    }
    if (
      this.state.phoneNumber == "" ||
      this.state.phoneNumber.length < 16 ||
      this.state.phoneNumber.length > 16
    ) {
      problem = problem + "Telefon numaranız hatalı? </br>"; 
    }
  
    if (problem != "") {
      this.openHataModal("Eksik Bilgi!",problem);
    } else {
      this.openYukleniyorModal();

      var st = this.state.dogumTarihi;
      var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
      var dt = new Date(st.replace(pattern,'$3-$2-$1'));

      let register ={
        email: this.state.email,
        tc: this.state.tc,
        vergiNo: this.state.vergiNo,
        userName: this.state.userName,
        phoneNumber: this.state.phoneNumber,
        password: this.state.password,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        smsCode: this.state.smsCode,
        dogumTarihi: dt
      }

      this.props.userRegister(register);
      
      let timerOffers = setInterval(() => {
        clearInterval(timerOffers);
        this.closeYukleniyorModal();
        if (register.Message != null && register.Message != ""){
          this.openHataModal("Uyarı!",register.Message);
        }
         else 
         {
          this.openHataModal("Tebrikler!","Kayıt işlemi başarılıdır, giriş yapabilirsiniz.");
          this.setState({openModalRegister: false})
          this.setState({
           email: "",
           tc: "",
           vergiNo: "",
           userName: "",
           phoneNumber: "",
           password: "",
           firstName: "",
           lastName: "",
           smsCode: "",
           dogumTarihi: ""
         });
         }
    }, 2000);
    }
  }

    smsDogrulama = (id) => {
      let re = this.state.phoneNumber.replace(' ', '').replace('(', '').replace(')', '').replace('-','').replace('  ', '');
      
      let problem = "";
  
      if (this.state.phoneNumber == "" ||
        this.state.phoneNumber.length < 16 ||
        this.state.phoneNumber.length > 16
      ) {
        problem = "Telefon numaranız hatalı? </br>"; 
      }
  
      if (problem != "") {
        this.openHataModal("Eksik Bilgi!",problem);
      }
      else {
      this.props.setEnter('90' + re);
      
      let timer = setInterval(()=>{
        clearInterval(timer)
        this.stepper(id)
      },1000);
    }
  }

    stepper = (id) => {
      let steps = document.getElementsByTagName("section");
      for (var i = 0; i < steps.length; i++) {
        if (steps[i].id == id) {
          steps[i].className = "active";
        } else steps[i].className = "inactive";
      }
    }

    isNumericInput = (event) => {
      const key = event.keyCode;
      return ((key >= 48 && key <= 57) || // Allow number line
          (key >= 96 && key <= 105) // Allow number pad
      );
  };

  isModifierKey = (event) => {
    const key = event.keyCode;
    return (event.shiftKey == true || key == 35 || key == 36) || // Allow Shift, Home, End
        (key == 8 || key == 9 || key == 13 || key == 46) || // Allow Backspace, Tab, Enter, Delete
        (key > 36 && key < 41) || // Allow left, up, right, down
        (
            // Allow Ctrl/Command + A,C,V,X,Z
            (event.ctrlKey == true || event.metaKey == true) &&
            (key == 65 || key == 67 || key == 86 || key == 88 || key == 90)
        )
  };
  enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if(!this.isNumericInput(event) && !this.isModifierKey(event)){
        event.preventDefault();
    }
};


formatToPhone = (event) => {
  if(this.isModifierKey(event)) {return;}

  const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
  if(input.length == 1&& input == "0") {event.target.value="";return;}
  const areaCode = input.substring(0,3);
  const middle = input.substring(3,6);
  const last = input.substring(6,10);

  if(input.length > 6){event.target.value = `(${areaCode}) ${middle} - ${last}`;}
  else if(input.length > 3){event.target.value = `(${areaCode}) ${middle}`;}
  else if(input.length > 0){event.target.value = `(${areaCode}`;}
  
};

  inputTypeChange = (id) => {
    let a = document.getElementById(id); 
    let imgChange = document.getElementById('imgChange');

    if(a.type == "password") {
      a.type = "text";
      imgChange.src = typeChange[1];
    }else {
      a.type = "password";
      imgChange.src = typeChange[0];
    }
  }

  menuToggele = (id) => {
    let dvA = document.getElementById('dvA');
    let dvS = document.getElementById('dvS');
    let dvE = document.getElementById('dvE');
    let dvI = document.getElementById('dvI');
    let dvD = document.getElementById('dvD');

    dvA.style.display ='none';
    dvS.style.display ='none';
    dvE.style.display ='none';
    dvI.style.display ='none';
    dvD.style.display ='none';
    
    if(id == '1') {
      dvA.style.display = "block";
    }
    else if(id == '2'){
      dvS.style.display =  "block";
    }
    else if(id == '3'){
      dvE.style.display =  "block";
    }
    else if(id == '4'){
      dvI.style.display = "block";
    }
    else if(id == '5'){
      dvD.style.display =  "block";
    }
  }

  openYukleniyorModal = () => {
    this.setState({ yukleniyor: true });
  };

  closeYukleniyorModal = () => {
    this.setState({ yukleniyor: false });
  };

 /*  showDropdownA = () => {
    this.setState({arabanizShow:true,});
  }

  showDropdownS = () => {
    this.setState({sagliginizShow: true});
  }

  showDropdownE = () => {
    this.setState({evinizShow: true});
  }

  showDropdownI = () => {
    this.setState({isinizShow: true});
  }

  showDropdownD = () => {
    this.setState({digerShow:true});
  }

  hideDropdown = () => {
    this.setState({arabanizShow:false, evinizShow: false, sagliginizShow: false, isinizShow: false, digerShow:false});
  }
 */
    render() {
        return (
            <div className='sticky'>
            <header>
              <div className='container-flud flex'>
                  <div className='logo'>
                      <Link to="/"><img src={logo} alt="" /></Link>
                  </div>
                  <div className='nav'>
                      <ul className='flex'>
                        <li>
                          <NavDropdown title="Arabanız" id="navbarScrollingDropdown" /* show={this.state.arabanizShow}
                          onMouseEnter={()=>this.showDropdownA()} 
                          onMouseLeave={()=>this.hideDropdown()} */>
                      <div className='flex'>
                            <div className='link-divider'>
                            <NavDropdown.Item>
                              <div className='link-content'>{'>'} Trafik Sigortası
                                <img src={trafik} alt='Tafik' className='LinkButtonImage'/>
                            </div>
                            <Link to="/trafik">
                            <Button className='link-button' > Hemen Teklif Al </Button>
                            </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                              <div className='link-content'>{'>'} Kasko Sigortası
                                <img src={kasko} alt='Kasko' className='LinkButtonImage'/>
                            </div>
                            <Link to="/kasko">
                            <Button className='link-button'> Hemen Teklif Al </Button>
                            </Link>
                            </NavDropdown.Item>
                            </div>
                            <div className='link-right'>
                            <NavDropdown.Item><Link to='/teklif-form?br=26'>{'>'} İhtiyari Mali Mesuliyet Sigortası</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to='/teklif-form?br=27'>{'>'} Tekne/Yat Sigortaları</Link></NavDropdown.Item>
                            </div>
                            </div>
                          </NavDropdown>
                        </li>
      
                        <li>
                          <NavDropdown title="Sağlığınız" id="navbarScrollingDropdown" /* show={this.state.sagliginizShow}
                          onMouseEnter={()=>this.showDropdownS()} 
                          onMouseLeave={()=>this.hideDropdown()} */>
                      <div className='flex'>
                            <div className='link-divider'>
                            <NavDropdown.Item>
                              <div className='link-content'>{'>'} Tamamlayıcı Sağlık Sigortası
                                <img src={tsaglik} alt='Tamamlayıcı Sağlık' className='LinkButtonImage'/>
                            </div>
                            <Link to="/tamamlayici-saglik">
                            <Button className='link-button' > Hemen Teklif Al </Button>
                            </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                              <div className='link-content'>{'>'} Seyahat Sağlık Sigortası
                                <img src={seyahat} alt='Kasko' className='LinkButtonImage'/>
                            </div>
                            <Link to="/seyahat">
                            <Button className='link-button'> Hemen Teklif Al </Button>
                            </Link>
                            </NavDropdown.Item>
                            </div>
                            <div className='link-right'>
                            <NavDropdown.Item><Link to='/ozel-saglik'>{'>'} Özel Sağlık Sigortası</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to='/teklif-form?br=7'>{'>'} Ferdi Kaza Sigortası</Link></NavDropdown.Item>
                            </div>
                            </div>
                          </NavDropdown>
                        </li>
      
                        <li>
                          <NavDropdown title="Eviniz" id="navbarScrollingDropdown" /* show={this.state.evinizShow}
                          onMouseEnter={()=>this.showDropdownE()} 
                          onMouseLeave={()=>this.hideDropdown()} */>
                            <NavDropdown.Item>
                              <div className='link-content'>{'>'} Dask Sigortası
                                <img src={dask} alt='Dask' className='LinkButtonImage'/>
                            </div>
                            <Link to="/dask">
                            <Button className='link-button'> Hemen Teklif Al </Button>
                            </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                              <div className='link-content'>{'>'} Konut Sigortası
                                <img src={dask} alt='Konut' className='LinkButtonImage'/>
                            </div>
                            <Link to="/konut">
                            <Button className='link-button'> Hemen Teklif Al </Button>
                            </Link>
                            </NavDropdown.Item>
                          </NavDropdown>
                        </li>
      
                        <li>
                          <NavDropdown title="İşiniz" id="navbarScrollingDropdown"/*  show={this.state.isinizShow}
                          onMouseEnter={()=>this.showDropdownI()} 
                          onMouseLeave={()=>this.hideDropdown()} */>
                            <NavDropdown.Item><Link to='/teklif-form?br=14'>{'>'} Yangın Paket Poliçesi</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to='/teklif-form?br=11'>{'>'} Sorumluluk Sigortası</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to='/teklif-form?br=21'>{'>'} İnşaat Sigortası</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to='/teklif-form?br=22'>{'>'} Alacak Sigortası</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to='/teklif-form?br=10'>{'>'} Mühendislik Sigortaları</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to='/teklif-form?br=23'>{'>'} Mesleki Sorumluluk Sigortası</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to='/teklif-form?br=24'>{'>'} Kar Kaybı Sigortası</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to='/teklif-form?br=25'>{'>'} Montaj Sigortası</Link></NavDropdown.Item>
                          </NavDropdown>
                        </li>
      
                        <li>
                          <NavDropdown title="Diğer Sigortalar" id="navbarScrollingDropdown" /* show={this.state.digerShow}
                          onMouseEnter={()=>this.showDropdownD()} 
                          onMouseLeave={()=>this.hideDropdown()} */>
                            <NavDropdown.Item><Link to='/teklif-form?br=9'>{'>'} Nakliyat Sigortası</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to='/teklif-form?br=20'>{'>'} Hayvan Hayat Sigortası</Link></NavDropdown.Item>
                          </NavDropdown>
                        </li>
                        <li>
                          <NavDropdown.Item>
                            {this.props.auth.token == null || this.props.auth.username == null ? (
                              <Link to="" onClick={()=> this.openModalLogin()}>
                                Giriş
                                <img src={user} alt="" style={{width:20, marginLeft: 5}} />
                              </Link>
                            ) : (
                              <Link to="/hesabim">
                                {this.props.auth.username.firstName + " " + this.props.auth.username.lastName}
                                <img src={user} alt="" style={{width:20, marginLeft: 5}} />
                              </Link>
                            )}
                          </NavDropdown.Item>
                        </li>
                      </ul>
                  </div>
                  <div className='toggle'>
                    <div className='navbar'>
                      {this.props.auth.token == null || this.props.auth.username == null ? (
                        <Link to='' className='menu-bars' onClick={()=> this.openModalLogin()} >
                          <img src={user} alt=""/>
                        </Link>
                      ) : (
                        <Link to='/hesabim' className='menu-bars'>
                        <img src={user} alt=""/>
                      </Link>
                      )}
                      
                      <Link to='#' id='menu-toggle' className='menu-bars' onClick={() => this.openSideBar()}>
                        <img src={menu} alt=""/>
                      </Link>
                    </div>
                    <nav className={this.state.openSideBar ? 'nav-menu active' : 'nav-menu'}>
                      <div id='menu' className='nav-menu-items' >
                        <div className='navbar-toggle'>
                          <Link to='#' className='menu-bars' onClick={() => this.closeSideBar()}>
                          <img src={close} alt=""/>
                          </Link>
                        </div>

                        <div className="text-hesabim">
                          {this.props.auth.token == null || this.props.auth.username == null ? 
                            <Link to="" onClick={()=> this.openModalLogin()}>Giriş <img src={userB} alt=""/></Link> : <Link to="/hesabim">Hesabım <img src={userB} alt=""/></Link>
                          }
                        </div>
                        <div className='nav-line'></div>
      
                        <div className="text">
                          <Link to="/">Anasayfa</Link>
                        </div>
                        <div className='nav-line'></div>
                        <div className="text">
                          <div id='toggleButtonA' className='toggleButton' onClick={() => this.menuToggele('1')}>
                            <h6 onClick={() => this.menuToggele('1')}>Arabanız</h6>
                            <img src='' alt=''/>
                          </div>
                          <div id="dvA" className="menuToggle">
                            <div className='menuToggleContent'>
                              <Link to='/kasko'>{'>'} Kasko</Link>
                              <Link to='/trafik'>{'>'} Trafik Sigortası</Link>
                              <Link to='/teklif-form'>{'>'} İhtiyari Mali Mesuliyet</Link>
                              <Link to='/teklif-form'>{'>'} Tekne/Yat Sigortaları</Link>
                            </div>
                          </div>
                        </div>
                        <div className='nav-line'></div>

                        <div className="text">
                          <div id='toggleButtonS' className='toggleButton' onClick={() => this.menuToggele('2')}>
                            <h6 onClick={() => this.menuToggele('2')}>Sağlığınız</h6>
                            <img src='' alt=''/>
                          </div>
                          <div id="dvS" className="menuToggle">
                            <div className='menuToggleContent'>
                              <Link to='/ozel-saglik'>{'>'} Özel Sağlık Sigortası</Link>
                              <Link to='/tamamlayici-saglik'>{'>'} Tamamlayıcı Sağlık Sigortası</Link>
                              <Link to='/seyahat'>{'>'} Seyahat Sağlık Sigortası</Link>
                              <Link to='/teklif-form'>{'>'} Ferdi Kaza Sigortası</Link>
                            </div>
                          </div>
                        </div>
                        <div className='nav-line'></div>

                        <div className="text">
                          <div id='toggleButtonE' className='toggleButton' onClick={() => this.menuToggele('3')}>
                            <h6 onClick={() => this.menuToggele('3')}>Eviniz</h6>
                            <img src='' alt=''/>
                          </div>
                          <div id="dvE" className="menuToggle">
                            <div className='menuToggleContent'>
                              <Link to='/dask'>{'>'} Dask Sigortası</Link>
                              <Link to='/konut'>{'>'} Konut Sigortası</Link>
                            </div>
                          </div>
                        </div>
                        <div className='nav-line'></div>

                        <div className="text">
                          <div id='toggleButtonI' className='toggleButton'onClick={() => this.menuToggele('4')}>
                            <h6 onClick={() => this.menuToggele('4')}>İşyeriniz</h6>
                            <img src='' alt=''/>
                          </div>
                          <div id="dvI" className="menuToggle">
                            <div className='menuToggleContent'>
                              <Link to='/teklif-form'>{'>'} Yangın Paket Poliçesi</Link>
                              <Link to='/teklif-form'>{'>'} Sorumluluk Sigortası</Link>
                              <Link to='/teklif-form'>{'>'} İnşaat Sigortası</Link>
                              <Link to='/teklif-form'>{'>'} Alacak Sigortası</Link>
                              <Link to='/teklif-form'>{'>'} Mühendislik Sigortaları</Link>
                              <Link to='/teklif-form'>{'>'} Mesleki Sorumluluk Sigortası</Link>
                              <Link to='/teklif-form'>{'>'} Kar Kaybı Sigortası</Link>
                              <Link to='/teklif-form'>{'>'} Montaj Sigortası</Link>
                            </div>
                          </div>
                        </div>
                        <div className='nav-line'></div>

                        <div className="text">
                          <div id='toggleButtonD' className='toggleButton' onClick={() => this.menuToggele('5')}>
                            <h6 onClick={() => this.menuToggele('5')}>Diğer Sigortalar</h6>
                            <img src='' alt=''/>
                          </div>
                          <div id="dvD" className="menuToggle">
                            <div className='menuToggleContent'>
                              <Link to='/teklif-form'>{'>'} Nakliyat Sigortası</Link>
                              <Link to='/teklif-form'>{'>'} Hayvan Hayat Sigortası</Link>
                            </div>
                          </div>
                        </div>
                        <div className='nav-line'></div>
                        
                        <div className="text">
                          <Link to="/iletisim">İletişim</Link>
                        </div>
                        <div className='nav-line'></div>
                      </div>
                    </nav>
                  </div>
                </div>
            </header>

            <Modal
              isOpen={this.state.openModalLogin}
              onRequestClose={()=> this.closeModal()}
              className="modal"
              ariaHideApp={false}
            >
              <div className='modal-box'>
                    <div className='close-button' onClick={() => this.closeModal()}>
                      <img src={close} alt="" className='close-icon'/>
                    </div>
                    <h2>Üye Girişi</h2>
                    <br></br>
                    <div className='input-box-register' hidden={this.state.openModalSifremiUnuttum}>
                      <Input type='text' className='modal-input' placeholder='(5__) ___ ____' 
                      onKeyDown={(event)=>this.enforceFormat(event)} onKeyUp={(event)=>this.formatToPhone(event)} id="_input"
                       value={this.state.phoneNumber} onChange={(event) => this.setState({phoneNumber: event.target.value})} />
                      
                      <div className='modal-input'>
                        <Input type='password' id='pass-inp' placeholder='Şifre' value={this.state.password} onChange={(event) => this.setState({password: event.target.value})}/>
                        <button className='modal-span' onClick={()=>this.inputTypeChange('pass-inp')}><img src={typeChange[0]} id="imgChange" alt="" style={{height: 15}} /></button>
                      </div>

                    </div>

                    <div className='input-box-register' hidden={!this.state.openModalSifremiUnuttum}>
                      
                      <Input type='text' className='modal-input' placeholder='Doğrulama Kodu Giriniz' value={this.state.smsCode} onChange={(event) => this.setState({smsCode: event.target.value})} hidden={!this.state.openModalSifremiUnuttum}/>

                      <div className='modal-input'>
                        <Input type='password' id='pass-inp' placeholder='Yeni Şifre' value={this.state.password} onChange={(event) => this.setState({password: event.target.value})}/>
                        <button className='modal-span' onClick={()=>this.inputTypeChange('pass-inp')}><img src={typeChange[0]} id="imgChange" alt="" style={{height: 15}} /></button>
                      </div>

                    </div>

                    <Button
                      active
                      size="lg"
                      className='modal-button w-200'
                      onClick={()=> this.login()}
                      hidden={this.state.openModalSifremiUnuttum}
                    >
                      Üye Girişi
                    </Button>
                    <Button
                      active
                      size="lg"
                      className='modal-button'
                      onClick={()=> this.sifremiDegistir()}
                      hidden={!this.state.openModalSifremiUnuttum}
                    >
                      Şifreyi Değiştir
                    </Button>
                    <div className='modal-link'>
                      <Link to="#" hidden={this.state.openSifremiUnuttum}></Link>
                      <Link to="#" hidden={!this.state.openSifremiUnuttum} onClick={() => this.sifremiUnuttum()}>Şifremi Unuttum</Link>
                      <Link to="#" hidden={this.state.openSifremiUnuttum} className='uye-ol' onClick={() => this.openModalRegister()}>Üye Ol</Link>
                    </div>
                  </div>
            </Modal>
            <Modal
            isOpen={this.state.openModalRegister}
            onRequestClose={()=> this.closeModal()}
            className="modal"
            ariaHideApp={false}
          >
            <div className='modal-box'>
                  <div className='close-button' onClick={() => this.closeModal()}>
                    <img src={close} alt="" className='close-icon'/>
                  </div>
                  <h2 style={{marginBottom:0}}>Üye Ol</h2>
                  <br></br>
                  <section id='step1' className='active'>
                    <div>
                      <Input type='text' className='modal-input' placeholder='(5__) ___ ____'
                        onKeyDown={(event)=>this.enforceFormat(event)} onKeyUp={(event)=>this.formatToPhone(event)} id="_input"
                       value={this.state.phoneNumber} onChange={(event) => this.setState({phoneNumber: event.target.value})} />
                    </div>
                    <div className='text-center'>
                    <Button
                        active
                        size="lg"
                        className='modal-button w-200'
                        onClick={()=> this.smsDogrulama('step2')}
                      >
                        Sms Kodu Al
                      </Button>
                      </div>
                  </section>
                  
                  <section id='step2' className='inactive'>
                    <div className='input-box2'><Input type='text' className='modal-input' placeholder='Doğrulama Kodu Giriniz' value={this.state.smsCode} onChange={(event) => this.setState({smsCode: event.target.value})} /></div>
                    <div className='input-box2'><Input type='text' className='modal-input' placeholder='TC Kimlik No' maxLength="11" value={this.state.tc} onChange={(event) => this.setState({tc: event.target.value.replace(/\D/g, '')})} /></div>
                    <div className='input-box2'><Input type='date' className='modal-input' placeholder='Doğum Tarihi' value={this.state.dogumTarihi} onChange={(event) => this.setState({dogumTarihi: event.target.value})} /></div>
                    <div className='input-box2'><Input type='text' className='modal-input' placeholder='Ad' value={this.state.firstName} onChange={(event) => this.setState({firstName: event.target.value})} /></div>
                    <div className='input-box2'><Input type='text' className='modal-input' placeholder='Soyad' value={this.state.lastName} onChange={(event) => this.setState({lastName: event.target.value})} /></div>
                    <div className='input-box2'><Input type='text' className='modal-input' autoCapitalize='none' placeholder='E-Mail Adresi' value={this.state.email} onChange={(event) => this.setState({email: event.target.value})} /></div>
                      <div className='input-box2'>
                        <Input type='password' id='pass-inp' className='modal-input' maxLength="12" placeholder='Şifre' value={this.state.password} onChange={(event) => this.setState({password: event.target.value})}/>
                        <button className='modal-span' onClick={()=>this.inputTypeChange('pass-inp')}><img src={typeChange[0]} id="imgChange" alt="" style={{height: 15}} /></button>
                      </div>
                      <div style={{fontSize:12}}>
                      <p className='modal-input text-white '>Şifre en az 6 karakter olmalıdır.1 büyük harf,1 küçük harf,sayı ve özel karakter içermelidir</p>
                      </div>
                      <div className='text-center'>
                      <Button
                        active
                        size="lg"
                        className='modal-button w-200'
                        style={{marginTop:0}}
                        onClick={()=> this.register()}
                      >
                        Üye Ol
                      </Button>
                      </div>
                  </section>
                  <div className='modal-link-register w-200'>
                    <Link to="#" className='uye-ol' onClick={() => this.openModalLogin()}>Üye Girişi</Link>
                  </div>
                </div>
            </Modal>
            <Modal
          isOpen={this.state.yukleniyor}
          onRequestClose={() => this.closeModal()}
          className="yukleniyor-modal-box"
          style={customStyles}
          ariaHideApp={false}
        >
          <div id="yukleniyor">
                        <img src={wait2} alt="" />
                      </div>
                      <label id="yukleniyorLabel">Lütfen Bekleyiniz...</label>
        </Modal>
            <Modal
          isOpen={this.state.hataModalIsOpen}
          onRequestClose={() => this.closeHataModal()}
          className="odeme-modal-box"
          style={customStyles}
          ariaHideApp={false}
        >
          <div className="hata-modal-container">

            <h4>
              <b>Uyarı!</b>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: this.state.hata }}>
         </div>
         <br></br>
         <Button onClick={() => this.closeHataModal()}>Tamam</Button>
          </div>
        </Modal>
          </div>
        )
    }
}
const mapStateToProps = state => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps, actions)(Header);