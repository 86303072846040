import React from 'react';
import TopHeader from '../common/header/TopHeader';
import Header from '../common/header/Header';
import FooterLayout from '../common/footer/Footer';

import "./layout.css";
import { Link } from 'react-router-dom';

const phone = require('../images/phone.png');
const whatsapp = require('../images/whatsapp.png');

const App = ({ children }) => {
  return (
    <div className="layout">
        <TopHeader/>
        <Header/>
        <main>
            <div >{children}</div>
        </main>
        <FooterLayout/>
        <div className='phone-sticky-left'>
        <a  href="tel:4440483">
        <img src={phone} alt="" className="sticky-img"/>
          </a>
        </div>
        <div className='phone-sticky-right'>
          <a target='_blank' href="https://wa.me/905331234188">
          <img src={whatsapp} alt="" className="sticky-img"/>
          </a>
        </div>
    </div>
  )
}
export default App;