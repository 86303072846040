import React, { Component } from 'react'

class MesafeliSatis extends Component {
  render() {
    return (
      <div>
          <p><b>MADDE 1: TARAFLAR</b></p>
                <p>İşbu sözleşme TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ. internet sitesi üzerinden sigorta ürünü satın almak isteyen ALICI ile SATICI arasında aşağıdaki hüküm ve koşullar dahilinde Finansal Hizmetlere İlişkin Mesafeli Sözleşmeler Yönetmeliği’ne uygun olarak akdedilmiştir.</p>
                <p><b>MADDE 2: KONU</b></p>
                <p>İşbu mesafeli satış sözleşmesinin konusu, sigorta ürünlerine ilişkin teklif, poliçe ve elektronik ortamda mesafeli olarak satış hizmetiyle ilgili tarafların 6502 sayılı Tüketicinin Korunması Hakkında Kanun, Mesafeli Sözleşmeler Yönetmeliği, 28982 sayılı Sigortacılık Kapsamında Değerlendirilecek Faaliyetlere, Tüketici Lehine Yapılan Sigorta Sözleşmeleri İle Mesafeli Akdedilen Sigorta Sözleşmelerine İlişkin Yönetmelik (beraber “Mevzuat” olarak anılacaktır.) çerçevesinde hak ve yükümlülüklerin belirlenmesidir.</p>
                <p>ALICI, işbu sözleşmeyi okuduğunu ve kabul ettiğini onaylamadan, sonraki adımlara geçemeyecek ve poliçe teklifi ve elektronik ortamda mesafeli olarak sigorta ürünü satın alım işlemlerini gerçekleştiremeyecektir.</p>
                <p>ALICI, TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ. ’nin isim, unvan, açık adres, telefon ve diğer erişim bilgileri, satışa konu sigorta ürününün temel nitelikleri, vergiler dâhil olmak üzere satış fiyatı, ödeme şekli, teslimat ve iade koşulları ve masrafları vs. satışa konu mal ile ilgili tüm ön bilgiler ve “cayma” hakkının kullanılması ve bu hakkın nasıl kullanılacağı, şikâyet ve itirazlarını iletebilecekleri resmi makamlar vs. konusunda açık, anlaşılır ve internet ortamına uygun şekilde SATICI tarafından bilgilendirildiğini, bu önbilgileri elektronik ortamda teyit ettiğini ve sonrasında sigorta ürünü aldığını işbu Sözleşme hükümleri çerçevesinde kabul, beyan ve taahhüt eder.</p>
                <p>ALICI, SATICI’nın poliçe teklifi ve satışı işlemleri için ve bu amaçla sınırlı olmak üzere kişisel verilerini işleme yetkisi verdiğini kabul ve beyan etmektedir. Ayrıca SATICI, ALICI’nın kişisel verilerini işbu sözleşme amaçları ile sınırlı olmak kaydıyla gerektiğinde kamu kurumları ve iş ortakları ile paylaşabilecektir.</p>
                <p><b>MADDE 3: SATIŞA KONU ÜRÜN/ÜRÜNLER, ÖDEME BİLGİLERİ VE TESLİMAT</b></p>
                <p>Ürünün özelliklerine (cinsi ve türü) ilişkin bilgiler her sigorta ürününün türüne göre değişiklik göstermekte olup, ürün seçildiğinde ürünün bedeli kullanıcı ekranında görüntülenmektedir. Bu bilgiler ALICI tarafından da onaylanmıştır.</p>
                <p>Ödeme Şekli: Sadece Kredi Kartı ile ödeme kabul edilmektedir.</p>
                <p>Satın alınan sigorta ürününe dair poliçeyi ALICI pdf formatında internet sitesinden indirebilecektir. Ayrıca SATICI tarafından ALICI’ya poliçe elektronik posta ile de gönderecektir. ALICI tarafından verilen elektronik posta adresinin yanlış olmasından veya başkasına ait olmasından dolayı poliçenin ulaşmaması durumunda ALICI SATICI’dan herhangi bir talepte bulunamayacağını kabul, beyan ve taahhüt eder.</p>
                <p><b>MADDE 4: SÖZLEŞME TARİHİ</b></p>
                <p>İşbu sözleşme ALICI’nın seçtiği sigorta türüne göre yapması gereken ödemeyi yaptığı anda akdedilmiş sayılır.</p>
                <p><b>MADDE 5: GENEL HÜKÜMLER</b></p>
                <p>ALICI “www.turkhavaksigorta.com” internet sitesinde sözleşme konusu ürünün tüm özelliklerine, temel nitelikleri, kapsadığı rizikoları ve tüm poliçe koşullarını bildiğini, satış fiyatı ve ödeme şekli ile teslimata ilişkin ön bilgileri okuyup bilgi sahibi olduğunu ve elektronik ortamda gerekli teyidi verdiğini kabul, beyan ve taahhüt eder.</p>
                <p>ALICI, işbu Sözleşme çerçevesinde verdiği kişisel bilgilerin doğru olduğunu beyan ve taahhüt eder. Kişisel bilgilerin doğruluğundan ALICI sorumludur. SATICI’nın bu bilgilerin doğru olmaması nedeniyle uğrayacağı tüm zararlardan ALICI sorumludur.</p>
                <p>ALICI, doğruluğu, güncelliği ve verdiği bilgilerin kendisine ait olduğu veya üçüncü kişilere ait olmakla birlikte sigorta ürünü satın alabilmek veya üçüncü kişileri lehdar olarak göstermek üzere gerekli açık rızanın kendisi tarafından alınmış olduğunu, bu bilgilerle ilgili olarak bir ihlal ortaya çıkması ve SATICI’nın bu ihlal nedeniyle bir zarar görmesi durumunda SATICI’nın zararlarını nakden ve defaten tazmin edeceğini kabul, beyan ve taahhüt eder.</p>
                <p>Sözleşmeye konu sigorta ürününe ait korumanın başlamış sayılması için işbu mesafeli satış sözleşmesi ile ön bilgilendirme formunun elektronik ortamda teyit edilmiş olması ve ürün/ürünlerin bedelinin ALICI tarafından kredi kartı ile ödenmiş olması şarttır. Herhangi bir nedenle ürün/ürünlerin bedeli ödenmez veya banka kayıtlarında iptal edilir ise, taraflar arasında bir sigorta sözleşmesinin kurulmamış olacağını taraflar kabul, beyan ve taahhüt eder.</p>
                <p>Sözleşmeye konu ürüne/ürünlere ilişkin ödemenin ALICI tarafından kredi kartı ile yapılması durumunda, ALICI ile kredi kartı sahibinin ya da ürünün/ürünlerin teslim edileceği kişinin farklı olmasından kaynaklanabilecek olan, kredi kartının veya banka kartının yetkisiz kişilerce haksız ve hukuka aykırı olarak kullanılması da dahil olmak üzere türlü hukuki risk, ALICI’ya aittir. ALICI, bahsi geçen durumlarda herhangi bir şekilde zarara uğraması halinde avukatlık ücretleri ve dava masrafları dahil SATICI’dan hiçbir talepte bulunmayacağını kabul, beyan ve taahhüt eder.</p>
                <p>ALICI, “www.turkhavaksigorta.com” internet sitesi üzerinden kredi kartı gerektiren işlemlerde kredi kartı malikinin rızası ile işlem yapacağını; aksi halde ödeme iadesi, hizmeti ifa etmeme veya benzeri sebeplerden kaynaklanabilecek, avukatlık masrafları da dâhil olmak üzere her türlü masraflardan ve zarardan münhasıran sorumlu olacağını ve SATICI’yı bunlardan kaynaklanabilecek her türlü talepten âri tutacağını kabul, beyan ve taahhüt eder.</p>
                <p>SATICI, sipariş konusu ürün veya hizmetin yerine getirilmesinin baştan veya sonradan imkânsızlaşması halinde sözleşme konusu yükümlülüklerini yerine getiremezse, bu durumu, öğrenmesi ile birlikte yazılı olarak ALICIYA bildireceğini, tahsil edilmiş bir bedel olması durumunda hizmetinden yararlanılmamış police süresi dikkate alınarak hesaplanan bedeli ALICI’ya iade edeceğini kabul, beyan ve taahhüt eder.</p>
                <p>ALICI, spam, virüs, Truva atı ve bunlarla sınırlı olmaksızın benzeri diğer kötü niyetli yazılımlar ile İnternet Sitesi’nin çalışmasını durdurucu veya engelleyici iş, işlem veya eylemlerde bulunmayacağını veya bu yönde iş, işlem veya eylemlerde bulunan üçüncü kişilerle herhangi bir ilişki içerisinde olmayacağını kabul, beyan ve taahhüt eder.</p>
                <p>ALICI işbu sözleşme konusu hizmeti aldığı an itibariyle poliçenin klozlarını kontrol etmeyi kabul ve taahhüt eder.</p>
                <p>ALICI’ya ait kredi kartının alıcının kusurundan kaynaklanmayan bir şekilde yetkisiz kişilerce haksız veya hukuka aykırı kullanılması nedeniyle banka veya finans kuruluşunun prim bedelini SATICI’ya ödememesi halinde sigorta sözleşmesi hükümlerini doğurmaya başlamayacak ve kendiliğinden geçersiz hale gelecektir. Bu durumda sözleşme konusu ürünün/poliçenin ALICI’ya önceden teslim edilmesi durumu oluşursa, ALICI, kendisine yapılan bildirim tarihinden başlamak üzere 3 gün içerisinde SATICI’ya ilgili ürün/poliçeyi iade edeceğini kabul, beyan ve taahhüt eder.</p>
                <p><b>MADDE 6: CAYMA HAKKI</b></p>
                <p>ALICI, sözleşmenin imzalandığı tarihten itibaren 14 (ondört) gün içinde herhangi bir gerekçe göstermeksizin ve cezai şart ödemeksizin sözleşmeden cayma hakkına sahiptir. İşbu 14 (ondört) günlük süre, tüketicinin sözleşmenin imzalandığı günden itibaren başlar. Cayma hakkının kullanılması için bu süre içinde SATICI’ya aşağıda detayları belirtilen iletişim bilgileri vasıtasıyla ALICI yazılı olarak bildirimde bulunmak zorundadır. ALICI bu sure zarfında cayma beyanını belirtilen şekilde bildirmediği takdirde cayma hakkını kaybeder.</p>
                <p>ALICI nın tüketici olmadığı durumlarda bu cayma hakkını kullanma hakkı bulunmamaktadır. Tüketici olmayan ALICI ancak TTK md. 1430(3) ‘e göre cayabilir. Buna göre sigorta ettiren sigorta şirketinin sorumluluğu başlamadan önce, kararlaştırılmış primin yarısını ödeyerek sözleşmeden cayabilir. Sözleşmeden kısmi cayma halinde sigorta ettirenin ödemekle yükümlü olduğu prim, cayılan kısma ilişkin primin yarısıdır. Alıcı tüketici sigortalı ise Tüketici Mevzuatından kaynaklanan hakkının yanında dilerse bu hakkını da kullanması mümkündür.</p>
                <p><b>MADDE 7: CAYMA HAKKININ KULLANIMI</b></p>
                <p>(1) Cayma hakkının kullanıldığına dair bildirimin cayma hakkı süresi içinde, yazılı olarak veya kalıcı veri saklayıcısı ile SATICI’ya yöneltilmiş olması yeterlidir.</p>
                <p>(2) İnternet sitesi üzerinden tüketiciye cayma hakkı sunulması durumunda SATICI, ALICI’nın cayma taleplerinin kendisine ulaştığına ilişkin teyit bilgisini, ALICI’ya derhal iletmek zorundadır.</p>
                <p><b>MADDE 8: CAYMA HAKKINDA SATICININ YÜKÜMLÜLÜKLERİ</b></p>
                <p>(1) SATICI, cayma hakkının kullanıldığına ilişkin bildirimin kendisine ulaştığı tarihten itibaren otuz gün içinde, Finansal Hizmetlere İlişkin Mesafeli Sözleşmeler Yönetmeliği’nin 11 inci maddesinin birinci fıkrasında belirtilen masraf ve bedeller hariç tüm ödemeleri ALICI’ya iade etmekle yükümlüdür. Sigortaya ilişkin sözleşmelerde ise iade süresi hakkında diğer mevzuatta yer alan ALICI lehine olan hükümler uygulanır.</p>
                <p>(2) SATICI, birinci fıkrada belirtilen tüm geri ödemeleri, ALICI’nın satın alırken kullandığı ödeme aracına uygun bir şekilde ve tüketiciye herhangi bir masraf veya yükümlülük getirmeden tek seferde yapmak zorundadır.</p>
                <p>(3) ALICI’nın sözleşmenin kurulmasına dair kabul beyanı kullanılan iletişim araçlarına uygun olarak fiziki veya elektronik ortamda tespit veya kayıt edilir. SATICI, cayma hakkının iletilmesi ile fiziki veya elektronik ortamda yapılacak tespit veya kayıtlar için gerekli önlemleri almakla yükümlüdür.</p>
                <p><b>MADDE 9: CAYMA HAKKINDA ALICININ YÜKÜMLÜLÜKLERİ</b></p>
                <p>(1) ALICI, cayma bildirimini yönelttiği tarihten itibaren en geç otuz gün içinde sözleşme ve yan sözleşme uyarınca ifa edilen hizmet bedelleri ile varsa bir kamu kurum veya kuruluşuna ya da üçüncü kişilere ödenmiş olan masraflar ile mevzuat gereği ödenmesi zorunlu olan bedelleri SATICI’ya iade etmekle yükümlüdür. Bu süre içinde ALICI’nın gerekli iade ve ödemeleri yapmaması halinde sözleşmeden caymadığı kabul edilir. Ödenmesi gereken bedel sözleşmede öngörülen hizmetin toplam bedeli ile karşılaştırıldığında, hizmetin ifa edilen payına düşen miktarını aşamaz ve herhangi bir şekilde cezai şart olarak yorumlanamaz. Bu fıkranın uygulanmasında diğer mevzuatta yer alan ALICI lehine olan hükümler saklıdır.</p>
                <p>(2) Cayma hakkının kullanılması nedeniyle turkhavaksigorta tarafından düzenlenen kampanya limit tutarının altına düşülmesi durumunda, kampanya kapsamında faydalanılan indirim miktarı da iptal edilecektir.</p>
                <p><b>MADDE 10: CAYMA HAKKININ KULANIMININ YAN SÖZLEŞMELERE ETKİSİ</b></p>
                <p>(1) ALICI’nın cayma hakkını kullanması halinde, varsa yan sözleşme de, herhangi bir tazminat veya cezai şart ödeme yükümlülüğü söz konusu olmaksızın sona erer.</p>
                <p>(2) SATICI, ALICI’nın cayma hakkını kullandığını yan sözleşmenin tarafı olan üçüncü kişiye yazılı olarak veya kalıcı veri saklayıcısı ile derhal bildirir.</p>
                <p>Cayma Hakkına Dair Bildirimin yapılacağı İletişim Bilgileri;</p>
                <p>TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ.</p>
                <p>MERSİS NO: 0879065403500018</p>
                <p>Adres: Havaalanı Cad. No: 1 Yeşilköy Bakırköy, İstanbul</p>
                <p>Telefon: 444 0 483</p>
                <p>Eposta Adresi: info@turkhavaksigorta.com</p>
                <p>KEP Adresi: turkhavaksigorta@hs03.kep.tr</p>
                <p>ALICI, Seyahat Sağlık, DASK ve Trafik sigortası gibi zorunlu bazı sigorta ürünlerinde getirilebilecek yasal gereklilikler nedeniyle ve hali hazırda www. turkhavaksigorta.com sitesinde de yayınlanan Seyahat Sağlık ve Dask Sigortası genel şartlarında belirtilen hususlarda ve bu hususların gerçekleşmemesi halinde cayma hakkının kullanılamayacağını bildiğini kabul, beyan ve taahhüt eder.</p>
                <p><b>MADDE 11: MÜCBİR SEBEP</b></p>
                <p>SATICI, tarafların iradesi dışında gelişen, önceden öngörülemeyen ve tarafların borçlarını yerine getirmesini engelleyici ve/veya geciktirici hallerin oluşması gibi mücbir sebepler halleri nedeni ile sözleşme konusu ürünü süresi içinde teslim edemez ise, durumu ALICI'ya bildireceğini kabul, beyan ve taahhüt eder. ALICI da mücbir sebep durumunda ve kendisine bildirim gelmesi kapsamında, siparişin iptal edilmesini ve/veya teslimat süresinin engelleyici durumun ortadan kalkmasına kadar ertelenmesini SATICI’dan talep etme hakkını haizdir. Bu durumda police/ürün tutarı, siparişin ALICI tarafından iptal edilmesi ile ilgili bankaya iade edilir. ALICI, SATICI tarafından kredi kartına iade edilen tutarın banka tarafından ALICI hesabına yansıtılmasına ilişkin ortalama sürecin 2 ile 3 haftayı bulabileceğini, bu tutarın bankaya iadesinden sonra ALICI’nın hesaplarına yansıması halinin tamamen banka işlem süreci ile ilgili olduğundan, ALICI, olası gecikmeler için SATICI’yı sorumlu tutamayacağını kabul, beyan ve taahhüt eder.</p>
                <p><b>MADDE 12: YETKİLİ MAHKEME</b></p>
                <p>İşbu Mesafeli Satış Sözleşmesi'nin uygulanmasında, Gümrük ve Ticaret Bakanlığınca ilan edilen değere kadar Alıcının Mal veya Hizmeti satın aldığı ve ikametgahının bulunduğu yerdeki Tüketici Hakem Heyetleri ile Tüketici Mahkemeleri yetkilidir. 6502 Sayılı Tüketicinin Korunması Hakkında Kanun'un 68'nci. Maddesinin 1. fıkrasında belirtilen alt ve üst limitler doğrultusunda tüketici talepleri hakkında ilçe/il tüketici hakem heyetleri yetkilidir.</p>
                <p>Tüketici sıfatını haiz olmayan ALICI için, taraflar arasında işbu Sözleşme’den doğabilecek uyuşmazlıkların çözümünde İstanbul Merkez Mahkemeleri ve İcra Müdürlükleri yetkilidir.</p>
                <p>İşbu mesafeli satış sözleşmesinin elektronik ortamda taraflarca okunup, kabul edilip, teyit edilmiştir.</p>
      </div>
    )
  }
}

export default MesafeliSatis