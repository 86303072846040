import React, { Component } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const logo = require("../../images/turkhavak.png");
const turkLogo = require("../../images/turklogo.png");
const facebook = require("../../images/icon/facebook.png");
const instagram = require("../../images/icon/instagram.png");
const adres = require("../../images/icon/pin.png");
const whatsapp = require("../../images/icon/whatsappS.png");
const mobilephone = require("../../images/icon/iphone.png");
const phone = require("../../images/icon/phoneS.png"); 

export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div className='container'>
              <div className='FooterContainer'>
                <div className='FooterBox'>
                    <div>
                      <img src={logo} alt='' className='FooterImage'/>
                    </div>
                    <a href="/hakkimizda" className='FooterButton'>Hakkımızda</a>
                    <a href="/" className='FooterButton'>Bilmeniz Gerekenler</a>
                    <a href="/iletisim" className='FooterButton'>İletişim</a>
                </div>
                <div className='FooterLine'></div>
                <div className='FooterRow'></div>
                <div className='FooterBox'>
                    <h5>Ürünler</h5>
                    <Link to='/trafik' className='FooterButton'>Zorunlu Trafik Sigortası</Link>
                    <Link to='/kasko' className='FooterButton'>Kasko</Link>
                    <Link to='/dask' className='FooterButton'>DASK</Link>
                    <Link to='/seyahat' className='FooterButton'>Seyahat Sağlık Sigortası</Link>
                    <Link to='/tamamlayici-saglik' className='FooterButton'>Tamamlayıcı Sağlık Sigortası</Link>
                </div>
                <div className='FooterLine'></div>
                <div className='FooterRow'></div>
                <div className='FooterBox'>
                    <h5>Bilgi Merkezi</h5>
                    <a href="/bilgi-merkezi?bilgi=acik-riza-metni" className='FooterButton'>Açık Rıza Metni</a>
                    <a href="/bilgi-merkezi?bilgi=kvkk" className='FooterButton'>Kişisel Verilerin Korunması</a>
                    <a href="/bilgi-merkezi?bilgi=gizlilik-cerez-korumasi" className='FooterButton'>Gizlilik ve Çerez Koruması</a>
                    <a href="/bilgi-merkezi?bilgi=mesafeli-satis-sozlesmesi" className='FooterButton'>Mesafeli Satış Sözleşmesi</a>
                </div>
                <div className='FooterLine'></div>
                <div className='FooterRow'></div>
                <div className='FooterBox'>
                    <h5>Rehber</h5>
                    <a href="/rehber?brans=trafik" className='FooterButton'>Zorunlu Trafik Sigortası Nedir?</a>
                    <a href="/rehber?brans=kasko" className='FooterButton'>Araç Kasko Sigortası Nedir?</a>
                    <a href="/rehber?brans=dask" className='FooterButton'>Dask Sigortası Nedir?</a>
                    <a href="/rehber?brans=konut" className='FooterButton'>Konut Sigortası Nedir?</a>
                    <a href="/rehber?brans=tss" className='FooterButton'>Sağlık Sigortası Nedir?</a>
                </div>
                <div className='FooterLine'></div>
                <div className='FooterRow'></div>
                <div className='FooterBox'>
                    <h5>İletişim Bilgileri</h5>
                    <div className="footer-iletisim">
                      <img src={phone} alt="" className="footer-icon" />
                      <div className="footer-iletisim-line"></div>
                      <Link to='tel:4440483' className='FooterButton address'>444 0 483</Link>
                    </div>
                    <div className="footer-iletisim">
                      <div className="footer-iletisim-licon-box">
                        <img src={mobilephone} alt="" className="footer-icon" />
                        <img src={whatsapp} alt="" className="footer-icon" />
                      </div>
                      <div className="footer-iletisim-line"></div>
                      <div>
                        <Link to='tel:05331234188' className='FooterButton address'>0533 1234 188</Link><br/>
                        <Link to='tel:05331234189' className='FooterButton address'>0533 1234 189</Link><br/>
                        <Link to='tel:05380437110' className='FooterButton address'>0538 0437 110</Link>
                      </div>
                    </div>
                    <div className="footer-iletisim">
                      <img src={adres} alt="" className="footer-icon" />
                      <div className="footer-iletisim-line"></div>
                      <div className='FooterButton address'>Havaalanı Cad. No: 1 THY A.O. Genel Müdürlük Binası Yanı Yeşilköy Bakırköy, İstanbul</div>
                    </div>
                    <div className="footer-iletisim d-flex flex-row">
                      <Link to="" className='FooterButton'>
                        <img src={facebook} alt="" className='FooterButtonImg' />
                      </Link>
                      <Link to="" className='FooterButton'>
                        <img src={instagram} alt="" className='FooterButtonImg' />
                      </Link>
                    </div>
                </div>
              </div>
          <div
            className="footer-bottom"
          >
            Türk Havak Sigorta bir
            <img
              src={turkLogo}
              alt="Türk Havak"
              className="FooterBottomImage"
            />
            Grup şirketidir.
          </div>
        </div>
      </footer>
    );
  }
}
