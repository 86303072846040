/* eslint-disable import/no-anonymous-default-export */
import { CREATE_CUSTOMER, CUSTOMER_SMS_CHECK, CUSTOMER_SEND_SMS, CUSTOMER_SEND_MAIL, SET_ENTER, CUSTOMER_DETAIL,RESET_CUSTOMER } from "../actions/types";

const INITIAL_STATE = {
    customer: {
        idendityNumber: "",
        birthDate: "",
        mobileNumber: "",
        email: "",
        job: "",
        musID: 0,
    },
    sms: {},
    customerSend: {},
    dogrulama: false,
    customerDetail: {},
    mail:{},
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_CUSTOMER:
            return {...state, customer: action.payload}
        case CUSTOMER_SMS_CHECK:
            return {...state, sms: action.payload}
        case CUSTOMER_SEND_SMS:
            return {...state, customerSend: action.payload}
        case CUSTOMER_SEND_MAIL:
            return {...state, mail: action.payload}
        case SET_ENTER:
            return {...state, dogrulama: action.payload}
        case CUSTOMER_DETAIL:
            return {...state, customerDetail: action.payload}
            case RESET_CUSTOMER:
                return {...state, customer:{
                    idendityNumber: "",
                    birthDate: "",
                    mobileNumber: "",
                    email: "",
                    job: "",
                    musID: 0,
                } }
            default:
            return state;
    }


}