/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import * as actions from '../../../redux/actions';
import {connect} from 'react-redux';
import Modal from "react-modal";
import { Button } from "react-bootstrap";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import { Link } from 'react-router-dom';

const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

class Tekliflerim extends Component {
    state={
        currentPage: 1,
        data: [],
        sayfaSayisi: 0,
        hataModalIsOpen: false
    }
    async componentDidMount(){
       await this.props.proposalList(this.props.auth.token);
        this.changeCurrentPage(1)
    }

    changeCurrentPage = numPage => {
        this.setState({ currentPage: numPage });
        let a = this.props.proposal.proposalList;
        var data = a.slice((numPage - 1) * 4, numPage * 4);
        this.setState({data: data});
    };

    tarihChange = (tarih) => {
    let yil = tarih.substring(0, 4);
    let ay = tarih.substring(5, 7);
    let gun = tarih.substring(8, 10);
    return gun + ' / ' + ay + ' / ' + yil
    }

    openHataModal = (baslik,problem) => {
        this.setState({ hataModalIsOpen: true });
        this.setState({ hata: problem });
        this.setState({ hatabaslik: baslik });
      };

      closeModal = () => {
        this.setState({
          hataModalIsOpen: false,
        });
      };

    callMe = (item) => {
        let callMe = {
            sigortaliUnvan: item.sigortaliAdi,
            idendityNumber: this.props.customer.customerDetail.kimlikNo,
            birthDate: this.props.customer.customerDetail.dogumTarihi,
            mobileNumber: this.props.customer.customerDetail.cep,
            email: this.props.customer.customerDetail.eposta
        }
        this.props.callMe(callMe);

        let timer = setInterval(() => {
          clearInterval(timer);
    
          this.openHataModal("Bilgi!","Talebiniz tarafımıza ulaşmıştır. En kısa zamanda dönüş sağlanacaktır.");
      }, 2000);
    

    }

    setStorage = () => {
        sessionStorage.setItem('profil_tab', 'tekliflerim')
        sessionStorage.setItem('geri_link', '/hesabim')
        }

  render() {
    return (
      <div>
         <Modal
          isOpen={this.state.hataModalIsOpen}
          onRequestClose={() => this.closeModal()}
          className="odeme-modal-box"
          style={customStyles}
          ariaHideApp={false}
        >
          <div className="hata-modal-container">

            <h4>
              <b>{this.state.hatabaslik}</b>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: this.state.hata }}>
         </div>
         <br></br>
         <Button onClick={() => this.closeModal()}>Tamam</Button>
          </div>
        </Modal>
          <div>
          {this.state.data.map((e) =>
                <div className='profil-police-box'>
                    <div className='police-column'>
                        <div className='police-title-box'>
                            <label>{e.brans} Teklifi</label>
                            <div className='line'></div>
                        </div>
                        <div className='d-flex justify-conten-start align-items-center'>
                            <p>{e.sigortaliAdi}</p>
                        </div>
                    </div>
                    <div className='police-column'>
                        <div className='police-title-box'>
                            <label>Teklif Geçerlilik Tarihi</label>
                            <div className='line'></div>
                        </div>
                        <p>{this.tarihChange(e.teklifTarihi)}</p>
                    </div>
                    <div className='police-column'>
                        <div className='police-title-box'>
                            <label>En Düşük Fiyat</label>
                            <div className='line'></div>
                        </div>
                        <div>{e.proposalDetailListResultDto.length === 0 ? (
                            <p>0 TL</p>
                        ) : (
                            <p>
                                {new Intl.NumberFormat('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(e.proposalDetailListResultDto.sort(function(a, b) {return a.prim-b.prim})[0].prim)} TL
                            </p>
                        )}</div>
                    </div>
                    <br></br>
                    <div className='police-column button-center' >
                        {/* <div className='police-button'><Link to={"/satin-al?teklifID=" + e.teklifID}>Satın Al</Link></div> */}
                        <div className='police-button'><Link to={"/sigorta-sepeti?teklifID=" + e.teklifID}>Tekrar Çalış</Link></div>
                        <div className='police-button' onClick={() => this.callMe(e)}>Beni Ara</div>
                        <div className='police-button'><Link to={"/satin-al?teklifID=" + e.teklifID} onClick={() => this.setStorage()}>Teklifleri İncele</Link></div>
                    </div>
                </div>
            )}
            <Pagination
                currentPage={this.state.currentPage}
                sizePerPage={4}
                changeCurrentPage={this.changeCurrentPage}
                totalSize={this.props.proposal.proposalList.length}
            />
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
    return {
        auth: state.auth,
        proposal: state.proposal,
        customer: state.customer,
    }
}

export default connect(mapStateToProps,actions)(Tekliflerim);