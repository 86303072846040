/* eslint-disable no-unused-expressions */
/* eslint-disable no-loop-func */
/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import * as actions from "../../../redux/actions";
import { connect } from "react-redux";
import { dataModel, kullanimTarziAl } from "../../../redux/actions/definitions";
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const ruhsat = require("../../images/ruhsa-belge-seri-no.png");
const star = require("../../images/star.png");
const sirketImgURL = "https://web2c.biz/sirket/";
const close1 = require("../../images/icon/close1.png");
const correct = require("../../images/icon/correct.png");
const err = require("../../images/icon/error.png");
const wait = require("../../images/wait.gif");
const wait2 = require("../../images/turkhavak.gif");
const discount = require("../../images/icon/discount.gif");

const bgChange = [
  require("../../images/teklifBackground/kasko-teklif-01.jpg"),
  require("../../images/teklifBackground/kasko-teklif-02.jpg"),
  require("../../images/teklifBackground/kasko-teklif-03.jpg"),
  require("../../images/teklifBackground/kasko-teklif-04.jpg"),
  require("../../images/teklifBackground/kasko-teklif-05.jpg"),
];

const ay = [
  { name: "01", value: "01" },
  { name: "02", value: "02" },
  { name: "03", value: "03" },
  { name: "04", value: "04" },
  { name: "05", value: "05" },
  { name: "06", value: "06" },
  { name: "07", value: "07" },
  { name: "08", value: "08" },
  { name: "09", value: "09" },
  { name: "10", value: "10" },
  { name: "11", value: "11" },
  { name: "12", value: "12" },
];

const yil = [
  { name: "23", value: "2023" },
  { name: "24", value: "2024" },
  { name: "25", value: "2025" },
  { name: "26", value: "2026" },
  { name: "27", value: "2027" },
  { name: "28", value: "2028" },
  { name: "29", value: "2029" },
  { name: "30", value: "2030" },
  { name: "31", value: "2031" },
  { name: "32", value: "2032" },
  { name: "33", value: "2033" },
];

const taksit = [
  { name: "1", value: "1" },
  { name: "2", value: "2" },
  { name: "3", value: "3" },
  { name: "4", value: "4" },
  { name: "5", value: "5" },
  { name: "6", value: "6" },
  { name: "7", value: "7" },
  { name: "8", value: "8" },
  { name: "9", value: "9" },
  { name: "10", value: "10" },
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function getStorageValue(key) {
  // getting stored value
  if (typeof window !== "undefined") {
    const saved = sessionStorage.getItem(key);
    const token = saved != null ? JSON.parse(saved) : null;
    return token;
  }
}

class Kasko extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teklifModalIsOpen: false,
      sepetModalIsOpen: false,
      yukleniyor: false,
      hataModalIsOpen: false,
      policeModalIsOpen: false,
      karsilastirModalIsOpen: false,
      odemeModalIsOpen: false,
      gizlilikModalIsOpen: false,
      kvkkModalIsOpen: false,
      successModalIsOpen: false,
      mesafeliModalIsOpen: false,
      turkhavakModalIsOpen: false,
      bilgilendirmeModalIsOpen: false,
      idendityNumber: "",
      birthDate: "",
      mobileNumber: "",
      email: "",
      job: "",
      musID: 0,
      dogrulamaKodu: "",
      plaka: "",
      tescilKod: "",
      tescilNo: "",
      markaKodu: "",
      tipKodu: "",
      modelYili: "",
      engelliAraciMi: false,
      aksesuar: 0,
      kampanyaID: 0,
      kullanimTarziKodu: "",
      kullanimSekli: "",
      yolcuSayisi: "",
      aracBedeli: "",
      aksesuarBedeli: "",
      sasiNo: "",
      motorNo: "",
      plakamYok: false,
      isTss: false,
      isDainiMurtein: false,
      finansKurumKodu: "",
      bankaSubeKodu: "",
      ilKodu: "",
      aracKodu: "",
      sirketler: [],
      height: "",
      weight: "",
      plan: "",
      network: "",
      baslangicTarihi: "",
      bitisTarihi: "",
      teklifDetayID: 0,
      taksitSayisi: 0,
      kartSahibiAdi: "",
      kartSahibiSoyadi: "",
      kartNumarasi: "",
      ay: "",
      yil: "",
      cvv: "",
      policy: {},
      hataLog: "",
      policeNo: "",
      hata: "",
      hatabaslik: "",
      isSuccess: false,
      policyButton: true,
      karsilastirmaListesi: [],
      teminatIstendimi: false,
      sirketKodlari: [],
      tipler: [],
      lengthCount: "",
      lengthCountTrafik: "",
      createTrafik: {},
      wait: false,
    };
  }

  componentDidMount() {
    this.props.resetProposal();
    this.props.resetCustomer();
    this.props.getJobList();
    this.props.getIlList();
    this.props.getListMarka();
    this.props.getByMarkaKodAndModel(
      this.state.markaKodu,
      this.state.modelYili
    );
    this.props.getAracBedeli();
    this.props.getCompanyList(1);
    this.props.getHealthPlanList();
    this.props.getHealthPlanTypeList();
    this.props.getGuaranteeList();

    if (this.props.auth.token == null) {
      this.props.auth.token = getStorageValue('user');
    }

    if (this.props.auth.token != null) {
      this.props.getUserName(this.props.auth.token);
    }
  }

  openTeklifModal = (item) => {

    try {
  if (this.props.common.guaranteeAll.teminatList.length == 0)
  {
    this.props.getGuaranteeAllList(
      this.props.proposal.proposalID.proposalID
    );
  
    setTimeout(()=>{
    },1000)
  }

    let tList = [];
      let teminatSonuc = this.props.common.guaranteeAll.teminatList.filter(
        (res) => {
          return res.sirketKodu == item.sirketKodu;
        }
      );
      tList.push(teminatSonuc[0]);

    let isimListesi = this.props.common.guarantees;
    var kListesi = [];

    var sayi = tList.length;

    if (sayi != 0) {
      for (var key in tList[0]) {
        var reads = isimListesi.filter((e) => {
          return e.name2 == key;
        });
        if (reads.length > 0) {
          var k = { name: reads[0].name };

          for (let i = 0; i < sayi; i++) {
            var deger = tList[i][key];
            k[tList[i].sirketKodu] = deger;
          }

          kListesi.push(k);
        }
      }
    }
    this.setState({teklifModalIsOpen: true, policy: item, karsilastirmaListesi : kListesi});
  }
  catch{}
   }

  plakaRadioChange() {
    let isPlaka = document.getElementById("rd-var");

    let dvPlakaVar = document.getElementById("dvPlakaVar");
    let dvPlakaYok = document.getElementById("dvPlakaYok");

    if (isPlaka.checked) {
      dvPlakaVar.className = "active";
      dvPlakaYok.className = "inactive";
    } else {
      dvPlakaVar.className = "inactive";
      dvPlakaYok.className = "active";
      this.setState({ plakamYok: true });
    }
  }

  addToCart(offer) {
    let teklif = this.props.proposal.cart.filter((item) => {
      return item.bransNo === offer.bransNo;
    });

    if (teklif.length === 0) {
      this.props.addToCart(offer);
    }
  }

  addToBox(offer) {
    let teklif = this.props.proposal.box.filter((item) => {
      return item.sirketKodu === offer.sirketKodu;
    });

    if (teklif.length === 0) {
      if (this.state.teminatIstendimi === false) {
        this.props.getGuaranteeAllList(
          this.props.proposal.proposalID.proposalID
        );
        this.setState({ teminatIstendimi: true });
      }
      this.props.addToBox(offer);
    }
    else{
      this.props.removeToBox(teklif);
    }
  }

  engelliRadioChange() {
    let isEngelli = document.getElementById("rd-evet");

    if (isEngelli.checked) {
      this.setState({ engelliAraciMi: true });
    } else {
      this.setState({ engelliAraciMi: false });
    }
  }

  donanimChange() {
    let isDonanim = document.getElementById("rd-donanimEvet");

    let inpDonanim = document.getElementById("inp-donanim");

    if (isDonanim.checked) {
      inpDonanim.disabled = false;
    } else {
      inpDonanim.disabled = true;
    }
  }

  dainiMurteinChange() {
    let isDainiMurtein = document.getElementById("chb-dainiEvet");

    let inpDainiMurtein = document.getElementById("inp-dainiMurtein");

    if (isDainiMurtein.checked) {
      inpDainiMurtein.disabled = false;
    } else {
      inpDainiMurtein.disabled = true;
    }
  }

  createCustomer(id) {
    let checkedGizlilik = document.getElementById("gizlilik").checked;
    let checkedKvkk = document.getElementById("kvkk").checked;
    let problem = "";
    if (
      this.state.idendityNumber.length < 10 ||
      this.state.idendityNumber.length > 11 ||
      this.state.idendityNumber.length == 0
    ) {
      problem = problem + "Tc Kimlik numaranız hatalı? </br>";
    }
    if (this.state.birthDate == "" && this.state.idendityNumber.length == 11) {
      problem = problem + "Doğum tarihi hatalı? </br>";
    }
    if (
      this.state.mobileNumber == "" ||
      this.state.mobileNumber.length < 16 ||
      this.state.mobileNumber.length > 16
    ) {
      problem = problem + "Telefon numaranız hatalı? </br>"; 
    }
    if (this.state.email == "") {
      problem = problem + "Mail adresi boş geçilemez? </br>";
    }
    if (this.state.job == "" && this.state.idendityNumber.length == 11) {
      problem = problem + "Meslek boş geçilemez? </br>";
    }
    if (!checkedGizlilik || !checkedKvkk) {
      problem = problem + "Checkboxları tıklayın? </br>";
    }

    if (problem != "") {
      this.openHataModal("Eksik Bilgi!",problem);
    } else {
      this.openYukleniyorModal();

    var uyeID = (this.props.auth.username == null) ? 0 : this.props.auth.username.id;

      let customer = {
        idendityNumber: this.state.idendityNumber,
        birthDate: this.state.birthDate,
        mobileNumber: this.state.mobileNumber
          .replace(" ", "")
          .replace("(", "")
          .replace(")", "")
          .replace("-", "")
          .replace("  ", ""),
        email: this.state.email,
        job: this.state.job,
        musID: this.state.musID,
        uyeID:uyeID,
        uavtAdresKodu: this.state.uavtAdresKodu,
        errorMessage: this.state.errorMessage,
      };
console.log(customer);
      this.props.createCustomer(customer);

      let deneme = 0;
      let timerOffers = setInterval(() => {

        if (this.props.customer.customer.musID > 0) {
          clearInterval(timerOffers);
          this.closeYukleniyorModal();
          this.setState({
            dogrulamaKodu: "",
          });
          this.stepper(id);
        } 
        if (deneme == 10)
        {
          clearInterval(timerOffers);
          this.closeYukleniyorModal();
          if (customer.errorMessage != null) {
             this.openHataModal("Uyarı!",customer.errorMessage);
          }
        }
        deneme++;
      }, 1000);
    }
  }

  dogrulamaKodu(id) {
    let checkedEvet = document.getElementById("evet").checked;
    let checkedHayir = document.getElementById("hayır").checked;

    let problem = "";

    if (this.state.dogrulamaKodu === "") {
      problem = problem + "Doğrulama kodu boş bırakılamaz? </br>";
    }
    else if (this.state.dogrulamaKodu.length != 6) {
      problem = problem + "Doğrulama kodunu hatalı? </br>";
    }
    if (!checkedEvet && !checkedHayir) {
      problem = problem + "Çocuk sorusunu yanıtlayın! </br>";
    }
    if (this.state.kampanyaID == 0)
    {
      problem = problem + "Türk Havak üyeliğine cevap veriniz! </br>";
    }
    if (problem != "") {
      this.openHataModal("Eksik Bilgi!",problem);
    } else {
      this.openYukleniyorModal();
    let sms = {
      musID: this.props.customer.customer.musID,
      dogrulamaKodu: this.state.dogrulamaKodu,
    };
    this.props.customerSmsCheck(sms);

    let timerSms = setInterval(() => {
      clearInterval(timerSms);
      this.closeYukleniyorModal();
      if (this.props.customer.sms.isSuccess)
      {
       this.stepper(id);
      }
      else {
       this.openHataModal("Uyarı!",this.props.customer.sms.mesaj);
      }
 
      }, 1000);
    }
  
  }

  markaAtama(markaKodu) {
    this.setState({ markaKodu: markaKodu });
    this.props.getByMarkaKodAndModel(markaKodu, "");
  }

  modelYiliAtama = async(modelYili)=> {
    this.setState({ modelYili: modelYili });
    await this.props.getByMarkaKodAndModel(this.state.markaKodu, modelYili);
    //this.basildiPlakaYok("");
  }

  aracBedeli(tipKodu) {
    this.setState({ tipKodu: tipKodu });
    let aracKodu = this.state.markaKodu + tipKodu
    this.setState({ aracKodu: aracKodu });
    setTimeout(()=>{
      this.props.getAracBedeli(aracKodu, this.state.modelYili);
    },500)
  }

  ilAtama(ilKodu) {
    this.setState({ ilKodu: ilKodu });
    if (this.state.plakamYok === true) {
      if (ilKodu.length === 1) {
        ilKodu = "0" + ilKodu;
      }
      let plakaYK = ilKodu + "YK";
      this.setState({ plaka: plakaYK });
    }
  }

  kullanimTarziAtama(kullanimTarziKodu) {
    this.setState({ kullanimTarziKodu: kullanimTarziKodu });
  }

  tssCheckBoxChecked() {
    let chbTss = document.getElementById("rd-tssEvet");

    let dvTss = document.getElementById("dv-tss");

    if (chbTss.checked) {
      dvTss.style = "block";
      this.setState({ isTss: true });
    } else {
      dvTss.style = "display: none";
      this.setState({ isTss: false });
    }
  }

  createProposalKasko(id) {
    let problem = "";
    if (this.state.plakamYok == false) {
      if (this.state.plaka == "") {
        problem = problem + "Plaka boş geçilemez.</br>";
      }
      if (this.state.tescilKod == "") {
        problem = problem + "Belge Seri boş geçilemez.</br>";
      }
      if (this.state.tescilNo == "") {
        problem = problem + "Belge Seri No boş geçilemez.</br>";
      }
      // if (this.state.markaKodu == "") {
      //   problem = problem + "Marka boş geçilemez.</br>";
      // }
    } else {
      if (this.state.markaKodu == "") {
        problem = problem + "Marka boş geçilemez.</br>";
      }
      if (this.state.modelYili == "") {
        problem = problem + "Model Yılı boş geçilemez.</br>";
      }
      if (this.state.tipKodu == "") {
        problem = problem + "Tip boş geçilemez.</br>";
      }
      if (this.state.kullanimTarziKodu == "") {
        problem = problem + "Kullanım Tarzı boş geçilemez.</br>";
      }
      if (
        this.state.sasiNo.length == 0 ||
        this.state.sasiNo.length < 17 ||
        this.state.sasiNo.length > 17
      ) {
        problem = problem + "Şasi No boş geçilemez.</br>";
      }
      if (this.state.motorNo == "") {
        problem = problem + "Motor No boş geçilemez.</br>";
      }
    }
    if (problem != "") {
      this.openHataModal("Eksik Bilgi!",problem);
    } else {
      this.openYukleniyorModal();
      let proposal = {
        branch: 1,
        process: 0,
        vehicle: {
          plaka: this.state.plaka,
          tescilKod: this.state.tescilKod,
          tescilNo: this.state.tescilNo,
          markaKodu: this.state.markaKodu,
          tipKodu: this.state.tipKodu,
          modelYili: this.state.modelYili,
          engelliAraciMi: this.state.engelliAraciMi,
          aksesuar: this.state.aksesuar,
          kullanimTarziKodu: this.state.kullanimTarziKodu,
          kullanimSekli: this.state.kullanimSekli,
          yolcuSayisi: this.state.yolcuSayisi,
          aracBedeli: this.props.bilgiBankasi.aracBedeli,
          aksesuarBedeli: this.state.aksesuarBedeli,
          sasiNo: this.state.sasiNo,
          motorNo: this.state.motorNo,
          plakamYok: this.state.plakamYok,
        },
        health: {
          height: this.state.height,
          weight: this.state.weight,
          plan: this.state.plan,
          network: this.state.network,
          urunID: 1,
        },
        isTss: this.state.isTss,
        musID: this.props.customer.customer.musID,
        isDainiMurtein: this.state.isDainiMurtein,
        finansKurumKodu: this.state.finansKurumKodu,
        bankaSubeKodu: this.state.bankaSubeKodu,
        kampanyaID: this.state.kampanyaID,
        isCaprazCalis: false
      };

      this.props.createProposal(proposal);
      
      let deneme = 0;
      let timerOffers = setInterval(() => {
        if (this.props.proposal.proposalID.proposalID > 0) {

          clearInterval(timerOffers);
          this.closeYukleniyorModal();
          this.getProposal();
          this.stepper(id);
          this.spinner();
        }
        
        if (deneme == 5)
        {
          clearInterval(timerOffers);
          this.closeYukleniyorModal();
          if (this.props.proposal.proposalID.hataLog != null && this.props.proposal.proposalID.hataLog != "")
          this.openHataModal("Uyarı!",this.props.proposal.proposalID.hataLog);
        }
        deneme++;
      }, 2000);
    }
  }

  createProposalTrafik(id) {

    let a = this.state.createTrafik
    if (a != null) {
      let proposal = {
        branch: 0,
        process: 0,
        vehicle: {
          plaka: a.plaka,
          tescilKod: a.tescilKod,
          tescilNo: a.tescilNo,
          markaKodu: a.markaKodu,
          tipKodu: a.tipKodu,
          modelYili: a.modelYili,
          kullanimTarziKodu: a.kullanimTarziKodu,
          kullanimSekli: a.kullanimSekli,
          yolcuSayisi: a.yolcuSayisi,
          sasiNo: a.sasiNo,
          motorNo: a.motorNo,
          plakamYok: a.plakamYok
        },
        health: {
          height: this.state.height,
          weight: this.state.weight,
          plan: this.state.plan,
          network: this.state.network,
          urunID: 1,
          baslangicTarihi: this.state.baslangicTarihi,
          bitisTarihi: this.state.bitisTarihi,
        },
        isTss: this.state.isTss,
        musID: this.props.customer.customer.musID,
        kampanyaID: this.state.kampanyaID,
      };
 
      this.props.createProposal(proposal);
  
      let timerOffers = setInterval(() => {
        if (
          this.props.proposal.proposalID.proposalID !== 0 &&
          this.props.proposal.proposalID.proposalID !== undefined
        ) {
          clearInterval(timerOffers);
          this.getProposal();
        }
      }, 1000);
      this.stepper(id);
      this.spinnerTrafik();
    }
  } 

  createProposalTSS(id) {

        let proposal = {
          branch: 18,
          process: 0,
          health: {
            height: this.state.height,
            weight: this.state.weight,
            plan: this.state.plan,
            network: this.state.network,
            urunID: 1,
            baslangicTarihi: this.state.baslangicTarihi,
            bitisTarihi: this.state.bitisTarihi,
          },
          isTss: this.state.isTss,
          musID: this.props.customer.customer.musID,
        };
        this.props.createProposal(proposal);

       // this.stepper(id);

        // let timerOffers = setInterval(() => {
        //   if (this.props.proposal.proposalID.proposalID !== 0) {
        //     clearInterval(timerOffers);
        //     this.getProposal();
        //   }
        // }, 1000);


      
    
  }

  getProposal() {
    this.props.resetProposal();
    this.props.common.companies.forEach((element) => {
      let teklif = {
        sirketKodu: element.sirketKodu,
        teklifID: this.props.proposal.proposalID.proposalID,
      };
      this.props.getProposal(teklif);
    });
  }

  cPolicy() {
    let problem = "";

    if (this.state.kartSahibiAdi === "") {
      problem = problem + "Kart sahibi adı boş geçilemez</br>";
    }
    if (this.state.kartSahibiSoyadi === "") {
      problem = problem + "Kart sahibi soyadı boş geçilemez</br>";
    }
    if (
      this.state.kartNumarasi === "" &&
      this.state.kartNumarasi < 16 &&
      this.state.kartNumarasi > 16
    ) {
      problem = problem + "Kart numarası eksik veya hatalı</br>";
    }
    if (this.state.ay === "") {
      problem = problem + "Ay boş geçilemez</br>";
    }
    if (this.state.yil === "") {
      problem = problem + "Yıl boş geçilemez</br>";
    }
    if (this.state.cvv === "") {
      problem = problem + "CVV boş geçilemez";
    }
    if (problem !== "") {
      this.openHataModal("Eksik Bilgi!",problem);
    } else {
      this.openYukleniyorModal();
      let policy = {
        teklifDetayID: this.state.policy.teklifDetayID,
        taksitSayisi: this.state.taksitSayisi,
        krediKartiBilgisi: {
          kartSahibiAdi: this.state.kartSahibiAdi,
          kartSahibiSoyadi: this.state.kartSahibiSoyadi,
          kartNumarasi: this.state.kartNumarasi,
          ay: this.state.ay,
          yil: this.state.yil,
          cvv: this.state.cvv,
        },
        hataLog: this.state.hataLog,
        policeNo: this.state.policeNo,
        isSuccess: this.state.isSuccess,
      };
      this.props.createPolicy(policy);

      let deneme = 0;
      let timerPolicy = setInterval(() => {
        if (this.props.policy.policy.isSuccess) {

          clearInterval(timerPolicy);

          this.closeModal();
        this.closeYukleniyorModal();
          this.setState({
            taksitSayisi: 0,
            kartSahibiAdi: "",
            kartSahibiSoyadi: "",
            kartNumarasi: "",
            ay: "",
            yil: "",
            cvv: "",
            hataLog: "",
            policeNo: "",
            isSuccess: false,
            policyButton: false,
          });
          this.setState({ policeModalIsOpen:true});
        }
        
        if (deneme == 5)
        {
          clearInterval(timerPolicy);
          this.closeYukleniyorModal();
          this.openHataModal("Uyarı!",policy.hataLog);
        }
        deneme++;
      }, 2000);
    }
  }

  openYukleniyorModal = () => {
    this.setState({ yukleniyor: true });
  };

  closeYukleniyorModal = () => {
    this.setState({ yukleniyor: false });
  };

  openHataModal = (baslik,problem) => {
    this.setState({ hataModalIsOpen: true });
    this.setState({ hata: problem });
    this.setState({ hatabaslik: baslik });
  };

  openSepetModal = () => {
    this.setState({ sepetModalIsOpen: true });
  };

  openKarsilastirmaModal = (box) => {
    this.setState({ karsilastirModalIsOpen: true });

    let tList = [];
    box.map((b) => {
      let teminatSonuc = this.props.common.guaranteeAll.teminatList.filter(
        (res) => {
          return res.sirketKodu == b.sirketKodu;
        }
      );
      tList.push(teminatSonuc[0]);
    });

    let isimListesi = this.props.common.guarantees;
    var kListesi = this.state.karsilastirmaListesi;

    var sayi = tList.length;

    if (sayi != 0) {
      for (var key in tList[0]) {
        var reads = isimListesi.filter((e) => {
          return e.name2 == key;
        });
        if (reads.length > 0) {
          var k = { name: reads[0].name };

          for (let i = 0; i < sayi; i++) {
            var deger = tList[i][key];
            if (deger != null){
              k[tList[i].sirketKodu] = deger;
              }
        }

          kListesi.push(k);
        }
      }

      let sirket = [];
      for (let i = 0; i < sayi; i++) {
        sirket.push([tList[i].sirketKodu]);
      }
      this.setState({ sirketKodlari: sirket });
    }
    this.setState({ karsilastirmaListesi: kListesi });
  };
  openTeklifDetayModal = (offer) => {
  
  };
  openOdemeModal = (item) => {
    this.setState({ odemeModalIsOpen: true, policy: item });
  };
  openGizlilikModal = () => {
    this.setState({ gizlilikModalIsOpen: true });
  };

  openKvkkModal = () => {
    this.setState({ kvkkModalIsOpen: true });
  };
  successModalIsOpen = () => {
    this.setState({ successModalIsOpen: true });
  };

  mesafeliModalIsOpen = () => {
    this.setState({ mesafeliModalIsOpen: true });
  };

  turkhavakModalIsOpen = () => {
    this.setState({ turkhavakModalIsOpen: false });
  };

  bilgilendirmeModalIsOpen = () => {
    this.setState({ bilgilendirmeModalIsOpen: true });
  };

  closeModal = () => {
    this.setState({
      yukleniyor: false,
      hataModalIsOpen: false,
      sepetModalIsOpen: false,
      karsilastirModalIsOpen: false,
      odemeModalIsOpen: false,
      gizlilikModalIsOpen: false,
      kvkkModalIsOpen: false,
      teklifModalIsOpen: false,
      policeModalIsOpen: false
    });
  };

  closeSuccessModal = () => {
    this.setState({ successModalIsOpen: false });
  };

  closeMesafeliModal = () => {
    this.setState({
      mesafeliModalIsOpen: false,
      turkhavakModalIsOpen: false,
      bilgilendirmeModalIsOpen: false,
    });
  };

  stepper = (id) => {


    let problem = "";

    if (this.state.isTss == true && id === "adim4") {
      if (this.state.height == "") {
      problem = problem + "Boyunuzu giriniz.</br>";
    }
    if (this.state.weight == "") {
      problem = problem + "Kilonuzu giriniz.</br>";
    }
    if (this.state.plan == "") {
      problem = problem + "Plan boş geçilemez.</br>";
    }
    if (this.state.network == "") {
      problem = problem + "Network boş geçilemez.</br>";
    }
  }

    if (problem != "") {
      this.openHataModal("Eksik Bilgi!",problem);
    } else {

      // this.createProposalTSS(id);

    this.setState({wait:false})
    let steps = document.getElementsByTagName("section");
    let img = document.getElementById("bgImg");
    let tBoxContent = document.getElementById("tBoxContent");
    let bgImgBox = document.getElementById("bgImgBox");

    if (id === "adim1") {
      bgImgBox.src = bgChange[0];
    }
    if (id === "adim2") {
      bgImgBox.src = bgChange[1];
      let checkedHayir = document.getElementById("hayır");
      if (checkedHayir != null) checkedHayir.checked = true;
    }
    if (id === "adim3") {
      bgImgBox.src = bgChange[0];
      let isPlaka = document.getElementById("rd-var");
      isPlaka.checked = true;
    }
    if (id === "adim4") {
      bgImgBox.src = bgChange[1];
    }
    if (id === "adim5") {
      bgImgBox.src = bgChange[0];
    }

    if (
      id === "adim1" ||
      id === "adim2" ||
      id === "adim3" ||
      id === "adim4" ||
      id === "adim5"
    ) {
      document.getElementById("btn1").className = "isActive";
      document.getElementById("btn2").className = "inActive";
      document.getElementById("btn3").className = "inActive";
      img.style.display = "";
    } else if (id === "adim6" || id === "adim7") {
      document.getElementById("btn1").className = "inActive";
      document.getElementById("btn2").className = "isActive";
      document.getElementById("btn3").className = "inActive";
      img.style.display = "none";
      tBoxContent.style.width = "100%";
    } else if (id === "adim8") {
      document.getElementById("btn1").className = "inActive";
      document.getElementById("btn2").className = "inActive";
      document.getElementById("btn3").className = "isActive";
      img.style.display = "none";
      tBoxContent.style.width = "100%";
    }

    for (var i = 0; i < steps.length; i++) {
      if (steps[i].id === id) {
        steps[i].className = "active";
      } else steps[i].className = "inactive";
    }
  }
  };

  findGuaranteeName(value) {
    let name = this.props.common.guarantees.filter((v) => {
      return v.value === value;
    });
    return name[0].name;
  }


  spinner = () => {
    let a = document.getElementById("spinner");
    let lengthCount = 0;
    let timer = setInterval(() => {
      if (this.props.kasko.length > 0 && this.props.kasko.length == this.props.getCompanyList.length) lengthCount = 100;
      if (lengthCount == 100) {
        a.style.display = "none";
        this.setState({
          lengthCount: '100% Tamamlanmıştır'
        })
        this.props.getGuaranteeAllList(
          this.props.proposal.proposalID.proposalID
        );
        clearInterval(timer);
      } else {
        lengthCount++;
        this.setState({
          lengthCount: lengthCount.toString() + '%'
        })
      }
    }, 1000);
  };

  spinnerTrafik = () => {
    
    let b = document.getElementById("spinnerTrafik");
    let lengthCountTrafik = 0;
    let timer = setInterval(() => {
      if (lengthCountTrafik == 100) {
        b.style.display = "none";
        this.setState({
          lengthCountTrafik: '100% Tamamlanmıştır'
        })
        clearInterval(timer);
      } else {
        lengthCountTrafik++;
        this.setState({
          lengthCountTrafik: lengthCountTrafik.toString() + '%'
        })
      }
    }, 400);
  };

  dogrulamaTekrar = () => {
    let customerSend = {
      musID: this.props.customer.customer.musID,
      idendityNumber: "",
      mobilNumber: "",
    };
    debugger;
    let a = document.getElementById("smsTekrar");
    a.style.pointerEvents = "none";
    this.props.customerSendSms(customerSend);
    this.spinnerSMS();
  };

  spinnerSMS = () => {
    let a = document.getElementById("smsTekrar");
    let lengthCount = 60;
    let timer = setInterval(() => {
      if (lengthCount == 0) {
        a.style.pointerEvents = "auto";
        a.innerText = "Tekar Gönder";
        clearInterval(timer);
      } else {
        a.innerText = "Tekar Gönder - " + lengthCount;
        --lengthCount;
      }
    }, 1000);
  };

  pdfYazdir(){
    let police = {
        teklifDetayID: 1,
        policeNo: this.props.policy.policy.policeNo,
        yenilemeNo: "0",
        zeyilNo: "0",
        SirketKodu: this.state.policy.sirketKodu
    }
  
    this.openYukleniyorModal();
  
    this.props.getPrint(police);
    let base64Pdf = this.props.policy.print.base64;
  
    let timer = setInterval(()=> {
        if(base64Pdf !== undefined){
            clearInterval(timer)
            this.closeYukleniyorModal();
            var blob = this.base64ToBlob(base64Pdf);
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
              } else {
                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl);
              }
        } 
    }, 5000)
  }

  openBase64NewTab(teklif) {
    this.props.getPrint(teklif.teklifDetayID);
    let base64Pdf = this.props.policy.print.base64;
    var blob = this.base64ToBlob(base64Pdf);

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  }

  base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: "application/pdf" });
  }

  isNumericInput = (event) => {
    const key = event.keyCode;
    return (
      (key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };

  isModifierKey = (event) => {
    const key = event.keyCode;
    return (
      event.shiftKey === true ||
      key === 35 ||
      key === 36 || // Allow Shift, Home, End
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 46 || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      // Allow Ctrl/Command + A,C,V,X,Z
      ((event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
    );
  };
  enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!this.isNumericInput(event) && !this.isModifierKey(event)) {
      event.preventDefault();
    }
  };

  formatToPhone = (event) => {
    if (this.isModifierKey(event)) {
      return;
    }

    const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    if (input.length === 1 && input === "0") {
      event.target.value = "";
      return;
    }
    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      event.target.value = `(${areaCode}) ${middle} - ${last}`;
    } else if (input.length > 3) {
      event.target.value = `(${areaCode}) ${middle}`;
    } else if (input.length > 0) {
      event.target.value = `(${areaCode}`;
    }
  };

  renderTooltip = (props) => (
    <Tooltip
      id="button-tooltip"
      style={{ backgroundColor: "rgba(255, 100, 100, 0.85)" }}
      {...props}
    >
      TürkHavak Üyelerine özel %30 indirim!
    </Tooltip>
  );

  basildiIl = (value) => {
    let liste = document.getElementById("listeIl");
    liste.innerHTML = '';
    if (value != "") {
 
      let ilAta = this.props.bilgiBankasi.iller.filter(e => {
        return e.ilAdi != null && e.ilAdi.toString().toLowerCase().includes(value.toString().toLowerCase())
      })

      ilAta.map(item => {
        var myOption = document.createElement("option");
        myOption.value = item.ilKodu;
        myOption.text = item.ilAdi;
        liste.appendChild(myOption);
      })
    } else {
      this.props.bilgiBankasi.iller.map(item => {
        var myOption = document.createElement("option");
        myOption.value = item.ilKodu;
        myOption.text = item.ilAdi;
        liste.appendChild(myOption);
      })
    }
  }
  aktifIl = () => {
    let liste = document.getElementById("listeIl");
    liste.style.display = "block";
    this.basildiIl("")
  }

  secildiIl = () => {
    let liste = document.getElementById("listeIl");
    let data = document.getElementById("dataIl");

    var newListe = this.props.bilgiBankasi.iller.filter(function (el) {
      return el.ilKodu == liste.value
    });

    data.value = newListe[0].ilAdi;
   let selectValue = newListe[0].ilKodu;

   this.ilAtama(selectValue);
   
    this.pasifIl();
    let timer = setInterval(()=>{
      if(this.state.ilKodu== undefined || this.state.ilKodu == ""){
        clearInterval(timer)
        this.setState({ilKodu: selectValue})
      }
    })
  }

  pasifIl = () => {
    let liste = document.getElementById("listeIl");
    liste.style.display = "none";
  }

  pasif2Il = () => {
    let liste = document.getElementById("listeIl");
    let data = document.getElementById("dataIl");
    setTimeout(function () {
      if (document.activeElement != liste & document.activeElement != data)
        liste.style.display = "none";
    }, 100)

  }

  basildiMarka = (value) => {
    let liste = document.getElementById("listeMarka");
    liste.innerHTML = '';
    if (value != "") {
 
      let markaAta = this.props.bilgiBankasi.markalar.filter(e => {
        return e.markaAdi != null && e.markaAdi.toString().toLowerCase().includes(value.toString().toLowerCase())
      })

      markaAta.map(item => {
        var myOption = document.createElement("option");
        myOption.value = item.markaKodu;
        myOption.text = item.markaAdi;
        liste.appendChild(myOption);
      })
    } else {
      this.props.bilgiBankasi.markalar.map(item => {
        var myOption = document.createElement("option");
        myOption.value = item.markaKodu;
        myOption.text = item.markaAdi;
        liste.appendChild(myOption);
      })
    }
  }
  aktifMarka = () => {
    let liste = document.getElementById("listeMarka");
    liste.style.display = "block";
    this.basildiMarka("")
  }

  secildiMarka = () => {
    let liste = document.getElementById("listeMarka");
    let data = document.getElementById("dataMarka");

    var newListe = this.props.bilgiBankasi.markalar.filter(function (el) {
      return el.markaKodu == liste.value
    });

    data.value = newListe[0].markaAdi;
   let selectValue = newListe[0].markaKodu;

   this.markaAtama(selectValue);
   
    this.pasifMarka();
    let timer = setInterval(()=>{
      if(this.state.markaKodu== undefined || this.state.markaKodu == ""){
        clearInterval(timer)
        this.setState({markaKodu: selectValue})
      }
    })
  }

  pasifMarka = () => {
    let liste = document.getElementById("listeMarka");
    liste.style.display = "none";
  }

  pasif2Marka = () => {
    let liste = document.getElementById("listeMarka");
    let data = document.getElementById("dataMarka");
    setTimeout(function () {
      if (document.activeElement != liste & document.activeElement != data)
        liste.style.display = "none";
    }, 100)

  }

  basildiMarka2 = (value) => {
    let liste = document.getElementById("listeMarka2");
    liste.innerHTML = '';
    if (value != "") {
 
      let markaAta = this.props.bilgiBankasi.markalar.filter(e => {
        return e.markaAdi != null && e.markaAdi.toString().toLowerCase().includes(value.toString().toLowerCase())
      })

      markaAta.map(item => {
        var myOption = document.createElement("option");
        myOption.value = item.markaKodu;
        myOption.text = item.markaAdi;
        liste.appendChild(myOption);
      })
    } else {
      this.props.bilgiBankasi.markalar.map(item => {
        var myOption = document.createElement("option");
        myOption.value = item.markaKodu;
        myOption.text = item.markaAdi;
        liste.appendChild(myOption);
      })
    }
  }
  aktifMarka2 = () => {
    let liste = document.getElementById("listeMarka2");
    liste.style.display = "block";
    this.basildiMarka2("")
  }

  secildiMarka2 = () => {
    let liste = document.getElementById("listeMarka2");
    let data = document.getElementById("dataMarka2");

    var newListe = this.props.bilgiBankasi.markalar.filter(function (el) {
      return el.markaKodu == liste.value
    });

    data.value = newListe[0].markaAdi;
   let selectValue = newListe[0].markaKodu;

   this.markaAtama(selectValue);
   
    this.pasifMarka2();
    let timer = setInterval(()=>{
      if(this.state.markaKodu== undefined || this.state.markaKodu == ""){
        clearInterval(timer)
        this.setState({markaKodu: selectValue})
      }
    })
  }

  pasifMarka2 = () => {
    let liste = document.getElementById("listeMarka2");
    liste.style.display = "none";
  }

  pasif2Marka2 = () => {
    let liste = document.getElementById("listeMarka2");
    let data = document.getElementById("dataMarka2");
    setTimeout(function () {
      if (document.activeElement != liste & document.activeElement != data)
        liste.style.display = "none";
    }, 100)

  }

  basildi = (value) => {
    let liste = document.getElementById("liste");
    liste.innerHTML = '';
    if (value != "") {

      let tipAta = this.props.bilgiBankasi.tipler.filter(e => {
        return e.tipAdi.toString().toLowerCase().includes(value.toString().toLowerCase())
      })


      tipAta.map(item => {
        var myOption = document.createElement("option");
        myOption.value = item.aracKodu;
        myOption.text = item.tipAdi;
        liste.appendChild(myOption);
      })
    } else {
      this.props.bilgiBankasi.tipler.map(item => {
        var myOption = document.createElement("option");
        myOption.value = item.aracKodu;
        myOption.text = item.tipAdi;
        liste.appendChild(myOption);
      })
    }
  }
  aktif = () => {
    let liste = document.getElementById("liste");
    liste.style.display = "block";
    this.basildi("")
  }

  secildi = () => {
    let liste = document.getElementById("liste");
    let data = document.getElementById("data");

    var newListe = this.props.bilgiBankasi.tipler.filter(function (el) {
      return el.aracKodu == liste.value
    });

    data.value = newListe[0].tipAdi;
   let selectValue = newListe[0].tipKodu;

    this.pasif();
    let timer = setInterval(()=>{
      if(this.state.tipKodu== undefined || this.state.tipKodu == ""){
        clearInterval(timer)
        this.aracBedeli(selectValue)
      }
    })
  }

  pasif = () => {
    let liste = document.getElementById("liste");
    liste.style.display = "none";
  }

  pasif2 = () => {
    let liste = document.getElementById("liste");
    let data = document.getElementById("data");
    setTimeout(function () {
      if (document.activeElement != liste & document.activeElement != data)
        liste.style.display = "none";
    }, 100)

  }

  basildiPlakaYok = (value) => {
    let listePlakaYok = document.getElementById("listePlakaYok");
    //listePlakaYok.innerHTML = '';
    if (value != "") {

      let tipAta = this.props.bilgiBankasi.tipler.filter(e => {
        return e.tipAdi.toString().toLowerCase().includes(value.toString().toLowerCase())
      })


      tipAta.map(item => {
        var myOption = document.createElement("option");
        myOption.value = item.aracKodu;
        myOption.text = item.tipAdi;
        listePlakaYok.appendChild(myOption);
      })
    } else {

      this.props.bilgiBankasi.tipler.map(item => {
        var myOption = document.createElement("option");
        myOption.value = item.aracKodu;
        myOption.text = item.tipAdi;
        listePlakaYok.appendChild(myOption);
      })
    }
  }
  aktifPlakaYok = () => {
    let listePlakaYok = document.getElementById("listePlakaYok");
    listePlakaYok.style.display = "block";
    //this.basildiPlakaYok("")
  }


  secildiPlakaYok = () => {
    let listePlakaYok = document.getElementById("listePlakaYok");
    let dataPlakaYok = document.getElementById("dataPlakaYok");

      var newListe = this.props.bilgiBankasi.tipler.filter(function (el) {
      return el.aracKodu == listePlakaYok.value
    });

    dataPlakaYok.value = newListe[0].tipAdi;
   let selectValue = newListe[0].tipKodu;

    this.pasifPlakaYok();
    let timer = setInterval(()=>{
      if(this.state.tipKodu== undefined || this.state.tipKodu == ""){
        clearInterval(timer)
        this.aracBedeli(selectValue)
      }
    })
  }

  pasifPlakaYok = () => {
    let listePlakaYok = document.getElementById("listePlakaYok");
    listePlakaYok.style.display = "none";
  }

  pasif2PlakaYok = () => {
    let listePlakaYok = document.getElementById("listePlakaYok");
    let dataPlakaYok = document.getElementById("dataPlakaYok");
    setTimeout(function () {
      if (document.activeElement != listePlakaYok & document.activeElement != dataPlakaYok)
        listePlakaYok.style.display = "none";
    }, 100)

  }

  tarihChange = (tarih) => {
    let yil = tarih.substring(0, 4);
    let ay = tarih.substring(5, 7);
    let gun = tarih.substring(8, 10);
    return gun + ' / ' + ay + ' / ' + yil
    }

    callMe = () => {
      let callMe = {
          sigortaliUnvan: this.props.customer.customer.musteriAdi,
          idendityNumber: this.state.idendityNumber,
          birthDate: this.state.birthDate,
          mobileNumber: this.state.mobileNumber
          .replace(" ", "")
          .replace("(", "")
          .replace(")", "")
          .replace("-", "")
          .replace("  ", ""),
          email: this.state.email
      }
      this.props.callMe(callMe);

      let timer = setInterval(() => {
        clearInterval(timer);
  
        this.openHataModal("Bilgi!","Talebiniz tarafımıza ulaşmıştır. En kısa zamanda dönüş sağlanacaktır.");
    }, 2000);

  }

  callMe2 = (item) => {
    let callMe = {
      sigortaliUnvan: this.props.customer.customer.musteriAdi,
      idendityNumber: this.state.idendityNumber,
        birthDate: this.state.birthDate,
        mobileNumber: this.state.mobileNumber
        .replace(" ", "")
        .replace("(", "")
        .replace(")", "")
        .replace("-", "")
        .replace("  ", ""),
        email: this.state.email,
        sirketKodu: item.sirketKodu
  }
    this.props.callMe(callMe);

    let timer = setInterval(() => {
      clearInterval(timer);

      this.openHataModal("Bilgi!","Talebiniz tarafımıza ulaşmıştır. En kısa zamanda dönüş sağlanacaktır.");
  }, 2000);

}

  render() {
    return (
      <div>

        <Modal
              isOpen={this.state.teklifModalIsOpen}
              onRequestClose={()=> this.closeModal()}
              style={customStyles}
              className="odeme-modal-box"
              ariaHideApp={false}
            >
              <div className="odeme-modal-container">
                <h4>
                  <b>Teklif Bilgileri</b>
                </h4>
                <div className="odeme-modal-line"></div>
                <div className="odeme-ozet">
                  <div className="odeme-bilgi-container">
                  <div className="taksit-box-container">
                  <img src={sirketImgURL + this.state.policy.sirketKodu + ".png"} alt="" style={{ height: 40 }} />
                    </div>
                    <div className="taksit-box-container">
                      <label>Toplam Tutar</label>
                      <span><b>{new Intl.NumberFormat('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.state.policy.prim)} TL</b></span>
                    </div>
                  </div>
                </div>
                <div className="odeme-ozet">
                  {/* <div className="odeme-bilgi-container">
                    <div className="cart-box-container">
                      <label>{this.props.teklif[0].sigortaliAdi}</label><br></br>
                      <label>{this.props.teklif[0].vehicleInfoResultDto.markaAdi}</label>
                      <label>{this.props.teklif[0].vehicleInfoResultDto.tipAdi}</label>
                      <label>{this.props.teklif[0].vehicleInfoResultDto.modelYili}</label><br></br>
                    </div>
                  </div> */}
                  <div className="karsilastir-modal-content">
              <div className="karsilastir-container">
                <div className="karsilastir-teminat-box">
                  <div className="table-responsive">
                  <table className="table table-hover">
                      <tbody>
                        {this.state.karsilastirmaListesi.map((teminat) => (
                          <tr>
                            <td>{teminat.name}</td>
                            <td>{teminat[this.state.policy.sirketKodu]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
                </div>
                <br></br>
         <Button onClick={() => this.closeModal()}>Kapat</Button>
              </div>
            </Modal>
        <Modal
              isOpen={this.state.policeModalIsOpen}
              style={customStyles}
              className="odeme-modal-box"
              ariaHideApp={false}
            >
              <div className="odeme-modal-container">
                <h4>
                  <b>Tebrikler!</b>
                </h4>
                <div className="odeme-ozet">
                  <div className="odeme-bilgi-container" style={{ marginTop:20}}>
                  <img src={sirketImgURL + this.props.policy.policy.sirketKodu + ".png"} alt="" style={{ height: 40 }} />

                  </div>
                  <div className="odeme-bilgi-container" style={{ fontSize:20, marginTop:20}}>
                  
                  <p>Artık güvence altındasınız!</p>
                  </div>
                  <div className="odeme-bilgi-container">
                  <b>Poliçe Numarası = {this.props.policy.policy.policeNo}</b>
                  </div>
                </div>
                <div className="odeme-bilgi-container" style={{ marginTop:20}}>
                  <p>Poliçe talebiniz doğrultusunda Türk Havak Sigorta kanalıyla</p>
                  </div>
                  <div className="odeme-bilgi-container">
                  <p>{this.props.policy.policy.sirketAdi} Şirketi tarafından düzenlenmiştir.</p>
                  </div>
                <div>
                      <Link to="" onClick={()=> this.pdfYazdir()}>Pdf Yazdır</Link>
                    </div>
                    <br></br>
         <Button onClick={() => this.closeModal()}>Kapat</Button>
              </div>
            </Modal>
        <Modal
          isOpen={this.state.sepetModalIsOpen}
          onRequestClose={() => this.closeModal()}
          className="sepet-modal-box"
          style={customStyles}
          ariaHideApp={false}
        >
          <div className="sepet-container">
            <h4>
              <b>Sigorta Sepeti</b>
            </h4>
            {this.props.proposal.cart.length != 0 ? (
              <div>
                {this.props.proposal.cart.map((cart) => (
                  <div className="teklif-karsilastirma-box">
                    <div style={{ width: 80 }}>
                      <img
                        src={sirketImgURL + cart.sirketKodu + ".png"}
                        alt=""
                        style={{ height: 25 }}
                      />
                    </div>
                    <div className="teklif-karsilastirma-line"></div>
                    <div style={{ width: 50, textAlign: "center" }}>
                      <p>Kasko</p>
                      <p>Eko Paket</p>
                    </div>
                    <div className="teklif-karsilastirma-line"></div>
                    <div>
                      <p>
                        {new Intl.NumberFormat("tr-TR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(cart.prim)}{" "}
                        TL
                      </p>
                    </div>
                    <Link to="" onClick={() => this.props.removeToCart(cart)}>
                      <img
                        src={close1}
                        alt=""
                        style={{ width: 14, height: 14 }}
                      />
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <label>Sepetiniz Boş</label>
            )}
            {this.props.proposal.cart.length == 0 ? (
              <Link
                to="#"
                disabled={true}
                className="sepet-button"
                onClick={() => this.stepper("adim8")}
              >
                Satın Al
              </Link>
            ) : (
              <Link
                to="#"
                className="sepet-button"
                onClick={() => this.stepper("adim8")}
              >
                Satın Al
              </Link>
            )}
          </div>
        </Modal>
        <Modal
          isOpen={this.state.karsilastirModalIsOpen}
          onRequestClose={() => this.closeModal()}
          className="karsilastirma-modal-box"
          style={customStyles}
          ariaHideApp={false}
        >
          <div className="karsilastir-modal-container">
            <h4>
              <b>Karşılaştırma Ekranı</b>
            </h4>
            <div className="karsilastir-modal-content">
              <div className="karsilastir-container">
                <div className="karsilastir-teminat-box">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead className="align-middle">
                        <tr>
                          <td colspan="3">Genel Teminat İsimleri</td>
                          {this.state.sirketKodlari.map((sirket) => (
                            <td colspan="3">
                              <img
                                src={sirketImgURL + sirket + ".png"}
                                alt=""
                                className="modalImg"
                              />
                            </td>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.karsilastirmaListesi.map((teminat) => (
                          <tr>
                            <td colspan="3">{teminat.name}</td>
                            {this.state.sirketKodlari.map((sirket) => (
                              <td colspan="3">{teminat[sirket]}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
         <Button onClick={() => this.closeModal()}>Kapat</Button>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.odemeModalIsOpen}
          onRequestClose={() => this.closeModal()}
          style={customStyles}
          className="odeme-modal-box"
          ariaHideApp={false}
        >
          <div className="odeme-modal-container">
            <h4>
              <b>Ödeme Bilgileri</b>
            </h4>
            <div className="odeme-modal-line"></div>
            <div className="odeme-ozet">
              <div className="odeme-bilgi-container">
                <div className="taksit-box-container">
                  <img
                    src={sirketImgURL + this.state.policy.sirketKodu + ".png"}
                    alt=""
                    style={{ height: 30 }}
                  />
                </div>
                <div className="taksit-box-container">
                  <label>Toplam Tutar</label>
                  <span>
                    <b>
                      {new Intl.NumberFormat("tr-TR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(this.state.policy.prim)}{" "}
                      TL
                    </b>
                  </span>
                </div>
              </div>
            </div>
            <div className="odeme-modal-line"></div>
            <div className="odeme-ozet">
              <div className="odeme-bilgi-container">
                <div className="odeme-box-container">
                <div className="text-center mt-2" style={{ display: "inline-grid" }}>
                    <label style={{ marginBottom: "10px" }}>Kart Sahibinin</label>
                    <div className="d-flex flex-row justify-content-between align-items-center flex-wrap">
                      <div className="text-center">
                        <input
                          type="text"
                          className="cart-box-input"
                          placeholder="Adı"
                          value={this.state.kartSahibiAdi}
                          onChange={(event) =>
                            this.setState({ kartSahibiAdi: event.target.value })
                          }
                        />
                      </div>
                      <div className="text-center">
                        <input
                          type="text"
                          className="cart-box-input"
                          placeholder="Soyadı"
                          value={this.state.kartSahibiSoyadi}
                          onChange={(event) =>
                            this.setState({
                              kartSahibiSoyadi: event.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-2" style={{ display: "inline-grid" }}>
                    <label style={{ marginBottom: "10px" }}>Kart Numarası</label>
                    <input
                      type="text"
                      className="cart-box"
                      maxLength={16}
                      placeholder="Kredi Kartı Numarası"
                      value={this.state.kartNumarasi}
                      onChange={(event) =>
                        this.setState({ kartNumarasi: event.target.value.replace(/\D/g, '') })
                      }
                    />
                  </div>
                  <div className="text-center mt-2" style={{ display: "inline-grid" }}>
                    <label style={{ marginBottom: "10px" }}>Son Kullanma Tarihi</label>
                    <div className="d-flex flex-row justify-content-between align-items-center flex-wrap">
                      <select
                        className="cart-box-input"
                        value={this.state.ay}
                        onChange={(event) =>
                          this.setState({ ay: event.target.value })
                        }
                      >
                        <option selected>Ay Seçiniz</option>
                        {ay.map((a) => (
                          <option value={a.value}>{a.name}</option>
                        ))}
                      </select>
                      <select
                        className="cart-box-input"
                        value={this.state.yil}
                        onChange={(event) =>
                          this.setState({ yil: event.target.value })
                        }
                      >
                        <option>Yıl Seçiniz</option>
                        {yil.map((y) => (
                          <option value={y.value}>{y.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt-2">
                    <div className="text-center">
                      <input
                        type="text"
                        className="cart-box-input"
                        placeholder="CVV"
                        value={this.state.cvv}
                        onChange={(event) =>
                          this.setState({ cvv: event.target.value })
                        }
                      />
                    </div>
                    <select
                      className="cart-box-input"
                      value={this.state.taksitSayisi}
                      onChange={(event) =>
                        this.setState({ taksitSayisi: event.target.value })
                      }
                    >
                      <option selected>Taksit Seçiniz</option>
                      {taksit.map((t) => (
                        <option value={t.value}>{t.name} Taksit</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="odeme-modal-line"></div>
            <div className="odeme-ozet">
              <div className="d-flex flex-column justify-content-center align-items-center flex-wrap">
                <div className="odeme-radio-box">
                  <div
                    className="form-check"
                    onClick={() => this.mesafeliModalIsOpen()}
                  >
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="yeni"
                        id="yeni"
                        value="yeni"
                      />
                      Mesafeli Satış Sözleşmesi’ni okudum, kabul ediyorum.
                    </label>
                  </div>
                  {/* <div
                    className="form-check"
                    onClick={() => this.bilgilendirmeModalIsOpen()}
                  >
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="uavt"
                        id="uavt"
                        value="uavt"
                      />
                      Bilgilendirme Formu’nu okudum, onaylıyorum.
                    </label>
                  </div> */}
                  {/* <div
                    className="form-check"
                    onClick={() => this.turkhavakModalIsOpen()}
                  >
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="uavt"
                        id="uavt"
                        value="uavt"
                      />
                      Türk Havak Üyelerine Özel İndirim Koşulları’nı
                      onaylıyorum.
                    </label>
                  </div> */}
                </div>
                <div className="odeme-button">
                  <Link to="" onClick={() => this.cPolicy("adim9")}>
                    Ödemeyi Onayla
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.yukleniyor}
          onRequestClose={() => this.closeModal()}
          className="yukleniyor-modal-box"
          style={customStyles}
          ariaHideApp={false}
        >
          <div id="yukleniyor">
                        <img src={wait2} alt="" />
                      </div>
                      <label id="yukleniyorLabel">Lütfen Bekleyiniz...</label>
        </Modal>
        <Modal
          isOpen={this.state.hataModalIsOpen}
          onRequestClose={() => this.closeModal()}
          className="odeme-modal-box"
          style={customStyles}
          ariaHideApp={false}
        >
          <div className="hata-modal-container">

            <h4>
              <b>{this.state.hatabaslik}</b>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: this.state.hata }}>
         </div>
         <br></br>
         <Button onClick={() => this.closeModal()}>Tamam</Button>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.gizlilikModalIsOpen}
          style={customStyles}
          className="gizlilik-modal-box"
          ariaHideApp={false}
        >
          <div className="gizlilik-modal-container">
            <h4>Gizlilik Politikası ve Kullanıcı Sözleşmesi</h4>
            <div className="gizlilik-container">
              <p>
                Lütfen sitemizi kullanmadan evvel bu site kullanım şartlarını
                dikkatlice okuyunuz. Bu alışveriş sitesini kullanan ve alışveriş
                yapan müşterilerimiz aşağıdaki şartları kabul etmiş
                varsayılmaktadır: Sitemizdeki web sayfaları ve ona bağlı tüm
                sayfalar (site) Yeşilköy Mah, THY Genel Müdürlük Binası Yanı,
                Atatürk Havalimanı Yolu, 34149 Bakırköy/İstanbul adresindeki
                TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ. firmasının (Firma) malıdır
                ve onun tarafından işletilir. Sizler (Kullanıcı) sitede sunulan
                tüm hizmetleri kullanırken aşağıdaki şartlara tabi olduğunuzu,
                sitedeki hizmetten yararlanmakla ve kullanmaya devam etmekle;
                Bağlı olduğunuz yasalara göre sözleşme imzalama hakkına,
                yetkisine ve hukuki ehliyetine sahip ve 18 yaşın üzerinde
                olduğunuzu, bu sözleşmeyi okuduğunuzu, anladığınızı ve
                sözleşmede yazan şartlarla bağlı olduğunuzu kabul etmiş
                sayılırsınız.
              </p>
              <p>
                İşbu sözleşme taraflara sözleşme konusu site ile ilgili hak ve
                yükümlülükler yükler ve taraflar işbu sözleşmeyi kabul
                ettiklerinde bahsi geçen hak ve yükümlülükleri eksiksiz, doğru,
                zamanında, işbu sözleşmede talep edilen şartlar dâhilinde yerine
                getireceklerini beyan ederler.
              </p>
              <p>
                <b>1 SORUMLULUKLAR</b>
              </p>
              <p>
                <b>a.</b>Firma, fiyatlar ve sunulan ürün ve hizmetler üzerinde
                değişiklik yapma hakkını her zaman saklı tutar.
              </p>
              <p>
                <b>b.</b>Firma, üyenin sözleşme konusu hizmetlerden, teknik
                arızalar dışında yararlandırılacağını kabul ve taahhüt eder.
              </p>
              <p>
                <b>c.</b>Kullanıcı, sitenin kullanımında tersine mühendislik
                yapmayacağını ya da bunların kaynak kodunu bulmak veya elde
                etmek amacına yönelik herhangi bir başka işlemde bulunmayacağını
                aksi halde ve 3 Kişiler nezdinde doğacak zararlardan sorumlu
                olacağını, hakkında hukuki ve cezai işlem yapılacağını peşinen
                kabul eder.
              </p>
              <p>
                <b>d.</b>Kullanıcı, site içindeki faaliyetlerinde, sitenin
                herhangi bir bölümünde veya iletişimlerinde genel ahlaka ve
                adaba aykırı, kanuna aykırı, 3 Kişilerin haklarını zedeleyen,
                yanıltıcı, saldırgan, müstehcen, pornografik, kişilik haklarını
                zedeleyen, telif haklarına aykırı, yasa dışı faaliyetleri teşvik
                eden içerikler üretmeyeceğini, paylaşmayacağını kabul eder. Aksi
                halde oluşacak zarardan tamamen kendisi sorumludur ve bu durumda
                Site yetkilileri, bu tür hesapları askıya alabilir, sona
                erdirebilir, yasal süreç başlatma hakkını saklı tutar. Bu
                sebeple yargı mercilerinden etkinlik veya kullanıcı hesapları
                ile ilgili bilgi talepleri gelirse paylaşma hakkını saklı tutar.
              </p>
              <p>
                <b>e.</b>Sitenin üyelerinin birbirleri veya üçüncü şahıslarla
                olan ilişkileri kendi sorumluluğundadır.
              </p>
              <p>
                <b>2 FİKRİ MÜLKİYET HAKLARI</b>
              </p>
              <p>
                <b>2.1.</b>İşbu Site de yer alan unvan , işletme adı, marka,
                patent, logo, tasarım, bilgi ve yöntem gibi tescilli veya
                tescilsiz tüm fikri mülkiyet hakları site işleteni ve sahibi
                firmaya veya belirtilen ilgilisine ait olup, ulusal ve
                uluslararası hukukun koruması altındadır. İşbu Site’nin ziyaret
                edilmesi veya bu Site’deki hizmetlerden yararlanılması söz
                konusu fikri mülkiyet hakları konusunda hiçbir hak vermez.
              </p>
              <p>
                <b>2.2.</b>Site’de yer alan bilgiler hiçbir şekilde
                çoğaltılamaz, yayınlanamaz, kopyalanamaz, sunulamaz ve/veya
                aktarılamaz. Site’nin bütünü veya bir kısmı diğer bir internet
                sitesinde izinsiz olarak kullanılamaz.
              </p>
              <p>
                <b>3 GİZLİ BİLGİ</b>
              </p>
              <p>
                <b>3.1.</b>Firma, site üzerinden kullanıcıların ilettiği kişisel
                bilgileri 3 Kişilere açıklamayacaktır. Bu kişisel bilgiler; kişi
                adı-soyadı, adresi, telefon numarası, cep telefonu, e-posta
                adresi gibi Kullanıcıyı tanımlamaya yönelik her türlü diğer
                bilgiyi içermekte olup, kısaca “Gizli Bilgiler” olarak
                anılacaktır.
              </p>
              <p>
                <b>3.2.</b>Kullanıcı, sadece tanıtım, reklam, kampanya,
                promosyon, duyuru vb. pazarlama faaliyetleri kapsamında
                kullanılması ile sınırlı olmak üzere, Sitenin sahibi olan
                firmanın kendisine ait iletişim, portföy durumu ve demografik
                bilgilerini iştirakleri ya da bağlı bulunduğu grup şirketleri
                ile paylaşmasına muvafakat ettiğini kabul ve beyan eder. Bu
                kişisel bilgiler firma bünyesinde müşteri profili belirlemek,
                müşteri profiline uygun promosyon ve kampanyalar sunmak ve
                istatistiksel çalışmalar yapmak amacıyla kullanılabilecektir.
              </p>
              <p>
                <b>3.3.</b>Gizli Bilgiler, ancak resmi makamlarca usulü
                dairesinde bu bilgilerin talep edilmesi halinde ve yürürlükteki
                emredici mevzuat hükümleri gereğince resmi makamlara açıklama
                yapılmasının zorunlu olduğu durumlarda resmi makamlara
                açıklanabilecektir.
              </p>
              <p>
                <b>4 GARANTİ VERMEME</b>
              </p>
              <p>
                İŞBU SÖZLEŞME MADDESİ UYGULANABİLİR KANUNUN İZİN VERDİĞİ AZAMİ
                ÖLÇÜDE GEÇERLİ OLACAKTIR. FİRMA TARAFINDAN SUNULAN HİZMETLER
                "OLDUĞU GİBİ VE "MÜMKÜN OLDUĞU, TEMELDE SUNULMAKTA VE
                PAZARLANABİLİRLİK, BELİRLİ BİR AMACA UYGUNLUK VEYA İHLAL ETMEME
                KONUSUNDA TÜM ZIMNİ GARANTİLER DE DÂHİL OLMAK ÜZERE HİZMETLER
                VEYA UYGULAMA İLE İLGİLİ OLARAK (BUNLARDA YER ALAN TÜM BİLGİLER
                DÂHİL) SARİH VEYA ZIMNİ, KANUNİ VEYA BAŞKA BİR NİTELİKTE HİÇBİR
                GARANTİDE BULUNMAMAKTADIR.
              </p>
              <p>
                <b>5 KAYIT ve GÜVENLİK</b>
              </p>
              <p>
                Kullanıcı, doğru, eksiksiz ve güncel kayıt bilgilerini vermek
                zorundadır. Aksi halde bu Sözleşme ihlal edilmiş sayılacak ve
                Kullanıcı bilgilendirilmeksizin hesap kapatılabilecektir.
                Kullanıcı, site ve üçüncü taraf sitelerdeki şifre ve hesap
                güvenliğinden kendisi sorumludur. Aksi halde oluşacak veri
                kayıplarından ve güvenlik ihlallerinden veya donanım ve
                cihazların zarar görmesinden Firma sorumlu tutulamaz.
              </p>
              <p>
                <b>6 MÜCBİR SEBEP</b>
              </p>
              <p>
                Tarafların kontrolünde olmayan; tabii afetler, yangın,
                patlamalar, iç savaşlar, savaşlar, ayaklanmalar, halk
                hareketleri, seferberlik ilanı, grev, lokavt ve salgın
                hastalıklar, altyapı ve internet arızaları, elektrik kesintisi
                gibi sebeplerden (aşağıda birlikte "Mücbir Sebep” olarak
                anılacaktır.) dolayı sözleşmeden doğan yükümlülükler taraflarca
                ifa edilemez hale gelirse, taraflar bundan sorumlu değildir. Bu
                sürede Tarafların işbu Sözleşmeden doğan hak ve yükümlülükleri
                askıya alınır.
              </p>
              <p>
                <b>7 SÖZLEŞMENİN BÜTÜNLÜĞÜ ve UYGULANABİLİRLİK</b>
              </p>
              <p>
                İşbu sözleşme şartlarından biri, kısmen veya tamamen geçersiz
                hale gelirse, sözleşmenin geri kalanı geçerliliğini korumaya
                devam eder.
              </p>
              <p>
                <b>8 SÖZLEŞMEDE YAPILACAK DEĞİŞİKLİKLER</b>
              </p>
              <p>
                Firma, dilediği zaman sitede sunulan hizmetleri ve işbu sözleşme
                şartlarını kısmen veya tamamen değiştirebilir. Değişiklikler
                sitede yayınlandığı tarihten itibaren geçerli olacaktır.
                Değişiklikleri takip etmek Kullanıcının sorumluluğundadır.
                Kullanıcı, sunulan hizmetlerden yararlanmaya devam etmekle bu
                değişiklikleri de kabul etmiş sayılır.
              </p>
              <p>
                <b>9 TEBLİGAT</b>
              </p>
              <p>
                İşbu Sözleşme ile ilgili taraflara gönderilecek olan tüm
                bildirimler, Firma’nın bilinen e.posta adresi ve kullanıcının
                üyelik formunda belirttiği e.posta adresi vasıtasıyla
                yapılacaktır. Kullanıcı, üye olurken belirttiği adresin geçerli
                tebligat adresi olduğunu, değişmesi durumunda 5 gün içinde
                yazılı olarak diğer tarafa bildireceğini, aksi halde bu adrese
                yapılacak tebligatların geçerli sayılacağını kabul eder.
              </p>
              <p>
                <b>10 DELİL SÖZLEŞMESİ</b>
              </p>
              <p>
                Taraflar arasında işbu sözleşme ile ilgili işlemler için
                çıkabilecek her türlü uyuşmazlıklarda Tarafların defter, kayıt
                ve belgeleri ile ve bilgisayar kayıtları ve faks kayıtları 6100
                sayılı Hukuk Muhakemeleri Kanunu uyarınca delil olarak kabul
                edilecek olup, kullanıcı bu kayıtlara itiraz etmeyeceğini kabul
                eder.
              </p>
              <p>
                <b>11 UYUŞMAZLIKLARIN ÇÖZÜMÜ</b>
              </p>
              <p>
                İşbu Sözleşme’nin uygulanmasından veya yorumlanmasından doğacak
                her türlü uyuşmazlığın çözümünde İstanbul (Merkez) Adliyesi
                Mahkemeleri ve İcra Daireleri yetkilidir.
              </p>
            </div>
            <Button onClick={() => this.closeModal()}>
              Okudum ve Onaylıyorum.
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.kvkkModalIsOpen}
          style={customStyles}
          className="gizlilik-modal-box"
          ariaHideApp={false}
        >
          <div className="gizlilik-modal-container">
            <h4>Kişisel Verilerin Korunması Kanunu</h4>
            <div className="gizlilik-container">
              <p>
                6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”)
                gereğince, kişinin özgeçmişi içerisinde yer alan adı/soyadı,
                adresi, doğum yeri ve tarihi, telefon numarası, elektronik posta
                adresi, öğrenim durumu, eğitim bilgileri, iş deneyimleri,
                yabancı dil bilgisi, katıldığı seminer, konferans ve sertifika
                programları, referansları ile ilgili kişiyi tanıtan her türlü
                bilgi ve belge, kişisel veri kapsamındadır. Yukarıda bahsi geçen
                kişisel verileriniz; “Veri Sorumlusu” olarak “TÜRKHAVAK SİGORTA
                ACENTELİĞİ LTD.ŞTİ.” tarafından aşağıda açıklanan kapsamda
                işlenebilecektir.{" "}
              </p>
              <p>
                <b>1. Kişisel Verilerin Hangi Amaçla İşleneceği:</b>
              </p>
              <p>
                <b>1.1.</b>Kişisel verileriniz; sigortacılık mevzuatı başta
                olmak üzere, kanunlar ve sair mevzuat kapsamında sigorta
                teklifleri ve hayat ve bireysel emeklilik sözleşmeleri, poliçe
                ve sözleşme gereğinin ifa edilebilmesi, risk değerlendirmesi
                yapılabilmesi, sigorta satış ve pazarlama faaliyetleri, hasar
                ödeme ve asistans faaliyetleri başta olmak üzere sigorta
                şirketleri ve diğer iş ortaklarımız aracılığıyla sigorta
                sözleşmeleri kapsamında sizlere verilecek hizmetlerin yerine
                getirilmesi süreçlerinde sigorta primlerinin belirlenebilmesi,
                tahsilat yapılabilmesi, sigortacılık ve bireysel emeklilik ürün
                ve hizmetlerinin, avantaj veya kampanyaların sunulabilmesi,
                bilgilendirme, tanıtım, pazarlama ve satış da dahil olmak üzere
                her türlü iletişimin sağlanması ve bu amaçlarla ticari
                elektronik ileti gönderilebilmesi, mevzuatta belirtilen
                yükümlülüklerin ifa edilebilmesi, işlem yapanın/yaptıranın
                kimliğinin tespit edilebilmesi, elektronik ortamda veya basılı
                olarak kayıt ve belge düzenlenebilmesi, mevzuatta öngörülen
                evrak saklama, raporlama ve bilgilendirme yükümlülüklerinin
                yerine getirilebilmesi ve istatistiksel amaçlarla,{" "}
              </p>
              <p>
                <b>1.2.</b>Kişisel verileriniz, otomatik ya da otomatik olmayan
                yöntemlerle, TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ.ne ait
                internet sitesi, sosyal medya mecraları, mobil uygulamalar ve
                benzeri vasıtalarla sözlü, yazılı ya da elektronik olarak
                toplanmaktadır. Eğitim, seminer, kurs, konferans vb.
                hizmetlerden yararlandığınız müddetçe, düzenlenen
                organizasyonlara katıldığınızda, web sitesini veya sosyal medya
                hesaplarını ziyaret ettiğinizde toplanabilecek,
                güncellenebilecek ve
              </p>
              <p>
                <b>1.3.</b>Kişisel verileriniz, iş başvuru talebinizin ve ilgili
                pozisyona uygunluğunuzun değerlendirilmesi, TÜRKHAVAK SİGORTA
                ACENTELİĞİ LTD.ŞTİ.’in insan kaynakları politikaları
                çerçevesinde işe alım süreçlerinin yürütülmesi,
                sonuçlandırılması, sonucun olumsuz olması durumlarında sonradan
                oluşacak benzer pozisyonlarda başvurunuzun değerlendirmeye
                alınması, bu kapsamda sizinle iletişime geçilmesi ve TÜRKHAVAK
                SİGORTA ACENTELİĞİ LTD.ŞTİ.’nın uyguladığı işe alım ilkelerinin
                geliştirilmesi ve iyileştirilmesi amaçları dâhilinde,
                işlenecektir.{" "}
              </p>
              <p>
                <b>
                  2. İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla
                  Aktarılabileceği:
                </b>
              </p>
              <p>
                Toplanan kişisel verileriniz; yukarıda belirtilen sebepler,
                amaçlar ve Kanun’da yer alan düzenlemeler çerçevesinde; “
                TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ. “ tarafından dâhili olarak
                kullanılacağı gibi Sigortacılık ve sair mevzuat çerçevesinde;
                T.C. Başbakanlık Hazine Müsteşarlığı Sigortacılık Genel
                Müdürlüğü, Sigorta Bilgi ve Gözetim Merkezi başta olmak üzere
                zorunlu raporlamaların hazırlanabilmesi amacıyla çeşitli kurum
                ve kuruluşlara; reasürörlere, sigorta ve hayat ve bireysel
                emeklilik şirketleri ve aracılarına, poliçe ve sözleşme
                taraflarına, doğrudan ve dolaylı yurtiçi ve yurtdışı topluluk
                şirketleri ve iştiraklerine, mevzuatta belirtilen amaçlara konu
                faaliyetlerini yürütmek üzere hizmet alınan, işbirliği yapılan
                kişi ve kuruluşlar ile destek hizmeti sağlayıcılarına mevzuatın
                izin verdiği ölçüde sigortacılık faaliyetinin yürütülebilmesi
                için hizmet alınan bilişim teknolojileri, aktüerya, ekspertiz
                şirketleri gibi özel kuruluşlara; sigortacılık hizmetlerimiz
                çerçevesinde fiyatlama, satış ve pazarlama işlemleri ile
                istatistiksel analizler yapmak üzere Sigorta Şirketlerine,
                Şubelerimize ve diğer iştiraklerimize, Sosyal Güvenlik Kurumu ve
                kanunen yetkili diğer kamu kurumları, faaliyetlerimizi
                yürütebilmek amacıyla işbirliği yaptığımız kurum ve
                kuruluşlarla, verilerin saklanması için hizmetlerinden
                faydalandığımız, fiziksel server ve/veya bulut hizmeti aldığımız
                yurt içi/yurt dışı kişi ve kurumlarla, ticari elektronik
                iletilerin gönderilmesi konusunda hizmet aldığımız yurt içi/yurt
                dışı firmalarla, anlaşmalı olduğumuz bankalarla, uluslararası
                sertifikasyon sahibi ödeme kuruluşlarıyla, yurtiçi/yurtdışı
                çeşitli reklam şirketleri, ajanslar ve anket şirketleriyle ya da
                yurtiçi/yurtdışı diğer iş ortaklarımızla, kanunen yetkili kamu
                kurumları ve özel kişilere yurtiçi ve yurtdışında KVKK’nın 8. ve
                9. maddelerinde belirtilen kişisel veri işleme şartları ve
                amaçları çerçevesinde, aktarılabilecektir.{" "}
              </p>
              <p>
                <b>3. Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi: </b>
              </p>
              <p>
                <b>3.1.</b>Kişisel verileriniz, Kanunun 5. ve 6. maddelerinde
                belirtilen hukuki sebeplere dayanarak; “TÜRKHAVAK SİGORTA
                ACENTELİĞİ LTD.ŞTİ.” Şubeleri, Çağrı Merkezi, İnternet Şubesi,
                dijital kanallar, poliçe ve sözleşme tarafları, aracılar ile
                bunların hizmet verdiği tüm kanallar, yurt içi/yurt dışı
                iştirakler, işbirliği yapılan kurum ve kuruluşlar, sigorta ve
                hayat ve bireysel emeklilik şirketleri, resmi kurumlar, yurt
                içi/yurt dışı bankalar ve diğer üçüncü kişiler aracılığıyla;
                sözlü, yazılı veya elektronik ortamda; tamamen veya kısmen
                otomatik olan ya da herhangi bir veri kayıt sisteminin parçası
                olarak otomatik olmayan yollarla toplanabilecek ve{" "}
              </p>
              <p>
                <b>3.2.</b>Sigorta poliçeleri ve emeklilik sözleşmelerinin
                taraflarına ait kişisel/özel nitelikli kişisel veriler; merkez
                ve şubelerimiz, internet uygulamalarımız ve çağrı merkezimiz
                aracılığı ile doğrudan doğruya sizlerden ve sigorta
                sözleşmelerinden kaynaklanan yükümlülüklerin yerine
                getirilebilmesi için kamu kurumları tarafından tarafımıza erişim
                yetkisi verilen veri tabanlarından derlenmekte ve.{" "}
              </p>
              <p>
                <b>3.3.</b>Kişisel verileriniz, TÜRKHAVAK SİGORTA ACENTELİĞİ
                LTD.ŞTİ.’nın insan kaynakları politikaları çerçevesinde işe alım
                süreçlerinin yürütülmesi, sonuçlandırılması ve bu kapsamda
                sizinle iletişime geçilmesi amaçlarıyla edinilmekte ve bu hukuki
                sebeple toplanan kişisel verileriniz otomatik ya da otomatik
                olmayan yöntemlerle, sözlü, yazılı ve elektronik ortam da dahil
                olmak üzere çeşitli toplama yöntemleri vasıtasıyla toplanmakta
                ve
              </p>
              <p>
                <b>3.4.</b>Kişisel Verileriniz, “6698 sayılı Kişisel Verilerin
                Korunması Kanunu” ve sair mevzuat çerçevesinde sadece
                sigortacılık faaliyetlerinin yürütülmesi amacı ile ve bu amacın
                gerektirdiği yasal sürelerle sınırlı olarak işlenmektedir
              </p>
              <p>
                <b>
                  4. Veri Sahibinin Kanun’un 11. maddesinde Sayılan Hakları:
                </b>
              </p>
              <p>
                Kanun’un 11. maddesi uyarınca, kişisel veri sahibi olarak,
                yazılı veya Kişisel Verileri Koruma Kurulu’nun belirlediği diğer
                yöntemlerle TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ.’ya başvurarak,{" "}
              </p>
              <p>
                <b>4.1.</b>Kişisel verilerinizin işlenip işlenmediğini öğrenme,{" "}
              </p>
              <p>
                <b>4.2.</b>Kişisel verileriniz işlenmişse buna ilişkin bilgi
                talep etme,{" "}
              </p>
              <p>
                <b>4.3.</b>Kişisel verileriniz işleniyorsa,
              </p>
              <p>
                <b>4.4.</b>Kişisel verilerinizin işlenme amacını ve bunların
                amacına uygun kullanıp kullanılmadığını öğrenme,
              </p>
              <p>
                <b>4.5.</b>Kişisel verileriniz yurt içinde veya yurt dışında
                üçüncü kişilere aktarılıyorsa, kişisel verilerinizin aktarıldığı
                üçüncü kişileri bilme,
              </p>
              <p>
                <b>4.6.</b>Kişisel verilerinizin eksik veya yanlış işlenmiş
                olması halinde bunların düzeltilmesini isteme ve bu kapsamda
                yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere
                bildirilmesini isteme,{" "}
              </p>
              <p>
                <b>4.7.</b>Kişisel verilerinizin Kanun ve ilgili diğer kanun
                hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini
                gerektiren sebeplerin ortadan kalkması halinde kişisel
                verilerinizin silinmesini veya yok edilmesini isteme ve bu
                kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü
                kişilere bildirilmesini isteme,{" "}
              </p>
              <p>
                <b>4.8.</b>İşlenen verilerin münhasıran otomatik sistemler
                vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun
                ortaya çıkmasına itiraz etme,{" "}
              </p>
              <p>
                <b>4.9.</b>Kişisel verilerinizin kanuna aykırı olarak işlenmesi
                sebebiyle zarara uğramanız halinde, zararın giderilmesini talep
                etme haklarına sahipsiniz. Yukarıda belirtilen (Kanun’un 11.
                Maddesi) haklarınıza ilişkin kullanmak istediğiniz hakkınıza
                yönelik açıklamalarınızı içeren talebinizi TÜRKHAVAK SİGORTA
                ACENTELİĞİ LTD.ŞTİ.’nın /www.turkhavaksigorta.com adlı internet
                sitesinde yer alan “Başvuru Formu ”nu tam ve eksiksiz bir
                şekilde doldurarak, kimlik fotokopiniz ve diğer iletişim
                bilgilerinizi de eklemek suretiyle ıslak imzalı olarak TÜRKHAVAK
                SİGORTA ACENTELİĞİ LTD.ŞTİ. VE Yeşilköy Mah, THY Genel Müdürlük
                Binası Yanı, Atatürk Havalimanı Yolu, 34149 Bakırköy/İstanbul
                adresine bizzat elden iletebilir, noter kanalıyla veya iadeli
                taahhütlü mektup yoluyla gönderebilirsiniz, Başvurunuzda yer
                alan talepleriniz, talebin niteliğine göre en kısa sürede ve en
                geç otuz gün içinde TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ.
                Tarafından ücretsiz olarak sonuçlandırılacaktır. Ancak, işlemin
                TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ. için ayrıca bir maliyet
                gerektirmesi hâlinde, Kişisel Verileri Koruma Kurulu tarafından
                belirlenen tarifedeki ücret alınacaktır.
              </p>
              <p>
                2 1 10.03.2018 tarih ve 30356 sayılı “Veri Sorumlusuna Başvuru
                Usul ve Esaslar ı Hakkında Tebliğ” uyarınca; veri sahipleri,
                Kanun’un 11. maddesinde belirtilen hakları kapsamında
                taleplerini, yazılı olarak veya kayıtlı elektronik posta (KEP)
                adresi, güvenli elektronik imza, mobil imza ya da veri sahipleri
                tarafından TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ. ’ya daha önce
                bildirilen ve TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ. nın
                sisteminde kayıtlı bulunan elektronik posta adresini kullanmak
                suretiyle veya başvuru amacına yönelik geliştirilmiş bir yazılım
                ya da uygulama vasıtasıyla TÜRKHAVAK SİGORTA ACENTELİĞİ
                LTD.ŞTİ.’ ya iletir.
              </p>
              <p>
                {" "}
                2 10.03.2018 tarih ve 30356 sayılı Resmî Gazete ’de yayınlanan
                “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ”
                uyarınca, veri sahiplerinin başvurusuna yazılı olarak cevap
                verilecekse, on sayfaya kadar ücret alınmaz. On sayfanın
                üzerindeki her sayfa için 1 Türk lirası işlem ücreti alınabilir.
              </p>
              <p>
                6698 sayılı Kişisel Verilerin Korunması Kanunu’nun “Veri
                Sorumlusunun Aydınlatma Yükümlülüğü” başlıklı 10. maddesi
                gereğince kişisel verilerimin kim tarafından, hangi amaçla
                işleneceği, işlenen kişisel verilerin kimlere ve hangi amaçla
                aktarılabileceği, kişisel veri toplamanın yöntemi ve hukuki
                sebebi ve Kanun’un 11. maddesinde yer alan haklarım konusunda
                hazırlanan işbu Aydınlatma Metnini okudum, anladım ve veri
                sorumlusu sıfatına sahip, TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ.
                tarafından bu konuda detaylı olarak bilgilendirildim.
              </p>
            </div>
            <Button onClick={() => this.closeModal()}>
              Okudum ve Onaylıyorum.
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.mesafeliModalIsOpen}
          style={customStyles}
          className="gizlilik-modal-box"
          ariaHideApp={false}
        >
          <div className="gizlilik-modal-container">
            <h4>Mesafeli Satış Sözleşmesi</h4>
            <div className="gizlilik-container">
              <p>
                <b>MADDE 1: TARAFLAR</b>
              </p>
              <p>
                İşbu sözleşme TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ. internet
                sitesi üzerinden sigorta ürünü satın almak isteyen ALICI ile
                SATICI arasında aşağıdaki hüküm ve koşullar dahilinde Finansal
                Hizmetlere İlişkin Mesafeli Sözleşmeler Yönetmeliği’ne uygun
                olarak akdedilmiştir.
              </p>
              <p>
                <b>MADDE 2: KONU</b>
              </p>
              <p>
                İşbu mesafeli satış sözleşmesinin konusu, sigorta ürünlerine
                ilişkin teklif, poliçe ve elektronik ortamda mesafeli olarak
                satış hizmetiyle ilgili tarafların 6502 sayılı Tüketicinin
                Korunması Hakkında Kanun, Mesafeli Sözleşmeler Yönetmeliği,
                28982 sayılı Sigortacılık Kapsamında Değerlendirilecek
                Faaliyetlere, Tüketici Lehine Yapılan Sigorta Sözleşmeleri İle
                Mesafeli Akdedilen Sigorta Sözleşmelerine İlişkin Yönetmelik
                (beraber “Mevzuat” olarak anılacaktır.) çerçevesinde hak ve
                yükümlülüklerin belirlenmesidir.
              </p>
              <p>
                ALICI, işbu sözleşmeyi okuduğunu ve kabul ettiğini onaylamadan,
                sonraki adımlara geçemeyecek ve poliçe teklifi ve elektronik
                ortamda mesafeli olarak sigorta ürünü satın alım işlemlerini
                gerçekleştiremeyecektir.
              </p>
              <p>
                ALICI, TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ. ’nin isim, unvan,
                açık adres, telefon ve diğer erişim bilgileri, satışa konu
                sigorta ürününün temel nitelikleri, vergiler dâhil olmak üzere
                satış fiyatı, ödeme şekli, teslimat ve iade koşulları ve
                masrafları vs. satışa konu mal ile ilgili tüm ön bilgiler ve
                “cayma” hakkının kullanılması ve bu hakkın nasıl kullanılacağı,
                şikâyet ve itirazlarını iletebilecekleri resmi makamlar vs.
                konusunda açık, anlaşılır ve internet ortamına uygun şekilde
                SATICI tarafından bilgilendirildiğini, bu önbilgileri elektronik
                ortamda teyit ettiğini ve sonrasında sigorta ürünü aldığını işbu
                Sözleşme hükümleri çerçevesinde kabul, beyan ve taahhüt eder.
              </p>
              <p>
                ALICI, SATICI’nın poliçe teklifi ve satışı işlemleri için ve bu
                amaçla sınırlı olmak üzere kişisel verilerini işleme yetkisi
                verdiğini kabul ve beyan etmektedir. Ayrıca SATICI, ALICI’nın
                kişisel verilerini işbu sözleşme amaçları ile sınırlı olmak
                kaydıyla gerektiğinde kamu kurumları ve iş ortakları ile
                paylaşabilecektir.
              </p>
              <p>
                <b>
                  MADDE 3: SATIŞA KONU ÜRÜN/ÜRÜNLER, ÖDEME BİLGİLERİ VE TESLİMAT
                </b>
              </p>
              <p>
                Ürünün özelliklerine (cinsi ve türü) ilişkin bilgiler her
                sigorta ürününün türüne göre değişiklik göstermekte olup, ürün
                seçildiğinde ürünün bedeli kullanıcı ekranında
                görüntülenmektedir. Bu bilgiler ALICI tarafından da
                onaylanmıştır.
              </p>
              <p>
                Ödeme Şekli: Sadece Kredi Kartı ile ödeme kabul edilmektedir.
              </p>
              <p>
                Satın alınan sigorta ürününe dair poliçeyi ALICI pdf formatında
                internet sitesinden indirebilecektir. Ayrıca SATICI tarafından
                ALICI’ya poliçe elektronik posta ile de gönderecektir. ALICI
                tarafından verilen elektronik posta adresinin yanlış olmasından
                veya başkasına ait olmasından dolayı poliçenin ulaşmaması
                durumunda ALICI SATICI’dan herhangi bir talepte bulunamayacağını
                kabul, beyan ve taahhüt eder.
              </p>
              <p>
                <b>MADDE 4: SÖZLEŞME TARİHİ</b>
              </p>
              <p>
                İşbu sözleşme ALICI’nın seçtiği sigorta türüne göre yapması
                gereken ödemeyi yaptığı anda akdedilmiş sayılır.
              </p>
              <p>
                <b>MADDE 5: GENEL HÜKÜMLER</b>
              </p>
              <p>
                ALICI “www.turkhavaksigorta.com” internet sitesinde sözleşme
                konusu ürünün tüm özelliklerine, temel nitelikleri, kapsadığı
                rizikoları ve tüm poliçe koşullarını bildiğini, satış fiyatı ve
                ödeme şekli ile teslimata ilişkin ön bilgileri okuyup bilgi
                sahibi olduğunu ve elektronik ortamda gerekli teyidi verdiğini
                kabul, beyan ve taahhüt eder.
              </p>
              <p>
                ALICI, işbu Sözleşme çerçevesinde verdiği kişisel bilgilerin
                doğru olduğunu beyan ve taahhüt eder. Kişisel bilgilerin
                doğruluğundan ALICI sorumludur. SATICI’nın bu bilgilerin doğru
                olmaması nedeniyle uğrayacağı tüm zararlardan ALICI sorumludur.
              </p>
              <p>
                ALICI, doğruluğu, güncelliği ve verdiği bilgilerin kendisine ait
                olduğu veya üçüncü kişilere ait olmakla birlikte sigorta ürünü
                satın alabilmek veya üçüncü kişileri lehdar olarak göstermek
                üzere gerekli açık rızanın kendisi tarafından alınmış olduğunu,
                bu bilgilerle ilgili olarak bir ihlal ortaya çıkması ve
                SATICI’nın bu ihlal nedeniyle bir zarar görmesi durumunda
                SATICI’nın zararlarını nakden ve defaten tazmin edeceğini kabul,
                beyan ve taahhüt eder.
              </p>
              <p>
                Sözleşmeye konu sigorta ürününe ait korumanın başlamış sayılması
                için işbu mesafeli satış sözleşmesi ile ön bilgilendirme
                formunun elektronik ortamda teyit edilmiş olması ve
                ürün/ürünlerin bedelinin ALICI tarafından kredi kartı ile
                ödenmiş olması şarttır. Herhangi bir nedenle ürün/ürünlerin
                bedeli ödenmez veya banka kayıtlarında iptal edilir ise,
                taraflar arasında bir sigorta sözleşmesinin kurulmamış olacağını
                taraflar kabul, beyan ve taahhüt eder.
              </p>
              <p>
                Sözleşmeye konu ürüne/ürünlere ilişkin ödemenin ALICI tarafından
                kredi kartı ile yapılması durumunda, ALICI ile kredi kartı
                sahibinin ya da ürünün/ürünlerin teslim edileceği kişinin farklı
                olmasından kaynaklanabilecek olan, kredi kartının veya banka
                kartının yetkisiz kişilerce haksız ve hukuka aykırı olarak
                kullanılması da dahil olmak üzere türlü hukuki risk, ALICI’ya
                aittir. ALICI, bahsi geçen durumlarda herhangi bir şekilde
                zarara uğraması halinde avukatlık ücretleri ve dava masrafları
                dahil SATICI’dan hiçbir talepte bulunmayacağını kabul, beyan ve
                taahhüt eder.
              </p>
              <p>
                ALICI, “www.turkhavaksigorta.com” internet sitesi üzerinden
                kredi kartı gerektiren işlemlerde kredi kartı malikinin rızası
                ile işlem yapacağını; aksi halde ödeme iadesi, hizmeti ifa
                etmeme veya benzeri sebeplerden kaynaklanabilecek, avukatlık
                masrafları da dâhil olmak üzere her türlü masraflardan ve
                zarardan münhasıran sorumlu olacağını ve SATICI’yı bunlardan
                kaynaklanabilecek her türlü talepten âri tutacağını kabul, beyan
                ve taahhüt eder.
              </p>
              <p>
                SATICI, sipariş konusu ürün veya hizmetin yerine getirilmesinin
                baştan veya sonradan imkânsızlaşması halinde sözleşme konusu
                yükümlülüklerini yerine getiremezse, bu durumu, öğrenmesi ile
                birlikte yazılı olarak ALICIYA bildireceğini, tahsil edilmiş bir
                bedel olması durumunda hizmetinden yararlanılmamış police süresi
                dikkate alınarak hesaplanan bedeli ALICI’ya iade edeceğini
                kabul, beyan ve taahhüt eder.
              </p>
              <p>
                ALICI, spam, virüs, Truva atı ve bunlarla sınırlı olmaksızın
                benzeri diğer kötü niyetli yazılımlar ile İnternet Sitesi’nin
                çalışmasını durdurucu veya engelleyici iş, işlem veya eylemlerde
                bulunmayacağını veya bu yönde iş, işlem veya eylemlerde bulunan
                üçüncü kişilerle herhangi bir ilişki içerisinde olmayacağını
                kabul, beyan ve taahhüt eder.
              </p>
              <p>
                ALICI işbu sözleşme konusu hizmeti aldığı an itibariyle
                poliçenin klozlarını kontrol etmeyi kabul ve taahhüt eder.
              </p>
              <p>
                ALICI’ya ait kredi kartının alıcının kusurundan kaynaklanmayan
                bir şekilde yetkisiz kişilerce haksız veya hukuka aykırı
                kullanılması nedeniyle banka veya finans kuruluşunun prim
                bedelini SATICI’ya ödememesi halinde sigorta sözleşmesi
                hükümlerini doğurmaya başlamayacak ve kendiliğinden geçersiz
                hale gelecektir. Bu durumda sözleşme konusu ürünün/poliçenin
                ALICI’ya önceden teslim edilmesi durumu oluşursa, ALICI,
                kendisine yapılan bildirim tarihinden başlamak üzere 3 gün
                içerisinde SATICI’ya ilgili ürün/poliçeyi iade edeceğini kabul,
                beyan ve taahhüt eder.
              </p>
              <p>
                <b>MADDE 6: CAYMA HAKKI</b>
              </p>
              <p>
                ALICI, sözleşmenin imzalandığı tarihten itibaren 14 (ondört) gün
                içinde herhangi bir gerekçe göstermeksizin ve cezai şart
                ödemeksizin sözleşmeden cayma hakkına sahiptir. İşbu 14 (ondört)
                günlük süre, tüketicinin sözleşmenin imzalandığı günden itibaren
                başlar. Cayma hakkının kullanılması için bu süre içinde
                SATICI’ya aşağıda detayları belirtilen iletişim bilgileri
                vasıtasıyla ALICI yazılı olarak bildirimde bulunmak zorundadır.
                ALICI bu sure zarfında cayma beyanını belirtilen şekilde
                bildirmediği takdirde cayma hakkını kaybeder.
              </p>
              <p>
                ALICI nın tüketici olmadığı durumlarda bu cayma hakkını kullanma
                hakkı bulunmamaktadır. Tüketici olmayan ALICI ancak TTK md.
                1430(3) ‘e göre cayabilir. Buna göre sigorta ettiren sigorta
                şirketinin sorumluluğu başlamadan önce, kararlaştırılmış primin
                yarısını ödeyerek sözleşmeden cayabilir. Sözleşmeden kısmi cayma
                halinde sigorta ettirenin ödemekle yükümlü olduğu prim, cayılan
                kısma ilişkin primin yarısıdır. Alıcı tüketici sigortalı ise
                Tüketici Mevzuatından kaynaklanan hakkının yanında dilerse bu
                hakkını da kullanması mümkündür.
              </p>
              <p>
                <b>MADDE 7: CAYMA HAKKININ KULLANIMI</b>
              </p>
              <p>
                (1) Cayma hakkının kullanıldığına dair bildirimin cayma hakkı
                süresi içinde, yazılı olarak veya kalıcı veri saklayıcısı ile
                SATICI’ya yöneltilmiş olması yeterlidir.
              </p>
              <p>
                (2) İnternet sitesi üzerinden tüketiciye cayma hakkı sunulması
                durumunda SATICI, ALICI’nın cayma taleplerinin kendisine
                ulaştığına ilişkin teyit bilgisini, ALICI’ya derhal iletmek
                zorundadır.
              </p>
              <p>
                <b>MADDE 8: CAYMA HAKKINDA SATICININ YÜKÜMLÜLÜKLERİ</b>
              </p>
              <p>
                (1) SATICI, cayma hakkının kullanıldığına ilişkin bildirimin
                kendisine ulaştığı tarihten itibaren otuz gün içinde, Finansal
                Hizmetlere İlişkin Mesafeli Sözleşmeler Yönetmeliği’nin 11 inci
                maddesinin birinci fıkrasında belirtilen masraf ve bedeller
                hariç tüm ödemeleri ALICI’ya iade etmekle yükümlüdür. Sigortaya
                ilişkin sözleşmelerde ise iade süresi hakkında diğer mevzuatta
                yer alan ALICI lehine olan hükümler uygulanır.
              </p>
              <p>
                (2) SATICI, birinci fıkrada belirtilen tüm geri ödemeleri,
                ALICI’nın satın alırken kullandığı ödeme aracına uygun bir
                şekilde ve tüketiciye herhangi bir masraf veya yükümlülük
                getirmeden tek seferde yapmak zorundadır.
              </p>
              <p>
                (3) ALICI’nın sözleşmenin kurulmasına dair kabul beyanı
                kullanılan iletişim araçlarına uygun olarak fiziki veya
                elektronik ortamda tespit veya kayıt edilir. SATICI, cayma
                hakkının iletilmesi ile fiziki veya elektronik ortamda yapılacak
                tespit veya kayıtlar için gerekli önlemleri almakla yükümlüdür.
              </p>
              <p>
                <b>MADDE 9: CAYMA HAKKINDA ALICININ YÜKÜMLÜLÜKLERİ</b>
              </p>
              <p>
                (1) ALICI, cayma bildirimini yönelttiği tarihten itibaren en geç
                otuz gün içinde sözleşme ve yan sözleşme uyarınca ifa edilen
                hizmet bedelleri ile varsa bir kamu kurum veya kuruluşuna ya da
                üçüncü kişilere ödenmiş olan masraflar ile mevzuat gereği
                ödenmesi zorunlu olan bedelleri SATICI’ya iade etmekle
                yükümlüdür. Bu süre içinde ALICI’nın gerekli iade ve ödemeleri
                yapmaması halinde sözleşmeden caymadığı kabul edilir. Ödenmesi
                gereken bedel sözleşmede öngörülen hizmetin toplam bedeli ile
                karşılaştırıldığında, hizmetin ifa edilen payına düşen miktarını
                aşamaz ve herhangi bir şekilde cezai şart olarak yorumlanamaz.
                Bu fıkranın uygulanmasında diğer mevzuatta yer alan ALICI lehine
                olan hükümler saklıdır.
              </p>
              <p>
                (2) Cayma hakkının kullanılması nedeniyle turkhavaksigorta
                tarafından düzenlenen kampanya limit tutarının altına düşülmesi
                durumunda, kampanya kapsamında faydalanılan indirim miktarı da
                iptal edilecektir.
              </p>
              <p>
                <b>
                  MADDE 10: CAYMA HAKKININ KULANIMININ YAN SÖZLEŞMELERE ETKİSİ
                </b>
              </p>
              <p>
                (1) ALICI’nın cayma hakkını kullanması halinde, varsa yan
                sözleşme de, herhangi bir tazminat veya cezai şart ödeme
                yükümlülüğü söz konusu olmaksızın sona erer.
              </p>
              <p>
                (2) SATICI, ALICI’nın cayma hakkını kullandığını yan sözleşmenin
                tarafı olan üçüncü kişiye yazılı olarak veya kalıcı veri
                saklayıcısı ile derhal bildirir.
              </p>
              <p>
                Cayma Hakkına Dair Bildirimin yapılacağı İletişim Bilgileri;
              </p>
              <p>TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ.</p>
              <p>MERSİS NO: 0879065403500018</p>
              <p>Adres: Havaalanı Cad. No: 1 Yeşilköy Bakırköy, İstanbul</p>
              <p>Telefon: 444 0 483</p>
              <p>Eposta Adresi: info@turkhavaksigorta.com</p>
              <p>KEP Adresi: turkhavaksigorta@hs03.kep.tr</p>
              <p>
                ALICI, Seyahat Sağlık, DASK ve Trafik sigortası gibi zorunlu
                bazı sigorta ürünlerinde getirilebilecek yasal gereklilikler
                nedeniyle ve hali hazırda www. turkhavaksigorta.com sitesinde de
                yayınlanan Seyahat Sağlık ve Dask Sigortası genel şartlarında
                belirtilen hususlarda ve bu hususların gerçekleşmemesi halinde
                cayma hakkının kullanılamayacağını bildiğini kabul, beyan ve
                taahhüt eder.
              </p>
              <p>
                <b>MADDE 11: MÜCBİR SEBEP</b>
              </p>
              <p>
                SATICI, tarafların iradesi dışında gelişen, önceden
                öngörülemeyen ve tarafların borçlarını yerine getirmesini
                engelleyici ve/veya geciktirici hallerin oluşması gibi mücbir
                sebepler halleri nedeni ile sözleşme konusu ürünü süresi içinde
                teslim edemez ise, durumu ALICI'ya bildireceğini kabul, beyan ve
                taahhüt eder. ALICI da mücbir sebep durumunda ve kendisine
                bildirim gelmesi kapsamında, siparişin iptal edilmesini ve/veya
                teslimat süresinin engelleyici durumun ortadan kalkmasına kadar
                ertelenmesini SATICI’dan talep etme hakkını haizdir. Bu durumda
                police/ürün tutarı, siparişin ALICI tarafından iptal edilmesi
                ile ilgili bankaya iade edilir. ALICI, SATICI tarafından kredi
                kartına iade edilen tutarın banka tarafından ALICI hesabına
                yansıtılmasına ilişkin ortalama sürecin 2 ile 3 haftayı
                bulabileceğini, bu tutarın bankaya iadesinden sonra ALICI’nın
                hesaplarına yansıması halinin tamamen banka işlem süreci ile
                ilgili olduğundan, ALICI, olası gecikmeler için SATICI’yı
                sorumlu tutamayacağını kabul, beyan ve taahhüt eder.
              </p>
              <p>
                <b>MADDE 12: YETKİLİ MAHKEME</b>
              </p>
              <p>
                İşbu Mesafeli Satış Sözleşmesi'nin uygulanmasında, Gümrük ve
                Ticaret Bakanlığınca ilan edilen değere kadar Alıcının Mal veya
                Hizmeti satın aldığı ve ikametgahının bulunduğu yerdeki Tüketici
                Hakem Heyetleri ile Tüketici Mahkemeleri yetkilidir. 6502 Sayılı
                Tüketicinin Korunması Hakkında Kanun'un 68'nci. Maddesinin 1.
                fıkrasında belirtilen alt ve üst limitler doğrultusunda tüketici
                talepleri hakkında ilçe/il tüketici hakem heyetleri yetkilidir.
              </p>
              <p>
                Tüketici sıfatını haiz olmayan ALICI için, taraflar arasında
                işbu Sözleşme’den doğabilecek uyuşmazlıkların çözümünde İstanbul
                Merkez Mahkemeleri ve İcra Müdürlükleri yetkilidir.
              </p>
              <p>
                İşbu mesafeli satış sözleşmesinin elektronik ortamda taraflarca
                okunup, kabul edilip, teyit edilmiştir.
              </p>
            </div>
            <Button onClick={() => this.closeMesafeliModal()}>
              Okudum ve Onaylıyorum.
            </Button>
          </div>
        </Modal>

        {/* <Modal
          isOpen={this.state.turkhavakModalIsOpen}
          style={customStyles}
          className="gizlilik-modal-box"
          ariaHideApp={false}
        >
          <div className="gizlilik-modal-container">
            <h4>TürkHavak Üyelerine Özel</h4>
            <div className="gizlilik-container">
              <p>
                TürkHavak üyelerimize kaskoda %30 indirim(Groupama Vakıf Kasko)
                vereceğiz.
              </p>
              <p>
                Poliçe satın alım yapan TürkHavak üyelerimize ise{" "}
                <Link to="https://www.havakshop.com/">www.havakshop.com</Link>{" "}
                üzerinden %20 indirim kodu vereceğiz.
              </p>
            </div>
            <Button onClick={() => this.closeMesafeliModal()}>
              Okudum ve Onaylıyorum.
            </Button>
          </div>
        </Modal> */}

        <Modal
          isOpen={this.state.bilgilendirmeModalIsOpen}
          style={customStyles}
          className="gizlilik-modal-box"
          ariaHideApp={false}
        >
          <div className="gizlilik-modal-container">
            <h4>Bilgilendirme Formu</h4>
            <div className="gizlilik-container">

            </div>
            <Button onClick={() => this.closeMesafeliModal()}>
              Okudum ve Onaylıyorum.
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.successModalIsOpen}
          style={customStyles}
          className="success-modal-box"
          ariaHideApp={false}
        >
          <div className="success-modal-container">
            {this.state.isSuccess === true ? (
              <div className="succes-modal-container">
                <img src={correct} alt="" style={{ width: 64 }} />
                <h5
                  style={{
                    color: "#167f4f",
                    fontSize: 30,
                    fontWeight: "600",
                    letterSpacing: 2,
                  }}
                >
                  Başarılı!
                </h5>
                <p style={{ marginBottom: 30, marginTop: 30 }}>
                  {this.props.policy.policy.policeNo}
                </p>
                <Button onClick={() => this.closeSuccessModal()}>Tamam</Button>
              </div>
            ) : (
              <div className="succes-modal-container">
                <img src={err} alt="" style={{ width: 64 }} />
                <h5
                  style={{
                    color: "#d12222",
                    fontSize: 30,
                    fontWeight: "600",
                    letterSpacing: 2,
                  }}
                >
                  HATA!
                </h5>
                <p style={{ marginBottom: 30, marginTop: 30 }}>
                  {this.props.policy.policy.hataLog}
                </p>
                <Button onClick={() => this.closeSuccessModal()}>Tamam</Button>
              </div>
            )}
          </div>
        </Modal>

        <div className="teklif-content container-fluid">
          <div className="teklif-image-content" id="bgImg">
            <img
              src={bgChange[0]}
              alt=""
              className="teklif-image"
              id="bgImgBox"
            />

          </div>
          <div className="teklif-box-content" id="tBoxContent">
            <div className="kasko-adim-container container-fluid">
              <div className="isActive" id="btn1">
                <div>
                  <label className="kasko-adim-text">Bilgileri Gir {">"}</label>
                </div>
              </div>
              <div className="inActive" id="btn2">
                <div>
                  <label className="kasko-adim-text">Teklifleri Al {">"}</label>
                </div>
              </div>
              <div className="inActive" id="btn3">
                <div>
                  <label className="kasko-adim-text">Sigortalan {">"}</label>
                </div>
              </div>
            </div>
            <section className="adim1 active" id="adim1">
              <div>
                <div className="seyahat-container">
                  <div className="wrapper">
                    <input
                      type="text"
                      className="text-box"
                      required
                      maxLength="11"
                      onChange={(event) =>
                        this.setState({ idendityNumber: event.target.value.replace(/\D/g, '') })
                      }
                      value={this.state.idendityNumber}
                    />
                    <label>TC Kimlik Numarası / Vergi Numarası</label>
                  </div>
                  <div className="wrapper" hidden={this.state.idendityNumber.length<11}>
                    <input
                      type="date"
                      className="text-box"
                      required
                      placeholder="GG / AA / YYYY"
                      value={this.state.birthDate}
                      onChange={(event) =>
                        this.setState({ birthDate: event.target.value })
                      }
                    />
                    <label>Doğum Tarihi</label>
                  </div>
                  <div className="wrapper">
                    <input
                      type="text"
                      className="text-box"
                      required
                      onChange={(event) =>
                        this.setState({ email: event.target.value })
                      }
                      value={this.state.email}
                    />
                    <label>E-posta</label>
                  </div>
                  <div className="wrapper">
                    <input
                      type="phone"
                      className="text-box"
                      required
                      placeholder="(5__) ___ ____"
                      onKeyDown={(event) => this.enforceFormat(event)}
                      onKeyUp={(event) => this.formatToPhone(event)}
                      id="_input"
                      onChange={(event) =>
                        this.setState({ mobileNumber: event.target.value })
                      }
                      value={this.state.mobileNumber}
                    />
                    <label>Cep Telefonu Numarası</label>
                  </div>
                  <div className="wrapper" hidden={this.state.idendityNumber.length<11}>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={this.state.job}
                      onChange={(event) =>
                        this.setState({ job: event.target.value })
                      }
                    >
                      <option>Meslek Seçiniz..</option>
                      {this.props.common.jobs.map((job) => (
                        <option value={job.value}>{job.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="check-box">
                    <div
                      className="form-check"
                      onClick={this.openGizlilikModal}
                    >
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="gizlilik"
                        />
                        Gizlilik Politikası ve Kullanıcı Sözleşmesini okudum ve
                        onaylıyorum.
                      </label>
                    </div>
                    <div className="form-check" onClick={this.openKvkkModal}>
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="kvkk"
                        />
                        Kişisel Verilerin Korunması Kanunu' nu okudum ve
                        onaylıyorum.
                      </label>
                    </div>
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckChecked"
                        />
                        Elektronik İleti onayını okudum, yenilik ve kampanyalar
                        hakkında E-Bülten ve SMS almak istiyorum.
                      </label>
                    </div>
                  </div>
                  {this.state.wait == false ? (
                    <div className="wrapper">
                      <button onClick={() => this.createCustomer("adim2")}>
                        Devam
                      </button>
                    </div>
                  ) : (
                    <div className="wrapper-loading">
                      <img src={wait} alt="" style={{height: 25}} />
                    </div>
                  )}
                  
                </div>
              </div>
            </section>
            <section className="adim2 inactive" id="adim2">
              <div className="container seyahat-container">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: 30,
                  }}
                >
                  <h4 style={{ marginBottom: 13, fontSize: 18 }}>Sms Kodu</h4>
                  <div className="wrapper">
                    <input
                      type="text"
                      className="text-box"
                      required
                      placeholder="******"
                      maxLength="6"
                      onChange={(event) =>
                        this.setState({ dogrulamaKodu: event.target.value })
                      }
                      value={this.state.dogrulamaKodu}
                    />
                    <label>Sms Kodunu Giriniz</label>
                    <Link
                      to=""
                      style={{
                        marginTop: 5,
                        color: "#000",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pointerEvents: "auto",
                      }}
                      onClick={() => this.dogrulamaTekrar()}
                      id="smsTekrar"
                    >
                      Tekrar Gönder
                    </Link>
                  </div>
                </div>
                <h6>0-6 yaş çocuğunuz var mı?</h6>
                <div className="radio-box-adim7">
                  <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="cocuk"
                      id="evet"
                      value="evet"
                    />
                    Evet</label>
                  </div>
                  <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="cocuk"
                      id="hayır"
                      value="hayır"
                    />
                    Hayır</label>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center mb-3 mt-3">
                  <img
                    src={star}
                    alt=""
                    style={{ width: 30, marginRight: 10 }}
                  />
                  <h5 style={{ fontSize: 18 }}>
                    Türk Havak üyeliğiniz var mı?
                  </h5>
                </div>
                <p className="text-p">
                  3 iş günü içerisinde Türk Havak Üyeliğini başlatma taahhütü
                  verirseniz şu an üyelere özel Poliçeleri satın alabilirsiniz.
                </p>
                <div className="wrapper">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={this.state.kampanyaID}
                    onChange={(event) =>
                      this.setState({ kampanyaID: event.target.value })
                    }
                  >
                    <option>Türk Havak Üyesi Misin?</option>
                    <option value="01">Evet Üyeyim</option>
                    <option value="02">Hayır Değilim</option>
                    <option value="03">Üye Eşiyim</option>
                    <option value="04">
                      Üyenin 25 yaşını aşmamış çocuğuyum
                    </option>
                    <option value="05">3 gün içinde üye olacağım</option>
                  </select>
                </div>
                <div className="wrapper">
                  <button
                    className="g-button"
                    onClick={() => this.stepper("adim1")}
                  >
                    Geri
                  </button>
                  {this.state.wait == false ? (
                    <button
                      className="o-button"
                      onClick={() => this.dogrulamaKodu("adim3")}
                    >
                      Onayla
                    </button>
                  ) : (
                    <div className="wrapper-loading">
                      <img src={wait} alt="" style={{height: 25}} />
                    </div>
                  )}
                  
                </div>
              </div>
            </section>
            <section className="adim3 inactive" id="adim3">
              <div className="container kasko-bilgi-container">
                <h6>Aynı zamanda Tamamlayıcı Sağlık Sigortası</h6>
                <h6>ürünümüz içinde teklif almak ister misiniz?</h6>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                    width: 200,
                  }}
                >
                  <div
                    className="form-check"
                    style={{
                      width: "100%",
                      backgroundColor: "#CDDAEE",
                      padding: "10px 40px",
                      borderRadius: 10,
                    }}
                  >
                    <label
                      className="form-check-label"
                      style={{ fontSize: 16 }}
                    >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="rd-tss"
                      id="rd-tssEvet"
                      value={this.state.isTss}
                      onChange={() => this.tssCheckBoxChecked()}
                    />
                    
                      Evet
                    </label>
             
                  </div>
                </div>
                <div id="dv-tss" style={{ display: "none" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 20,
                      width: 350,
                    }}
                  >
                    <div
                      className="wrapper"
                      style={{ width: 170, marginBottom: 0 }}
                    >
                      <input
                        type="text"
                        className="text-box"
                        required
                        placeholder="kg"
                        value={this.state.weight}
                        onChange={(event) =>
                          this.setState({ weight: event.target.value })
                        }
                      />
                      <label>Kilo (kg)</label>
                    </div>
                    <div
                      className="wrapper"
                      style={{ width: 170, marginBottom: 0 }}
                    >
                      <input
                        type="text"
                        className="text-box"
                        required
                        placeholder="cm"
                        value={this.state.height}
                        onChange={(event) =>
                          this.setState({ height: event.target.value })
                        }
                      />
                      <label>Boy (cm)</label>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 20,
                      width: 350,
                    }}
                  >
                    <div
                      className="wrapper"
                      style={{ width: 170, marginBottom: 0 }}
                    >
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={this.state.plan}
                        onChange={(event) =>
                          this.setState({ plan: event.target.value })
                        }
                      >
                        <option>Plan Seçiniz..</option>
                        {this.props.common.healthPlans.map((plan) => (
                          <option value={plan.value}>{plan.name}</option>
                        ))}
                      </select>
                    </div>
                    <div
                      className="wrapper"
                      style={{ width: 170, marginBottom: 0 }}
                    >
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={this.state.network}
                        onChange={(event) =>
                          this.setState({ network: event.target.value })
                        }
                      >
                        <option>Network Seçiniz..</option>
                        {this.props.common.healthPlanTypes.map((network) => (
                          <option value={network.value}>{network.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <br></br>
                <div className="wrapper">
                  <button
                    className="g-button"
                    onClick={() => this.stepper("adim2")}
                  >
                    Geri
                  </button>
                    <button
                      className="o-button"
                      onClick={() => this.stepper("adim4")}
                    >
                      Devam
                    </button>
                </div>
              </div>
            </section>
            <section id="adim4" className="adim4 inactive">
              <div className="container kasko-bilgi-container">
                <h6>Aracınız engelli aracı mı?</h6>
                <div className="radio-box-adim7" style={{ width: 180 }}>
                  <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="engelliRadio"
                      id="rd-evet"
                      value="evet"
                      onChange={() => this.engelliRadioChange()}
                    />
                    Evet</label>
                  </div>
                  <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="engelliRadio"
                      id="rd-hayır"
                      value="hayır"
                      onChange={() => this.engelliRadioChange()}
                    />
                    Hayır</label>
                  </div>
                </div>
                <div className="radio-line"></div>
                <h6>Aracınızda ilave donanım/aksesuar var mı?</h6>
                <div
                  className="radio-box-adim7"
                  style={{
                    width: 250,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="donanimRadio"
                      id="rd-donanimEvet"
                      value="evet"
                      onChange={() => this.donanimChange()}
                    />
                    Evet</label>
                  </div>
                  <div
                    className="wrapper "
                    style={{ width: 150, marginBottom: 0 }}
                  >
                    <input
                      disabled={true}
                      type="text"
                      id="inp-donanim"
                      className="text-box"
                      required
                      placeholder="*****"
                      value={this.state.aksesuar}
                      onChange={(event) =>
                        this.setState({ aksesuar: event.target.value })
                      }
                    />
                    <label>Fiyat Giriniz</label>
                  </div>
                </div>
                <div className="radio-line"></div>
                <h6>Daini Mürtehin var mı?</h6>
                <div
                  className="radio-box-adim7"
                  style={{
                    width: 250,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="dainiRadio"
                      id="chb-dainiEvet"
                      value="evet"
                      onChange={() => this.dainiMurteinChange()}
                    />
                    Evet</label>
                  </div>
                  <div
                    className="wrapper"
                    style={{ width: 150, marginBottom: 0 }}
                  >
                    <input
                      type="text"
                      disabled={true}
                      className="text-box"
                      required
                      placeholder="*****"
                      id="inp-dainiMurtein"
                      value={this.state.finansKurumKodu}
                      onChange={(event) =>
                        this.setState({ finansKurumKodu: event.target.value })
                      }
                    />
                    <label>Finans Kodu</label>
                  </div>
                  <div
                    className="wrapper inactive"
                    style={{ width: 150, marginBottom: 0 }}
                  >
                    <input
                      type="text"
                      className="text-box"
                      required
                      placeholder="*****"
                      id="inp-dainiMurtein"
                      value={this.state.bankaSubeKodu}
                      onChange={(event) =>
                        this.setState({ bankaSubeKodu: event.target.value })
                      }
                    />
                    <label>Banka Şube Kodu</label>
                  </div>
                </div>

                <div className="wrapper mt-3">
                  <button
                    className="g-button"
                    onClick={() => this.stepper("adim3")}
                  >
                    Geri
                  </button>
                  <button
                    className="o-button"
                    onClick={() => this.stepper("adim5")}
                  >
                    Devam
                  </button>
                </div>
              </div>
            </section>
            <section id="adim5" className="adim5 inactive">
              <div className="container seyahat-container">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: 250,
                  }}
                >
                  <div className="form-check">
                    <label
                      className="form-check-label"
                      style={{ fontSize: 16 }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="plaka"
                        id="rd-var"
                       // checked="{true}"
                        onChange={() => this.plakaRadioChange()}
                      />
                      Plakam var
                    </label>
                  </div>
                  <div className="form-check">
                    <label
                      className="form-check-label"
                      style={{ fontSize: 16 }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="plaka"
                        id="rd-yok"
                        onChange={() => this.plakaRadioChange()}
                      />
                      Plakam yok
                    </label>
                  </div>
                </div>
                <div id="dvPlakaVar" className="kasko-bilgi-container active">
                  <h4 style={{ textAlign: "center", fontSize: 18 }}>
                    Araç Plaka No
                  </h4>
                  <div className="input-group mb-4 wrapper">
                    <span className="input-group-text">TR</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="34 ABC 1234"
                      aria-label=" 34 ABC 1234"
                      style={{ textAlign: "center", paddingRight:"30px" }}
                      value={this.state.plaka}
                      onChange={(event) =>
                        this.setState({ plaka: event.target.value })
                      }
                    />
                  </div>
                  {/* <div className="wrapper">
                    <input type="text" className="form-select" id="dataMarka" onKeyUp={(event) => this.basildiMarka(event.target.value)} onFocus={() => this.aktifMarka()}     placeholder="Aracın Markasını Seçiniz.." onBlur={()=>this.pasif2Marka()}/>
                      <br></br>
                      <select id="listeMarka"  multiple 
                        style={{ display: "none", borderRadius: 10, position: 'absolute', height: 200, zIndex: 6, marginTop: '-25' }}
                        onBlur={()=>this.pasif2Marka()}
                        onChange={()=>this.secildiMarka()}
                      >
                      </select>
                  </div>
                  <div className="wrapper">
                    <input type="text" className="form-select" id="data" onKeyUp={(event) => this.basildi(event.target.value)} onFocus={() => this.aktif()}     placeholder="Aracın Tipi Seçiniz.." onBlur={()=>this.pasif2()}/>
                    <br></br>
                      <select id="liste"  multiple 
                        style={{ display: "none", borderRadius: 10, position: 'absolute', height: 200, zIndex: 6, marginTop: '-25' }}
                        onBlur={()=>this.pasif2()}
                        onChange={()=>this.secildi()}
                      >
                      </select>
                  </div> */}
                  <h4
                    style={{
                      textAlign: "center",
                      fontSize: 18,
                      marginBottom: 15,
                    }}
                  >
                    Ruhsat Tescil Bilgisi
                  </h4>
                  <div className="ruhsat-box">
                    <div className="wrapper">
                      <input
                        type="text"
                        className="text-box"
                        required
                        placeholder="AA"
                        maxLength="2"
                        value={this.state.tescilKod}
                        onChange={(event) =>
                          this.setState({ tescilKod: event.target.value })
                        }
                      />
                      <label>Belge Seri</label>
                    </div>
                    <div className="wrapper">
                      <input
                        type="text"
                        className="text-box"
                        required
                        placeholder="123456"
                        maxLength="6"
                        value={this.state.tescilNo}
                        onChange={(event) =>
                          this.setState({ tescilNo: event.target.value })
                        }
                      />
                      <label>Belge Seri No</label>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 15,
                    }}
                  >
                    <img src={ruhsat} alt="" className="RuhsatImage" />
                    <div style={{ width: 175, fontSize: 10, marginLeft: 15 }}>
                      <p>Ruhsatınız sağ alt köşesinde yer almaktadır. </p>
                      <p>Ya da</p>
                      <p>
                        “Araçlarım” kısmından Tescil Belgesi seri numarasına
                        ulaşabilirsiniz.
                      </p>
                    </div>
                  </div>
                  <br></br>
                  <div className="wrapper">
                    <button
                      className="g-button"
                      onClick={() => this.stepper("adim4")}
                    >
                      Geri
                    </button>
                    {this.state.wait == false ? (
                      <button
                        className="o-button"
                        onClick={() => this.createProposalKasko("adim6")}
                      >
                        Teklif Al
                      </button>
                    ) : (
                      <div className="wrapper-loading">
                        <img src={wait} alt="" style={{height: 25}} />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  id="dvPlakaYok"
                  className="kasko-bilgi-container inactive text-ceter"
                >
                  <div
                    className="ruhsat-box"
                    style={{ marginBottom: 20, marginTop: 20 }}
                  >
                    <div className="wrapper">
                      <input
                        type="text"
                        className="text-box"
                        required
                        placeholder="*****"
                        value={this.state.motorNo}
                        onChange={(event) =>
                          this.setState({ motorNo: event.target.value })
                        }
                      />
                      <label>Motor No</label>
                    </div>
                    <div className="wrapper">
                      <input
                        type="text"
                        className="text-box"
                        required
                        placeholder="*****"
                        maxLength="17"
                        value={this.state.sasiNo}
                        onChange={(event) =>
                          this.setState({ sasiNo: event.target.value })
                        }
                      />
                      <label>Şasi No</label>
                    </div>
                  </div>
                  <div className="wrapper">
                  <select
                      className="form-select"
                      aria-label="Default select example"
                      value={this.state.ilKodu}
                      onChange={(event) =>
                        this.ilAtama(event.target.value)
                      }
                      >
                      <option>İl Seçiniz..</option>
                      {this.props.bilgiBankasi.iller.map((el) => (
                        <option value={el.ilKodu}>{el.ilAdi}</option>
                      ))}
                    </select>
                  </div>

                  <div className="wrapper">
                      <select
                      className="form-select"
                      aria-label="Default select example"
                      value={this.state.markaKodu}
                      onChange={(event) =>
                        this.markaAtama(event.target.value)
                      }
                      >
                      <option>Aracın Markasını Seçiniz..</option>
                      {this.props.bilgiBankasi.markalar.map((marka) => (
                        <option value={marka.markaKodu}>{marka.markaAdi}</option>
                      ))}
                    </select>

                  </div>

                  <div className="wrapper">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={this.state.modelYili}
                      onChange={(event) =>
                        this.modelYiliAtama(event.target.value)
                      }
                    >
                      <option>Model Yılı Seçiniz..</option>
                      {dataModel.map((model) => (
                        <option value={model.value}>{model.label}</option>
                      ))}
                    </select>
                  </div>

                  <div className="wrapper">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={this.state.tipKodu}
                      onChange={(event) =>
                        this.setState({ tipKodu: event.target.value })
                      }
                      >
                      <option>Aracın Tipi Seçiniz..</option>
                      {this.props.bilgiBankasi.tipler.map((marka) => (
                        <option value={marka.aracKodu}>{marka.tipAdi}</option>
                      ))}
                    </select>
                  </div>

                  <div className="wrapper">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={this.state.kullanimTarziKodu}
                      onChange={(event) =>
                        this.kullanimTarziAtama(event.target.value)
                      }
                    >
                      <option>Kullanım Tarzı Seçiniz..</option>
                      {kullanimTarziAl.map((kullanimTarzi) => (
                        <option value={kullanimTarzi.TramerKullanimKodu}>
                          {kullanimTarzi.KullanimTarziAdi}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="wrapper">
                    <button
                      className="g-button"
                      onClick={() => this.stepper("adim4")}
                    >
                      Geri
                    </button>
                    {this.state.wait == false ? (
                      <button
                        className="o-button"
                        onClick={() => this.createProposalKasko("adim6")}
                      >
                        Teklif Al
                      </button>
                    ) : (
                      <div className="wrapper-loading">
                        <img src={wait} alt="" style={{height: 25}} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>

            <section id="adim6" className="adim6 inactive">
              <div className="container seyahat-container">
                <h2>Kasko Sigorta Teklifleri</h2>
                {this.props.kasko ? (
                  <div className="EnUygun">
                    <div
                      style={{
                        width: 180,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <div id="spinner">
                        <img src={wait} alt="" />
                      </div>
                      <label id="spinnerLabel">{this.state.lengthCount}</label>
                    </div>
                    <div className="en-uygun-line mobile-line"></div>
                    <div className="teklif-adet">
                      <label>Teklif Sayısı</label>
                      <label>{this.props.kasko.length}</label>
                    </div>
                    <div className="en-uygun-line"></div>
                    <div className="teklif-fiyat">
                      <label>En Düşük Fiyat</label>
                      {this.props.kasko[0] ? (
                        <img
                          src={
                            sirketImgURL +
                            this.props.kasko[0].sirketKodu +
                            ".png"
                          }
                          alt=""
                          style={{ height: 40 }}
                        />
                      ) : null}
                      {this.props.kasko[0] ? (
                        <label>
                          {new Intl.NumberFormat("tr-TR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(this.props.kasko[0].prim)}{" "}
                          TL
                        </label>
                      ) : (
                        <label>0</label>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="EnUygun">
                    <div
                      style={{
                        width: 180,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <div id="spinner">
                        <img src={wait} alt="" />
                      </div>
                      <label id="spinnerLabel">{this.state.lengthCount}</label>
                    </div>
                    <div className="en-uygun-line mobile-line"></div>
                    <div className="teklif-adet">
                      <label>Teklif Sayısı</label>
                      <label>{this.props.kasko.length}</label>
                    </div>
                    <div className="en-uygun-line"></div>
                    <div className="teklif-fiyat">
                      <label>En Düşük Fiyat</label>
                      {this.props.kasko[0] ? (
                        <img
                          src={
                            sirketImgURL +
                            this.props.kasko[0].sirketKodu +
                            ".png"
                          }
                          alt=""
                          style={{ height: 40 }}
                        />
                      ) : null}
                      {this.props.kasko[0] ? (
                        <label>
                          {new Intl.NumberFormat("tr-TR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(this.props.kasko[0].prim)}{" "}
                          TL
                        </label>
                      ) : (
                        <label>0</label>
                      )}
                    </div>
                  </div>
                )}
                <div className="TeklifContainer container">
                  <div className="scroll">
                    {this.props.kasko ? (
                      <div className="TeklifListesiContainer">
                        {this.props.kasko.map((e, index) => (
                          <div>
                            {index === 0 ? (
                              <div className="en-uygun-teklif-box box">
                                <div className="yildizli">
                                  <p>En</p>
                                  <p>Uygun</p>
                                </div>
                                {e.sirketKodu === "011" ? (
                                  <div className="d-flex flex-row justify-content-center align-items-center">
                                    <img
                                      src={sirketImgURL + e.sirketKodu + ".png"}
                                      alt=""
                                      className="teklif-box-image"
                                    />
                                    <OverlayTrigger
                                      placement="right"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={this.renderTooltip}
                                    >
                                      <div className="grupamaOzel">
                                        <img
                                          src={discount}
                                          alt=""
                                          className="grupamaOzelImg"
                                        />
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                ) : (
                                  <img
                                    src={sirketImgURL + e.sirketKodu + ".png"}
                                    alt=""
                                    className="teklif-box-image"
                                  />
                                )}

                                <div className="teklif-box-line"></div>
                                <label>Kasko Genişletilmiş Paket</label>
                                <div className="teklif-box-line"></div>
                                <label>
                                  {new Intl.NumberFormat("tr-TR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(e.prim)}{" "}
                                  TL x {e.taksit} Taksit
                                </label>
                                <label>
                                  <b>
                                    {new Intl.NumberFormat("tr-TR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(e.prim)}{" "}
                                    TL
                                  </b>
                                </label>
                                <div className="teklif-box-line"></div>
                                <div className="d-flex flex-row justify-content-around align-items-center mt-1 mb-3 w-100">
                                <Link to="#" 
                                  className="teklif-listesi-a"                             
                                  onClick={()=> this.openTeklifModal(e)}>
                                    Teklif Detayı
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="8"
                                      viewBox="0 0 22 11"
                                    >
                                      <path
                                        id="Polygon_30"
                                        data-name="Polygon 30"
                                        d="M11,0,22,11H0Z"
                                        transform="translate(22 11) rotate(180)"
                                        fill="#0a4cac"
                                      />
                                    </svg>
                                  </Link>
                                  {/* <Link
                                    to="#"
                                    className="teklif-listesi-a odeme-plani"
                                  >
                                    Ödeme Planı
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="8"
                                      viewBox="0 0 22 11"
                                    >
                                      <path
                                        id="Polygon_30"
                                        data-name="Polygon 30"
                                        d="M11,0,22,11H0Z"
                                        transform="translate(22 11) rotate(180)"
                                        fill="#0a4cac"
                                      />
                                    </svg>
                                  </Link> */}
                                </div>
                                <div className="d-flex flex-row justify-content-around align-items-center w-100">
                                  <Link to="#" hidden={e.sirketKodu == "040" || e.sirketKodu == "110"} className="teklif-ekle-button"  onClick={() => this.addToCart(e)}>
                                  Sepete Ekle
                              </Link>
                              <Link to="#" hidden={e.sirketKodu != "040" && e.sirketKodu != "110"} className="teklif-ekle-button"  onClick={()=> this.callMe2(e)}>
                                Sizi Arayalım
                              </Link>

                                </div>
                                <div
                                  className="form-check form-switch karsilastirma"
                                  onClick={() => this.addToBox(e)}
                                >
                                  <label className="form-check-label">
                                    Karşılaştırma Listesine Ekle
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckDefault"
                                    />
                                  </label>
                                </div>
                              </div>
                            ) : (
                              <div className="teklif-box box">
                                {e.sirketKodu === "011" ? (
                                  <div className="d-flex flex-row justify-content-center align-items-center">
                                    <img
                                      src={sirketImgURL + e.sirketKodu + ".png"}
                                      alt=""
                                      className="teklif-box-image"
                                    />
                                    <OverlayTrigger
                                      placement="right"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={this.renderTooltip}
                                    >
                                      <div className="grupamaOzel">
                                        <img
                                          src={discount}
                                          alt=""
                                          className="grupamaOzelImg"
                                        />
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                ) : (
                                  <img
                                    src={sirketImgURL + e.sirketKodu + ".png"}
                                    alt=""
                                    className="teklif-box-image"
                                  />
                                )}
                                <div className="teklif-box-line"></div>
                                <label>Kasko Genişletilmiş Paket</label>
                                <div className="teklif-box-line"></div>
                                <label>
                                  {new Intl.NumberFormat("tr-TR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(e.prim)}{" "}
                                  TL x {e.taksit} Taksit
                                </label>
                                <label>
                                  <b>
                                    {new Intl.NumberFormat("tr-TR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(e.prim)}{" "}
                                    TL
                                  </b>
                                </label>
                                <div className="teklif-box-line"></div>
                                <div className="d-flex flex-row justify-content-around align-items-center mt-1 mb-3 w-100">
                                <Link to="#" 
                                  className="teklif-listesi-a"                             
                                  onClick={()=> this.openTeklifModal(e)}>
                                    Teklif Detayı
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="8"
                                      viewBox="0 0 22 11"
                                    >
                                      <path
                                        id="Polygon_30"
                                        data-name="Polygon 30"
                                        d="M11,0,22,11H0Z"
                                        transform="translate(22 11) rotate(180)"
                                        fill="#0a4cac"
                                      />
                                    </svg>
                                  </Link>
                                  {/* <Link
                                    to="#"
                                    className="teklif-listesi-a odeme-plani"
                                  >
                                    Ödeme Planı
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="8"
                                      viewBox="0 0 22 11"
                                    >
                                      <path
                                        id="Polygon_30"
                                        data-name="Polygon 30"
                                        d="M11,0,22,11H0Z"
                                        transform="translate(22 11) rotate(180)"
                                        fill="#0a4cac"
                                      />
                                    </svg>
                                  </Link> */}
                                </div>
                                <div className="d-flex flex-row justify-content-around align-items-center w-100">
                                <Link to="#" hidden={e.sirketKodu == "040" || e.sirketKodu == "110"} className="teklif-ekle-button"  onClick={() => this.addToCart(e)}>
                                  Sepete Ekle
                              </Link>
                              <Link to="#" hidden={e.sirketKodu != "040" && e.sirketKodu != "110"} className="teklif-ekle-button"  onClick={()=> this.callMe2(e)}>
                                Sizi Arayalım
                              </Link>
                                </div>
                                <div
                                  className="form-check form-switch karsilastirma"
                                  onClick={() => this.addToBox(e)}
                                >
                                  <label className="form-check-label">
                                    Karşılaştırma Listesine Ekle
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckDefault"
                                    />
                                  </label>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <label>Teklif Bulunamadı.</label>
                    )}

                    {/* <div className="container d-flex justify-content-center align-items-center mt-3 mb-5 daha-cok">
                      <Link to="#" style={{ color: "#000" }}>
                        Daha çok teklif görüntüle
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="11"
                          viewBox="0 0 22 11"
                        >
                          <path
                            id="Polygon_47"
                            data-name="Polygon 47"
                            d="M11,0,22,11H0Z"
                            transform="translate(22 11) rotate(180)"
                            fill="#0a4cac"
                          />
                        </svg>
                      </Link>
                    </div> */}
                  </div>

                  <div className="fixed-box">
                    <div className="geri-button mb-3">
                      <Link
                        to="#"
                        style={{ color: "#fff" }}
                        onClick={() => this.stepper("adim5")}
                      >
                        Geri
                      </Link>
                    </div>
                    <div className="onay-button mb-3 teklif-karsilastirma-container">
                      <Link
                        to="#"
                        style={{ color: "#000" }}
                        onClick={() => this.stepper("adim7")}
                      >
                        Tamamlayıcı Sağlık Teklifi
                      </Link>
                    </div>
                    {/* <div className="onay-button mb-3 teklif-karsilastirma-container">
                      <Link
                        to="#"
                        style={{ color: "#000" }}
                        onClick={() => this.createProposalTrafik("adim9")}
                      >
                        Trafik Teklifi Çalış
                      </Link>
                    </div> */}
                    <div className="sepet-fixed-button mb-3">
                      <Link
                        to="#"
                        style={{ color: "#fff" }}
                        onClick={() => this.openSepetModal()}
                      >
                        <span style={{ marginRight: 10 }}>
                          {this.props.proposal.cart.length}
                        </span>
                        Sepet
                      </Link>
                    </div>
                  </div>

                  <div className="sepet">
                    <div className="d-flex flex-row justify-content-between">
                      <div className="geri-button mb-3">
                        <Link
                          to="#"
                          style={{ color: "#fff" }}
                          onClick={() => this.stepper("adim5")}
                        >
                          Geri
                        </Link>
                      </div>
                      <div className="teklif-karsilastirma-container mb-3 w-75">
                        <Link
                          to="#"
                          style={{ color: "#000" }}
                          onClick={() => this.stepper("adim7")}
                        >
                          Tamamlayıcı Sağlık Teklifi
                        </Link>
                      </div>
                      {/* <div className="teklif-karsilastirma-container mb-3 w-25">
                        <Link
                          to="#"
                          style={{ color: "#000" }}
                          onClick={() => this.createProposalTrafik("adim9")}
                        >
                          Trafik Sigortası Teklifi
                        </Link>
                      </div> */}
                    </div>
                    <div>
                      <div className="teklif-karsilastirma-container">
                        <h4>
                          <b>Karşılaştırma Listesi</b>
                        </h4>
                        {this.props.proposal.box.length != 0 ? (
                          <div>
                            {this.props.proposal.box.map((box) => (
                              <div className="teklif-karsilastirma-box">
                                <div style={{ width: 80 }}>
                                  <img
                                    src={sirketImgURL + box.sirketKodu + ".png"}
                                    alt=""
                                    style={{ height: 25 }}
                                  />
                                </div>
                                <div className="teklif-karsilastirma-line"></div>
                                <div style={{ width: 50, textAlign: "center" }}>
                                  <p>{box.bransName}</p>
                                </div>
                                <div className="teklif-karsilastirma-line"></div>
                                <div>
                                  <p>
                                    {new Intl.NumberFormat("tr-TR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(box.prim)}{" "}
                                    TL
                                  </p>
                                </div>
                                <Link
                                  to=""
                                  onClick={() => this.props.removeToBox(box)}
                                >
                                  <img
                                    src={close1}
                                    alt=""
                                    style={{ width: 14, height: 14 }}
                                  />
                                </Link>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <label>Sepetiniz Boş</label>
                        )}

                        {this.props.proposal.box.length == 0 ? (
                          <Link
                            to="#"
                            style={{ pointerEvents: "none" }}
                            className="teklif-karsilastir-button"
                            onClick={() => this.openKarsilastirmaModal()}
                          >
                            Detaylı Karşılaştır
                          </Link>
                        ) : (
                          <Link
                            to="#"
                            className="teklif-karsilastir-button"
                            onClick={() =>
                              this.openKarsilastirmaModal(
                                this.props.proposal.box
                              )
                            }
                          >
                            Detaylı Karşılaştır
                          </Link>
                        )}
                      </div>
                      <div className="sepet-container">
                        <h4>
                          <b>Sigorta Sepeti</b>
                        </h4>

                        {this.props.proposal.cart.length != 0 ? (
                          <div>
                            {this.props.proposal.cart.map((cart) => (
                              <div className="teklif-karsilastirma-box">
                                <div style={{ width: 80 }}>
                                  <img
                                    src={
                                      sirketImgURL + cart.sirketKodu + ".png"
                                    }
                                    alt=""
                                    style={{ height: 25 }}
                                  />
                                </div>
                                <div className="teklif-karsilastirma-line"></div>
                                <div style={{ width: 50, textAlign: "center" }}>
                                  <p>{cart.bransName}</p>
                                </div>
                                <div className="teklif-karsilastirma-line"></div>
                                <div>
                                  <p>
                                    {new Intl.NumberFormat("tr-TR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(cart.prim)}{" "}
                                    TL
                                  </p>
                                </div>
                                <Link
                                  to=""
                                  onClick={() => this.props.removeToCart(cart)}
                                >
                                  <img
                                    src={close1}
                                    alt=""
                                    style={{ width: 14, height: 14 }}
                                  />
                                </Link>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <label>Sepetiniz Boş</label>
                        )}

                        {this.props.proposal.cart.length == 0 ? (
                          <Link
                            to="#"
                            style={{ pointerEvents: "none" }}
                            className="sepet-button"
                            onClick={() => this.stepper("adim8")}
                          >
                            Satın Al
                          </Link>
                        ) : (
                          <Link
                            to="#"
                            className="sepet-button"
                            onClick={() => this.stepper("adim8")}
                          >
                            Satın Al
                          </Link>
                        )}
                      </div>
                      <div className="d-flex flex-column justify-content-center align-items-center w-100">
                                  <Link
                                    to="#"
                                    className="sepet-button sizi-arayalim"
                                    onClick={()=>this.callMe()}
                                  >
                                    SİZİ ARAYALIM
                                  </Link>
                                </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <section id="adim9" className="adim9 inactive">
              <div className="container seyahat-container">
                <h2>Tarfik Sigorta Teklifleri</h2>
                {this.props.trafik ? (
                  <div className="EnUygun">
                    <div
                      style={{
                        width: 180,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <div id="spinnerTrafik">
                        <img src={wait} alt="" />
                      </div>
                      <label id="spinnerLabel">{this.state.lengthCount}</label>
                    </div>
                    <div className="en-uygun-line mobile-line"></div>
                    <div className="teklif-adet">
                      <label>Teklif Sayısı</label>
                      <label>{this.props.trafik.length}</label>
                    </div>
                    <div className="en-uygun-line"></div>
                    <div className="teklif-fiyat">
                      <label>En Düşük Fiyat</label>
                      {this.props.trafik[0] ? (
                        <img
                          src={
                            sirketImgURL +
                            this.props.trafik[0].sirketKodu +
                            ".png"
                          }
                          alt=""
                          style={{ height: 40 }}
                        />
                      ) : null}
                      {this.props.trafik[0] ? (
                        <label>
                          {new Intl.NumberFormat("tr-TR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(this.props.trafik[0].prim)}{" "}
                          TL
                        </label>
                      ) : (
                        <label>0</label>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="EnUygun">
                    <div
                      style={{
                        width: 180,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <div id="spinnerTrafik">
                        <img src={wait} alt="" />
                      </div>
                      <label id="spinnerLabel">{this.state.lengthCount}</label>
                    </div>
                    <div className="en-uygun-line mobile-line"></div>
                    <div className="teklif-adet">
                      <label>Teklif Sayısı</label>
                      <label>{this.props.trafik.length}</label>
                    </div>
                    <div className="en-uygun-line"></div>
                    <div className="teklif-fiyat">
                      <label>En Düşük Fiyat</label>
                      {this.props.trafik[0] ? (
                        <img
                          src={
                            sirketImgURL +
                            this.props.trafik[0].sirketKodu +
                            ".png"
                          }
                          alt=""
                          style={{ height: 40 }}
                        />
                      ) : null}
                      {this.props.trafik[0] ? (
                        <label>
                          {new Intl.NumberFormat("tr-TR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(this.props.trafik[0].prim)}{" "}
                          TL
                        </label>
                      ) : (
                        <label>0</label>
                      )}
                    </div>
                  </div>
                )}
                <div className="TeklifContainer container">
                  <div className="scroll">
                    {this.props.trafik ? (
                      <div className="TeklifListesiContainer">
                        {this.props.trafik.map((e, index) => (
                          <div>
                            {index === 0 ? (
                              <div className="en-uygun-teklif-box box">
                                <div className="yildizli">
                                  <p>En</p>
                                  <p>Uygun</p>
                                </div>
                                {e.sirketKodu === "011" ? (
                                  <div className="d-flex flex-row justify-content-center align-items-center">
                                    <img
                                      src={sirketImgURL + e.sirketKodu + ".png"}
                                      alt=""
                                      className="teklif-box-image"
                                    />
                                    <OverlayTrigger
                                      placement="right"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={this.renderTooltip}
                                    >
                                      <div className="grupamaOzel">
                                        <img
                                          src={discount}
                                          alt=""
                                          className="grupamaOzelImg"
                                        />
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                ) : (
                                  <img
                                    src={sirketImgURL + e.sirketKodu + ".png"}
                                    alt=""
                                    className="teklif-box-image"
                                  />
                                )}
                                <div className="teklif-box-line"></div>
                                <label>{e.bransName}</label>
                                <div className="teklif-box-line"></div>
                                <label>
                                  {new Intl.NumberFormat("tr-TR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(e.prim)}{" "}
                                  TL x 1 Taksit
                                </label>
                                <label>
                                  <b>
                                    {new Intl.NumberFormat("tr-TR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(e.prim)}{" "}
                                    TL
                                  </b>
                                </label>
                                <div className="teklif-box-line"></div>
                                <div className="d-flex flex-row justify-content-around align-items-center mt-1 mb-3 w-100">
                                  <Link to="#" className="teklif-listesi-a">
                                    Teklif Detayı
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="8"
                                      viewBox="0 0 22 11"
                                    >
                                      <path
                                        id="Polygon_30"
                                        data-name="Polygon 30"
                                        d="M11,0,22,11H0Z"
                                        transform="translate(22 11) rotate(180)"
                                        fill="#0a4cac"
                                      />
                                    </svg>
                                  </Link>
                                  <Link
                                    to="#"
                                    className="teklif-listesi-a odeme-plani"
                                  >
                                    Ödeme Planı
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="8"
                                      viewBox="0 0 22 11"
                                    >
                                      <path
                                        id="Polygon_30"
                                        data-name="Polygon 30"
                                        d="M11,0,22,11H0Z"
                                        transform="translate(22 11) rotate(180)"
                                        fill="#0a4cac"
                                      />
                                    </svg>
                                  </Link>
                                </div>
                                <div className="d-flex flex-row justify-content-around align-items-center w-100">
                                  <Link
                                    to="#"
                                    className="teklif-ekle-button"
                                    onClick={() => this.addToCart(e)}
                                  >
                                    Sepete Ekle
                                  </Link>
                                </div>
                                <div className="form-check form-switch karsilastirma">
                                  <label className="form-check-label">
                                    Karşılaştırma Listesine Ekle
                                  </label>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="teklif-box box">
                                {e.sirketKodu === "011" ? (
                                  <div className="d-flex flex-row justify-content-center align-items-center">
                                    <img
                                      src={sirketImgURL + e.sirketKodu + ".png"}
                                      alt=""
                                      className="teklif-box-image"
                                    />
                                    <OverlayTrigger
                                      placement="right"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={this.renderTooltip}
                                    >
                                      <div className="grupamaOzel">
                                        <img
                                          src={discount}
                                          alt=""
                                          className="grupamaOzelImg"
                                        />
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                ) : (
                                  <img
                                    src={sirketImgURL + e.sirketKodu + ".png"}
                                    alt=""
                                    className="teklif-box-image"
                                  />
                                )}
                                <div className="teklif-box-line"></div>
                                <label>{e.bransName}</label>
                                <div className="teklif-box-line"></div>
                                <label>
                                  {new Intl.NumberFormat("tr-TR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(e.prim)}{" "}
                                  TL x 1 Taksit
                                </label>
                                <label>
                                  <b>
                                    {new Intl.NumberFormat("tr-TR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(e.prim)}{" "}
                                    TL
                                  </b>
                                </label>
                                <div className="teklif-box-line"></div>
                                <div className="d-flex flex-row justify-content-around align-items-center mt-1 mb-3 w-100">
                                  <Link to="#" className="teklif-listesi-a">
                                    Teklif Detayı
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="8"
                                      viewBox="0 0 22 11"
                                    >
                                      <path
                                        id="Polygon_30"
                                        data-name="Polygon 30"
                                        d="M11,0,22,11H0Z"
                                        transform="translate(22 11) rotate(180)"
                                        fill="#0a4cac"
                                      />
                                    </svg>
                                  </Link>
                                  <Link
                                    to="#"
                                    className="teklif-listesi-a odeme-plani"
                                  >
                                    Ödeme Planı
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="8"
                                      viewBox="0 0 22 11"
                                    >
                                      <path
                                        id="Polygon_30"
                                        data-name="Polygon 30"
                                        d="M11,0,22,11H0Z"
                                        transform="translate(22 11) rotate(180)"
                                        fill="#0a4cac"
                                      />
                                    </svg>
                                  </Link>
                                </div>
                                <div className="d-flex flex-row justify-content-around align-items-center w-100">
                                  <Link
                                    to="#"
                                    className="teklif-ekle-button"
                                    onClick={() => this.addToCart(e)}
                                  >
                                    Sepete Ekle
                                  </Link>
                                </div>
                                <div
                                  className="form-check form-switch karsilastirma"
                                  onClick={() => this.addToBox(e)}
                                >
                                  <label className="form-check-label">
                                    Karşılaştırma Listesine Ekle
                                  </label>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <label>Teklif Bulunamadı.</label>
                    )}

                    <div className="container d-flex justify-content-center align-items-center mt-3 mb-5 daha-cok">
                      <Link to="#" style={{ color: "#000" }}>
                        Daha çok teklif görüntüle
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="11"
                          viewBox="0 0 22 11"
                        >
                          <path
                            id="Polygon_47"
                            data-name="Polygon 47"
                            d="M11,0,22,11H0Z"
                            transform="translate(22 11) rotate(180)"
                            fill="#0a4cac"
                          />
                        </svg>
                      </Link>
                    </div>
                  </div>

                  <div className="fixed-box">
                    <div className="geri-button mb-3">
                      <Link
                        to="#"
                        style={{ color: "#fff" }}
                        onClick={() => this.stepper("adim6")}
                      >
                        Geri
                      </Link>
                    </div>
                    <div className="onay-button mb-3 teklif-karsilastirma-container">
                      <Link
                        to="#"
                        style={{ color: "#000" }}
                        onClick={() => this.stepper("adim7")}
                      >
                        Tamamlayıcı Sağlık
                      </Link>
                    </div>
                    <div className="sepet-fixed-button mb-3">
                      <Link
                        to="#"
                        style={{ color: "#fff" }}
                        onClick={() => this.openSepetModal()}
                      >
                        <span style={{ marginRight: 10 }}>
                          {this.props.proposal.cart.length}
                        </span>
                        Sepet
                      </Link>
                    </div>
                  </div>

                  <div className="sepet">
                    <div className="d-flex flex-row justify-content-between">
                      <div className="geri-button mb-3">
                        <Link
                          to="#"
                          style={{ color: "#fff" }}
                          onClick={() => this.stepper("adim6")}
                        >
                          Geri
                        </Link>
                      </div>
                      <div className="teklif-karsilastirma-container mb-3 w-75">
                        <Link
                          to="#"
                          style={{ color: "#000" }}
                          onClick={() => this.stepper("adim7")}
                        >
                          Tamamlayıcı Sağlık Teklifleri
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="teklif-karsilastirma-container">
                        <h4>
                          <b>Karşılaştırma Listesi</b>
                        </h4>
                        {this.props.proposal.box.length != 0 ? (
                          <div>
                            {this.props.proposal.box.map((box) => (
                              <div className="teklif-karsilastirma-box">
                                <div style={{ width: 80 }}>
                                  <img
                                    src={sirketImgURL + box.sirketKodu + ".png"}
                                    alt=""
                                    style={{ height: 25 }}
                                  />
                                </div>
                                <div className="teklif-karsilastirma-line"></div>
                                <div style={{ width: 50, textAlign: "center" }}>
                                  <p>{box.bransName}</p>
                                </div>
                                <div className="teklif-karsilastirma-line"></div>
                                <div>
                                  <p>
                                    {new Intl.NumberFormat("tr-TR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(box.prim)}{" "}
                                    TL
                                  </p>
                                </div>
                                <Link
                                  to=""
                                  onClick={() => this.props.removeToBox(box)}
                                >
                                  <img
                                    src={close1}
                                    alt=""
                                    style={{ width: 14, height: 14 }}
                                  />
                                </Link>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <label>Sepetiniz Boş</label>
                        )}

                        {this.props.proposal.box.length == 0 ? (
                          <Link
                            to="#"
                            style={{ pointerEvents: "none" }}
                            className="teklif-karsilastir-button"
                            onClick={() => this.openKarsilastirmaModal()}
                          >
                            Detaylı Karşılaştır
                          </Link>
                        ) : (
                          <Link
                            to="#"
                            className="teklif-karsilastir-button"
                            onClick={() =>
                              this.openKarsilastirmaModal(
                                this.props.proposal.box
                              )
                            }
                          >
                            Detaylı Karşılaştır
                          </Link>
                        )}
                      </div>
                      <div className="sepet-container">
                        <h4>
                          <b>Sigorta Sepeti</b>
                        </h4>

                        {this.props.proposal.cart.length != 0 ? (
                          <div>
                            {this.props.proposal.cart.map((cart) => (
                              <div className="teklif-karsilastirma-box">
                                <div style={{ width: 80 }}>
                                  <img
                                    src={
                                      sirketImgURL + cart.sirketKodu + ".png"
                                    }
                                    alt=""
                                    style={{ height: 25 }}
                                  />
                                </div>
                                <div className="teklif-karsilastirma-line"></div>
                                <div style={{ width: 50, textAlign: "center" }}>
                                  <p>{cart.bransName}</p>
                                </div>
                                <div className="teklif-karsilastirma-line"></div>
                                <div>
                                  <p>
                                    {new Intl.NumberFormat("tr-TR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(cart.prim)}{" "}
                                    TL
                                  </p>
                                </div>
                                <Link to="">
                                  <img
                                    src={close1}
                                    alt=""
                                    style={{ width: 14, height: 14 }}
                                  />
                                </Link>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <label>Sepetiniz Boş</label>
                        )}

                        {this.props.proposal.cart.length == 0 ? (
                          <Link
                            to="#"
                            style={{ pointerEvents: "none" }}
                            className="sepet-button"
                            onClick={() => this.stepper("adim8")}
                          >
                            Satın Al
                          </Link>
                        ) : (
                          <Link
                            to="#"
                            className="sepet-button"
                            onClick={() => this.stepper("adim8")}
                          >
                            Satın Al
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            <section id="adim7" className="adim inactive">
              <div className="container seyahat-container">
                <h2>Tamamlayıcı Sağlık Sigorta Teklifleri</h2>
                {this.props.tss ? (
                  <div className="EnUygun">
                    <div
                      style={{
                        width: 180,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <div id="spinner">
                        <img src={wait} alt="" />
                      </div>
                      <label id="spinnerLabel">{this.state.lengthCount}</label>
                    </div>
                    <div className="en-uygun-line mobile-line"></div>
                    <div className="teklif-adet">
                      <label>Teklif Sayısı</label>
                      <label>{this.props.tss.length}</label>
                    </div>
                    <div className="en-uygun-line"></div>
                    <div className="teklif-fiyat">
                      <label>En Düşük Fiyat</label>
                      {this.props.tss[0] ? (
                        <img
                          src={
                            sirketImgURL + this.props.tss[0].sirketKodu + ".png"
                          }
                          alt=""
                          style={{ height: 40 }}
                        />
                      ) : null}
                      {this.props.tss[0] ? (
                        <label>
                          {new Intl.NumberFormat("tr-TR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(this.props.tss[0].prim)}{" "}
                          TL
                        </label>
                      ) : (
                        <label>0</label>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="EnUygun">
                    <div
                      style={{
                        width: 180,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <div id="spinner">
                        <img src={wait} alt="" />
                      </div>
                      <label id="spinnerLabel">{this.state.lengthCount}</label>
                    </div>
                    <div className="en-uygun-line mobile-line"></div>
                    <div className="teklif-adet">
                      <label>Teklif Sayısı</label>
                      <label>{this.props.tss.length}</label>
                    </div>
                    <div className="en-uygun-line"></div>
                    <div className="teklif-fiyat">
                      <label>En Düşük Fiyat</label>
                      {this.props.tss[0] ? (
                        <img
                          src={
                            sirketImgURL + this.props.tss[0].sirketKodu + ".png"
                          }
                          alt=""
                          style={{ height: 40 }}
                        />
                      ) : null}
                      {this.props.tss[0] ? (
                        <label>
                          {new Intl.NumberFormat("tr-TR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(this.props.tss[0].prim)}{" "}
                          TL
                        </label>
                      ) : (
                        <label>0</label>
                      )}
                    </div>
                  </div>
                )}
                <div className="TeklifContainer container">
                  <div className="scroll">
                    {this.props.tss ? (
                      <div className="TeklifListesiContainer">
                        {this.props.tss.map((e, index) => (
                          <div>
                            {index === 0 ? (
                              <div className="en-uygun-teklif-box box">
                                <div className="yildizli">
                                  <p>En</p>
                                  <p>Uygun</p>
                                </div>
                                <img
                                  src={sirketImgURL + e.sirketKodu + ".png"}
                                  alt=""
                                  className="teklif-box-image"
                                />
                                <div className="teklif-box-line"></div>
                                <label>{e.bransName}</label>
                                <div className="teklif-box-line"></div>
                                <label>
                                  {new Intl.NumberFormat("tr-TR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(e.prim)}{" "}
                                  TL x {e.taksit} Taksit
                                </label>
                                <label>
                                  <b>
                                    {new Intl.NumberFormat("tr-TR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(e.prim)}{" "}
                                    TL
                                  </b>
                                </label>
                                <div className="teklif-box-line"></div>
                                <div className="d-flex flex-row justify-content-around align-items-center mt-1 mb-3 w-100">
                                  <Link to="#" 
                                  className="teklif-listesi-a"                             
                                  onClick={()=> this.openTeklifModal(e)}>
                                    Teklif Detayı
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="8"
                                      viewBox="0 0 22 11"
                                    >
                                      <path
                                        id="Polygon_30"
                                        data-name="Polygon 30"
                                        d="M11,0,22,11H0Z"
                                        transform="translate(22 11) rotate(180)"
                                        fill="#0a4cac"
                                      />
                                    </svg>
                                  </Link>
                                  {/* <Link
                                    to="#"
                                    className="teklif-listesi-a odeme-plani"
                                  >
                                    Ödeme Planı
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="8"
                                      viewBox="0 0 22 11"
                                    >
                                      <path
                                        id="Polygon_30"
                                        data-name="Polygon 30"
                                        d="M11,0,22,11H0Z"
                                        transform="translate(22 11) rotate(180)"
                                        fill="#0a4cac"
                                      />
                                    </svg>
                                  </Link> */}
                                </div>
                                <div className="d-flex flex-row justify-content-around align-items-center w-100">
                                  <Link
                                    to="#"
                                    className="teklif-ekle-button"
                                    onClick={() => this.addToCart(e)}
                                  >
                                    Sepete Ekle
                                  </Link>
                                </div>
                              </div>
                            ) : (
                              <div className="teklif-box box">
                                <img
                                  src={sirketImgURL + e.sirketKodu + ".png"}
                                  alt=""
                                  className="teklif-box-image"
                                />
                                <div className="teklif-box-line"></div>
                                <label>{e.bransName}</label>
                                <div className="teklif-box-line"></div>
                                <label>
                                  {new Intl.NumberFormat("tr-TR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(e.prim)}{" "}
                                  TL x {e.taksit} Taksit
                                </label>
                                <label>
                                  <b>
                                    {new Intl.NumberFormat("tr-TR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(e.prim)}{" "}
                                    TL
                                  </b>
                                </label>
                                <div className="teklif-box-line"></div>
                                <div className="d-flex flex-row justify-content-around align-items-center mt-1 mb-3 w-100">
                                <Link to="#" 
                                  className="teklif-listesi-a"                             
                                  onClick={()=> this.openTeklifModal(e)}>
                                    Teklif Detayı
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="8"
                                      viewBox="0 0 22 11"
                                    >
                                      <path
                                        id="Polygon_30"
                                        data-name="Polygon 30"
                                        d="M11,0,22,11H0Z"
                                        transform="translate(22 11) rotate(180)"
                                        fill="#0a4cac"
                                      />
                                    </svg>
                                  </Link>
                                  {/* <Link
                                    to="#"
                                    className="teklif-listesi-a odeme-plani"
                                  >
                                    Ödeme Planı
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="8"
                                      viewBox="0 0 22 11"
                                    >
                                      <path
                                        id="Polygon_30"
                                        data-name="Polygon 30"
                                        d="M11,0,22,11H0Z"
                                        transform="translate(22 11) rotate(180)"
                                        fill="#0a4cac"
                                      />
                                    </svg>
                                  </Link> */}
                                </div>
                                <div className="d-flex flex-row justify-content-around align-items-center w-100">
                                  <Link
                                    to="#"
                                    className="teklif-ekle-button"
                                    onClick={() => this.addToCart(e)}
                                  >
                                    Sepete Ekle
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <label>Teklif bulunamamıştır.</label>
                    )}

                    {/* <div className="container d-flex justify-content-center align-items-center mt-3 mb-5 daha-cok">
                      <Link to="#" style={{ color: "#000" }}>
                        Daha çok teklif görüntüle
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="11"
                          viewBox="0 0 22 11"
                        >
                          <path
                            id="Polygon_47"
                            data-name="Polygon 47"
                            d="M11,0,22,11H0Z"
                            transform="translate(22 11) rotate(180)"
                            fill="#0a4cac"
                          />
                        </svg>
                      </Link>
                    </div> */}
                  </div>

                  <div className="fixed-box">
                    <div className="geri-button mb-3">
                      <Link
                        to="#"
                        style={{ color: "#fff" }}
                        onClick={() => this.stepper("adim6")}
                      >
                        Geri
                      </Link>
                    </div>
                    <div className="sepet-fixed-button mb-3">
                      <Link
                        to="#"
                        style={{ color: "#fff" }}
                        onClick={() => this.openModal()}
                      >
                        <span style={{ marginRight: 10 }}>
                          {this.props.proposal.cart.length}
                        </span>
                        Sepet
                      </Link>
                    </div>
                  </div>
                  <div className="sepet">
                    <div className="d-flex flex-row justify-content-between">
                      <div className="geri-button mb-3">
                        <Link
                          to="#"
                          style={{ color: "#fff" }}
                          onClick={() => this.stepper("adim6")}
                        >
                          Geri
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="sepet-container">
                        <h4>
                          <b>Sigorta Sepeti</b>
                        </h4>
                        {this.props.proposal.cart.length != 0 ? (
                          <div>
                            {this.props.proposal.cart.map((cart) => (
                              <div className="teklif-karsilastirma-box">
                                <div style={{ width: 80 }}>
                                  <img
                                    src={
                                      sirketImgURL + cart.sirketKodu + ".png"
                                    }
                                    alt=""
                                    style={{ height: 25 }}
                                  />
                                </div>
                                <div className="teklif-karsilastirma-line"></div>
                                <div style={{ width: 50, textAlign: "center" }}>
                                  <p>{cart.bransName}</p>
                                </div>
                                <div className="teklif-karsilastirma-line"></div>
                                <div>
                                  <p>
                                    {new Intl.NumberFormat("tr-TR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(cart.prim)}{" "}
                                    TL
                                  </p>
                                </div>
                                <Link
                                  to=""
                                  onClick={() => this.props.removeToCart(cart)}
                                >
                                  <img
                                    src={close1}
                                    alt=""
                                    style={{ width: 14, height: 14 }}
                                  />
                                </Link>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <label>Sepetiniz Boş</label>
                        )}

                        {this.props.proposal.cart.length == 0 ? (
                          <Link
                            to="#"
                            style={{ pointerEvents: "none" }}
                            className="sepet-button"
                            onClick={() => this.stepper("adim8")}
                          >
                            Satın Al
                          </Link>
                        ) : (
                          <Link
                            to="#"
                            className="sepet-button"
                            onClick={() => this.stepper("adim8")}
                          >
                            Satın Al
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="adim8" className="adim8 inactive">
              <div className="container kasko-teklif-container">
                <h3>
                  <b>Sigorta Sepetiniz</b>
                </h3>
                <div className="SepetContainer container">
                  {this.props.proposal.cart.map((item) => (
                    <div className="teklif-box">
                      <img
                        src={sirketImgURL + item.sirketKodu + ".png"}
                        alt=""
                        style={{ height: 41, marginTop: 30 }}
                      />
                      <div className="teklif-box-line"></div>
                      <label>{item.bransName}</label>
                      <div className="teklif-box-line"></div>
                      <label>
                        {new Intl.NumberFormat("tr-TR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(item.prim)}{" "}
                        TL x {item.taksit} Taksit
                      </label>
                      <label>
                        <b>
                          {new Intl.NumberFormat("tr-TR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(item.prim)}{" "}
                          TL
                        </b>
                      </label>
                      <div className="teklif-box-line"></div>
                      <div className="d-flex flex-row justify-content-around align-items-center mt-1 mb-3 w-100">
                      <Link to="#" 
                                  className="teklif-listesi-a"                             
                                  onClick={()=> this.openTeklifModal(item)}>
                          Teklif Detayı
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="8"
                            viewBox="0 0 22 11"
                          >
                            <path
                              id="Polygon_30"
                              data-name="Polygon 30"
                              d="M11,0,22,11H0Z"
                              transform="translate(22 11) rotate(180)"
                              fill="#0a4cac"
                            />
                          </svg>
                        </Link>
                      </div>

                      {this.state.policyButton == true ? (
                        <div className="d-flex flex-column justify-content-around align-items-center w-50">
                          <Link
                            to="#"
                            className="sepet-button satin-al"
                            onClick={() => this.openOdemeModal(item)}
                          >
                            SATIN AL
                          </Link>
                        </div>
                      ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center w-100">
                          {this.props.policy.policy.teklifDetayID ==
                          item.teklifDetayID ? (
                            <div>
                              {this.props.policy.policy.isSuccess === true ? (
                                <div className="d-flex flex-row justify-content-around align-items-center w-100">
                                  <Link
                                    to="#"
                                    className="sepet-button satin-al"
                                    onClick={() => this.openBase64NewTab(item)}
                                  >
                                    Poliçe İndir
                                  </Link>
                                  <Link
                                    to="#"
                                    className="sepet-button satin-al"
                                  >
                                    Makbuz İndir
                                  </Link>
                                </div>
                              ) : (
                                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                                  <Link
                                    to="#"
                                    className="sepet-button sizi-arayalim"
                                    onClick={()=> this.callMe(item)}
                                  >
                                    SİZİ ARAYALIM
                                  </Link>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="d-flex flex-column justify-content-center align-items-center w-100">
                              <Link
                                to="#"
                                className="sepet-button satin-al"
                                onClick={() => this.openOdemeModal(item)}
                              >
                                SATIN AL
                              </Link>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="fixed-box">
                  <div className="geri-button mb-3">
                    <Link
                      to="#"
                      style={{ color: "#fff" }}
                      onClick={() => this.stepper("adim7")}
                    >
                      Geri
                    </Link>
                  </div>
                  </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let tss = state.proposal.offers.filter((t) => {
    return t.bransNo === 18;
  });
  let kasko = state.proposal.offers.filter((k) => {
    return k.bransNo === 1 && k.prim > 0;
  });
  /* let trafik = state.proposal.offers.filter((t) => {
    return t.bransNo === 0;
  }); */

  return {
    auth: state.auth,
    sms: state.sms,
    customer: state.customer,
    common: state.common,
    proposal: state.proposal,
    bilgiBankasi: state.bilgiBankasi,
    tss,
    kasko,
    /* trafik, */
    policy: state.policy,
  };
};

export default connect(mapStateToProps, actions)(Kasko);
