import React, { Component } from 'react'

export class Dask extends Component {
  render() {
    return (
      <div>
        <p><b>DASK Sigortası Nedir?</b></p>
        <p>Deprem ve deprem sonucu meydana gelen yangın, patlama, tsunami ve yer kayması gibi olaylar, doğrudan maddi zarara neden olabilir. DASK (Doğal Afetler Sigortalar Kurumu) Zorunlu Deprem Sigortası, bu maddi zararları sigorta poliçesindeki limitler doğrultusunda karşılar. Zorunlu Deprem Sigortası yaptırarak, ikamet ettiğin binayı tamamen veya kısmen zarar gördüğünde teminat altına alabilirsin. DASK sigortası yaptırmadığın durumda ise bu yardımdan yararlanman mümkün değildir.</p>
        <p><b>DASK Hesaplama Nasıl Yapılır?</b></p>
        <p>Deprem sigortası fiyatları “Zorunlu Deprem Sigortası Tarife ve Talimatı’na göre belirlenir. DASK primleri hesaplanırken ikamet ettiğin binanın yapı tarzı, inşa yılı, toplam kat ve daire yüz ölçümü gibi bilgiler belirleyici olur. Her konutun özelliğine göre farklı bir DASK ücreti belirlense de senin kendi konutun için ödemen gereken DASK fiyatı sigorta şirketine göre değişmez. Sen de turkhavaksigorta.com’dan online DASK teklifi alabilir, evin için çıkan primi hızlıca görüp satın alma işlemini gerçekleştirebilirsin.</p>
        <p><b>DASK Nasıl Yaptırılır?</b></p>
        <p>DASK fiyatları, sigorta şirketlerine göre değişmediği için uzun araştırmalar yapmana gerek yok. Bilgilerini girerek sadece 2 dakikada turkhavaksigorta.com ‘dan teklif alabilir, deprem sigortanı hızlıca internetten yaptırabilirsin.</p>
      </div>
    )
  }
}

export default Dask