import axios from 'axios';
import { API_URL } from "./definitions";
import { CREATE_CUSTOMER, CUSTOMER_SMS_CHECK, CUSTOMER_SEND_SMS, CUSTOMER_SEND_MAIL,SET_ENTER, CUSTOMER_DETAIL, CUSTOMER_UPDATE, RESET_CUSTOMER } from './types';


export const createCustomer = (customer) => {
    return (dispatch) => {
        axios.post(API_URL + '/api/Customer/CreateCustomer', customer)
        .then(response => {
            customer.musID=response.data.musID;
            customer.isSuccess =response.data.isSuccess;
            customer.errorMessage = response.data.errorMessage;
            customer.uavtAdresKodu = response.data.uavtAdresKodu;
            customer.job = response.data.musID;
            customer.musteriAdi = response.data.musteriAdi
            dispatch({
                type: CREATE_CUSTOMER,
                payload: customer
            })
        }).catch(error => {
            customer.errorMessage=error.response.data.errorMessage
            dispatch({
                type: CREATE_CUSTOMER,
                payload: customer
            })
         })
    }
}

export const customerSmsCheck = (sms) => {
    return async (dispatch) => {
        axios.post(API_URL + '/api/Customer/CustomerSmsCheck', sms)
        .then(response => {
            dispatch({
                type: CUSTOMER_SMS_CHECK,
                payload: response.data
            })
        }).catch(error => {
            dispatch({
                type: CUSTOMER_SMS_CHECK,
                payload: error.response.data
            })
         })

    }
}

export const customerSendSms = (musID) => {
    return (dispatch) => {
        axios.post(API_URL + '/api/Customer/CustomerSendSms', musID)
        .then(response => {
            dispatch({
                type: CUSTOMER_SEND_SMS,
                payload: response.data
            })
        })
    }
}

export const customerSendMail = (mail) => {
    return (dispatch) => {
        axios.post(API_URL + '/api/Customer/IletisimFormu', mail)
        .then(response => {
            dispatch({
                type: CUSTOMER_SEND_MAIL,
                payload: response.data
            })
        })
    }
}

export const setEnter = (phoneNumber) => {
    return (dispatch) => {
        axios.get( API_URL + '/api/Customer/SetEnter?phoneNumber=' + phoneNumber + '&name= &lastname=')
        .then(response => {
            dispatch({
                type: SET_ENTER,
                payload: response.data
            })
        }).catch (error => {
            phoneNumber.message = error.response.data.message
            dispatch({
                type: SET_ENTER,
                payload: phoneNumber
            })
        })
    };
}

export const customerDetail = (user) => {
    return async (dispatch) => {
       await axios.get(API_URL + '/api/Customer/CustomerDetail', {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then(response=> {
          dispatch({
              type: CUSTOMER_DETAIL,
              payload: response.data
          })
      })
    }
}

export const customerUpdate = (customer, user) => {
    return async (dispatch) => {
       axios.post(API_URL + '/api/Customer/CustomerUpdate', customer, 
       {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
       .then(response => {
        dispatch({
            type: CUSTOMER_UPDATE,
            payload: customer
        })
    }).catch(error => {

        dispatch({
            type: CUSTOMER_UPDATE,
            payload: customer
        })
     })
}
}

export const resetCustomer = () => {

    return async (dispatch) => {
        dispatch({
            type: RESET_CUSTOMER,
            payload: ''
        })
    }
    
}

