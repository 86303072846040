/* eslint-disable array-callback-return */
import axios from "axios";
import { GET_LIST_MARKA, GET_ARAC_BEDELI, GET_BY_MARKA_AND_MODEL, GET_IL_LIST, GET_ILCE_LIST, GET_ULKE_LIST } from './types';
const API_URL = 'https://bbapi.crm2c.com';


export const getIlList = () => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/BilgiBankasi/GetIlList')
        .then(response => {
            dispatch ({
                type: GET_IL_LIST,
                payload: response.data
            })
        })
    }
}

export const getIlceList = (il) => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/BilgiBankasi/GetIlceList?IlKodu=' + il)
        .then(response => {
            dispatch ({
                type: GET_ILCE_LIST,
                payload: response.data
            })
        })
    }
}

export const getUlkeList = () => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/BilgiBankasi/GetUlkeList')
        .then(response => {
            dispatch ({
                type: GET_ULKE_LIST,
                payload: response.data
            })
        })
    }
}

export const getListMarka = () => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/BilgiBankasi/GetListMarka')
        .then(response => {
            dispatch ({
                type: GET_LIST_MARKA,
                payload: response.data
            })
        })
    }
}

export const getByMarkaKodAndModel = (markaKodu, modelYili) => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/BilgiBankasi/GetByMarkaKodAndModel?MarkaKodu=' + markaKodu + '&Model=' + modelYili)
        .then(response => {
            dispatch ({
                type: GET_BY_MARKA_AND_MODEL,
                payload: response.data
            })
        })
    }
}

export const getAracBedeli = (aracKodu, modelYili) => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/BilgiBankasi/GetAracBedeli?AracKodu=' + aracKodu)
        .then(response => {
            let bedel;
             response.data.filter((a) => {
                if(a.ModelYili===modelYili) {
                    return bedel = a.Bedeli.toString();
                }
            })
            dispatch ({
                type: GET_ARAC_BEDELI,
                payload: bedel
            })
        })
    }
}