export const USER_REGISTER = 'user_register';
export const USER_LOGIN = 'user_login';
export const USER_ERROR = 'user_error';
export const SET_ENTER = 'set_enter';
export const USER_LOGOUT = 'user_logout';
export const USER_NAME = 'user_name';
export const USER_SEND_SMS = 'user_send_sms';
export const USER_CHANGE_PASSWORD = 'user_change_password';

export const GET_JOB_LIST = 'get_job_list';
export const GET_COMPANY_LIST = 'get_company_list';
export const GET_GUARANTEE_LIST = 'get_guarantee_list';
export const GET_GUARANTEE_ALL_LIST = 'get_guarantee_all_list';
export const GET_BUILD_TYPE_LIST = 'get_build_type_list';
export const GET_BUILD_USE_TYPE_LIST = 'get_build_use_type_list';
export const GET_BUILD_YEAR_LIST = 'get_build_year_list';
export const GET_TOTAL_FLOOR_LIST = 'get_total_floor_list';
export const GET_HEALTH_PLAN_LIST = 'get_health_plan_list';
export const GET_HEALTH_PLAN_TYPE_LIST = 'get_healt_plan_type_list';
export const GET_INSURED_TYPE_LIST = 'get_insured_type_list';
export const REASON_FOR_TRAVEL = 'reason_for_travel';
export const GET_DISTRICT = 'get_district';
export const GET_VILLAGE = 'get_village';
export const GET_NEIGHBOURHOOD = 'get_neighbourhood';
export const GET_STREET = 'get_street';
export const GET_BUILD = 'get_build';
export const GET_INSIDE_DOOR = 'get_inside_door';
export const CALL_ME = 'call_me';
export const POLICE_DEGISIKLIGI = 'police_degisikligi';
export const POLICE_IADE = 'police_iade';
export const CREDIT_CARD = 'credit_card';

export const CREATE_CUSTOMER = 'create_customer';
export const CUSTOMER_SMS_CHECK = 'customer_sms_check';
export const CUSTOMER_SEND_SMS = 'customer_send_sms';
export const CUSTOMER_SEND_MAIL = 'customer_send_mail';
export const CUSTOMER_DETAIL = 'customer_detail';
export const CUSTOMER_UPDATE = 'customer_update';
export const RESET_CUSTOMER = 'reset_customer';

export const CREATE_PROPOSAL = 'create_proposal';
export const GET_PROPOSAL = 'get_proposal';
export const ADD_TO_CART = 'add_to_cart';
export const ADD_TO_BOX = 'add_to_box';
export const REMOVE_TO_CART = 'remove_to_cart';
export const REMOVE_TO_BOX = 'remove_to_box';
export const PROPOSAL_LIST = 'proposal_list';
export const CREATE_PROPOSAL_PETITION = 'create_proposal_petition';
export const RESET_PROPOSAL = 'reset_proposal';


export const GET_LIST_MARKA = 'get_list_marka';
export const GET_ARAC_BEDELI = 'get_arac_bedeli';
export const GET_BY_MARKA_AND_MODEL = 'get_by_marka_and_model';
export const  GET_IL_LIST = 'get_il_list';
export const GET_ILCE_LIST = 'get_ilce_list';
export const GET_ULKE_LIST = 'get_ulke_list';

export const CREATE_POLICY = 'create_policy';
export const GET_PRINT = 'get_print';
export const POLICY_LIST = 'policy_list';

export const GET_BLOG_LIST = 'get_blog_list';
export const GET_SLIDE_LIST = 'get_slide_list';
export const GET_CONTENT_LIST = 'get_content_list';