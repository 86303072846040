import React, { Component } from 'react';
import Hero from './hero/Hero';
import Urun from './urun/Urun';
import Kampanya from './kampanya/Kampanya';
import Banner from './banner/Banner';
import Karsilastir from './karsilastir/Karsilastir';
import Blog from './blog/Blog';
import Sirketler from './sirketler/Sirketler';
import Hakkimizda from './hakkimizda/Hakkimizda';

export default class Home extends Component {
  render() {
    return (
      <div>
          <Hero/>
          <Urun/>
          <Hakkimizda/>
          <Kampanya/>
          <Banner/>
          <Karsilastir/>
          <Blog/>
          <Sirketler/>
      </div>
    )
  }
}
