import React, { Component } from 'react'

export class Tss extends Component {
  render() {
    return (
      <div>
          <p><b>Tamamlayıcı Sağlık Sigortası Nedir?</b></p>
          <p>Tamamlayıcı sağlık sigortası (TSS), Sosyal Güvenlik Kurumu (SGK) ile anlaşması olan özel hastanelerde fark ücreti ödemeden sağlık hizmeti alabileceğin devlet destekli bir sağlık sigortasıdır. SGK’nın kapsamadığı ya da kapsadığı halde ücret limitlerini aşan tedavi giderlerini karşılayarak ilave ücret ödemeden tedavi olmanı sağlar.</p>
          <p><b>Tamamlayıcı Sağlık Sigortası Fiyatları Nasıl Hesaplanır?</b></p>
          <p>Tamamlayıcı sağlık sigortası fiyatları; cinsiyetine, yaşına, bulunduğun şehre ve sigorta poliçende yer alan teminat kapsamına göre değişiklik gösterir. Kendin ya da ailen için tamamlayıcı sağlık sigortası fiyatlarını hesaplamak istiyorsan yukarıdaki form alanına bilgilerini girmen yeterli. İnternetten TSS fiyat ve teminatlarını karşılaştırmalı olarak inceleyerek kendin için en iyi tamamlayıcı sağlık sigortasını seçebilirsin.</p>
          <p><b>Tamamlayıcı Sağlık Sigortası Sorgulama Nasıl Yapılır?</b></p>
          <p>Tamamlayıcı sağlık sigortası sorgulamasını internet üzerinden kolaylıkla yapabilirsin. e-Devlet üzerinden giriş yaparak tamamlayıcı sağlık sigortası poliçenle ilgili detaylı bilgilere ulaşabilirsin.</p>
          
      </div>
    )
  }
}

export default Tss