import React, { Component } from 'react';
import "./Blog.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import { API_URL } from '../../../redux/actions/definitions';

class Blog extends Component {
  componentDidMount(){
    setTimeout(()=> {
      this.props.getBlogList()
    },1000)
  }

  render() {
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "160px",
        slidesToShow: 3,
        speed: 500,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 820,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
              centerPadding: "65px",
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
              centerPadding: "65px",
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: "55px",
            }
          },
          {
            breakpoint: 395,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: "40px",
            }
          },
          {
            breakpoint: 365,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: "30px",
            }
          }
          ,
          {
            breakpoint: 285,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: "0px",
            }
          }
        ]
    };
    return (
        <div className='BilgiContainer'>
            <h4 style={{textAlign: 'center', paddingTop:20, paddingBottom: 20}}>Bilmeniz Gerekenler</h4>
            <div className='BilgiContent container'>
                <Slider {...settings}>
                    {this.props.admin.blogList.map(e=>
                      <div className="BilmenizGerekenBox">
                        <div className="BilmenizGerekenBoxImageBox" >
                            <img src={API_URL + '/api/Admin/Getimage?link=' + e.selectedFile} alt="" className="BilmenizGerekenBoxImage" />
                        </div>
                        <h6 className="BilmenizGerekenBoxTitle">{e.title}</h6>
                        <p className="BilmenizGerekenBoxText">{e.description}
                        
                        </p>
                        <Link to={'/blog/' + e.link} className="BilmenizGerekenBoxButton">
                            Devam {">>"}
                        </Link>
                      </div>


                    )}
                </Slider>

            </div>
            <h6 style={{textAlign: 'center', paddingBottom: 20}}>
              <a href="/">Diğer Konular</a>
            </h6>
            

        </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    admin: state.admin,
  }
}

export default connect(mapStateToProps, actions)(Blog);