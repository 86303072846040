import axios from "axios";
import { API_URL } from "./definitions";
import { GET_JOB_LIST, GET_COMPANY_LIST, GET_GUARANTEE_LIST, GET_BUILD_TYPE_LIST, GET_BUILD_USE_TYPE_LIST, GET_BUILD_YEAR_LIST, GET_TOTAL_FLOOR_LIST, GET_HEALTH_PLAN_LIST, GET_HEALTH_PLAN_TYPE_LIST, GET_INSURED_TYPE_LIST, GET_GUARANTEE_ALL_LIST, REASON_FOR_TRAVEL, GET_DISTRICT, GET_VILLAGE, GET_NEIGHBOURHOOD, GET_STREET, GET_BUILD, GET_INSIDE_DOOR, CALL_ME, POLICE_DEGISIKLIGI, POLICE_IADE, CREDIT_CARD } from "./types";

export const getJobList = () => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/Common/GetJob')
        .then(response => {
            dispatch ({
                type: GET_JOB_LIST,
                payload: response.data
            })
        })
    }
}

export const getCompanyList = (branch) => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/Common/GetCompany?BransNo=' + branch)
        .then(response => {
            dispatch ({
                type: GET_COMPANY_LIST,
                payload: response.data
            })
        })
    }
}

export const getGuaranteeList = () => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/Common/GetGuarantee')
        .then(response => {
            dispatch ({
                type: GET_GUARANTEE_LIST,
                payload: response.data
            })
        })
    }
}

export const getGuaranteeAllList = (teklifID) => {
    return async (dispatch) => {
        await axios.post(API_URL + '/api/Common/GetGuaranteeAll', {
            "teklifID": teklifID
          })
        .then(response => {

            dispatch ({
                type: GET_GUARANTEE_ALL_LIST,
                payload: response.data
            })
        })
    }
}

export const getBuildTypeList = () => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/Common/BuildType')
        .then(response => {
            dispatch ({
                type: GET_BUILD_TYPE_LIST,
                payload: response.data
            })
        })
    }
}

export const getBuildUseTypeList = () => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/Common/BuildUseType')
        .then(response => {
            dispatch ({
                type: GET_BUILD_USE_TYPE_LIST,
                payload: response.data
            })
        })
    }
}

export const getBuildYearList = () => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/Common/BuildYear')
        .then(response => {
            dispatch ({
                type: GET_BUILD_YEAR_LIST,
                payload: response.data
            })
        })
    }
}

export const getTotalFloorList = () => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/Common/TotalFloor')
        .then(response => {
            dispatch ({
                type: GET_TOTAL_FLOOR_LIST,
                payload: response.data
            })
        })
    }
}

export const getHealthPlanList = () => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/Common/HealthPlan')
        .then(response => {
            dispatch ({
                type: GET_HEALTH_PLAN_LIST,
                payload: response.data
            })
        })
    }
}

export const getHealthPlanTypeList = () => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/Common/HealthPlanType')
        .then(response => {
            dispatch ({
                type: GET_HEALTH_PLAN_TYPE_LIST,
                payload: response.data
            })
        })
    }
}

export const getInsuredTypeList = () => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/Common/InsuredType')
        .then(response => {
            dispatch ({
                type: GET_INSURED_TYPE_LIST,
                payload: response.data
            })
        })
    }
}

export const reasonForTravel = () => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/Common/ReasonForTravel')
        .then(response => {
            dispatch ({
                type: REASON_FOR_TRAVEL,
                payload: response.data
            })
        })
    }
}

export const getDistrict = (city) => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/Common/GetDistrict?CityCode=' + city)
        .then(response => {
            dispatch ({
                type: GET_DISTRICT,
                payload: response.data
            })
        })
    }
}

export const getVillage = (district) => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/Common/GetVillage?DistrictCode=' + district)
        .then(response => {
            dispatch ({
                type: GET_VILLAGE,
                payload: response.data
            })
        })
    }
}

export const getNeighbourhood = (village) => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/Common/GetNeighbourhood?VillageCode=' + village)
        .then(response => {
            dispatch ({
                type: GET_NEIGHBOURHOOD,
                payload: response.data
            })
        })
    }
}

export const getStreet = (neighbourhood) => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/Common/GetStreet?Neighbourhood=' + neighbourhood)
        .then(response => {
            dispatch ({
                type: GET_STREET,
                payload: response.data
            })
        })
    }
}

export const getBuild = (street, value) => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/Common/GetBuild?StreetCode=' + street + '&StreetName=' + value )
        .then(response => {
            dispatch ({
                type: GET_BUILD,
                payload: response.data
            })
        })
    }
}

export const getInsideDoor = (build) => {
    return async (dispatch) => {
        await axios.get(API_URL + '/api/Common/GetInsideDoor?BuildCode=' + build)
        .then(response => {
            dispatch ({
                type: GET_INSIDE_DOOR,
                payload: response.data
            })
        })
    }
}

export const callMe = (callMe) => {
    return async (dispatch) => {
        await axios.post(API_URL + '/api/Common/CallMe', {
            sigortaliUnvan: callMe.sigortaliUnvan,
            idendityNumber: callMe.idendityNumber,
            birthDate: callMe.birthDate,
            mobileNumber: callMe.mobileNumber,
            email: callMe.email
        })
        .then(response => {
            dispatch({
                type: CALL_ME,
                payload: response.data
            })
        })
    }
}

export const policeDegisikligi = (police) => {
    return async (dispatch) => {
        await axios.post(API_URL + '/api/Common/PoliceDegisikligi', {
            brans: police.brans,
            sigortaliUnvan: police.sigortaliUnvan,
            tanzimTarih: police.tanzimTarih,
            policeNo: police.policeNo,
            prim: police.prim.toString()
          })
          .then(response => {
              dispatch({
                  type: POLICE_DEGISIKLIGI,
                  payload: response.data
              })
          })
    }
}

export const policeIade = (police) => {
    return async (dispatch) => {
        await axios.post(API_URL + '/api/Common/PoliceIade', {
            brans: police.brans,
            sigortaliUnvan: police.sigortaliUnvan,
            tanzimTarih: police.tanzimTarih,
            policeNo: police.policeNo,
            prim: police.prim.toString()
          })
          .then(response => {
              dispatch({
                  type: POLICE_IADE,
                  payload: response.data
              })
          })
    }
}

export const getCreditCardInstallement = (kart) => {
    return async (dispatch) => {
        await axios.post(API_URL + '/api/Common/GetCreditCardInstallement', kart)
        .then(response => {
            dispatch({
                type: CREDIT_CARD,
                payload: response.data,
            })
        }).catch(error => {
            dispatch({
                type: CREDIT_CARD,
                payload: kart
            })
         })
    }
}
