import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "./Karsilastir.css";

const teklifbg = require('../../images/teklifbg.png');

export default class Karsilastir extends Component {
  render() {
    return (
        <div className='TeklifKarsilastir'>
        <Container>
          <div className='TeklifKarsilastirBox'>
            <div>
              <img src={teklifbg} alt='' style={{width: 270}} />
            </div>
            <div>
              <div className='KampanyaContent' style={{width:270, marginLeft:30}}>
                <h3 className='KampanyaSubTitle'>2 dakikada</h3>
                <h3 className='KampanyaSubTitle'>tüm teklifleri</h3>
                <h3 className='KampanyaSubTitle'>kolayca karşılaştır.</h3>
                <p className='KampanyaText'>
                  20+ sigorta şirketinden teklifleri tek platformda karşılaştır, internete özel indirimlerle uygun fiyata sigortan anında başlasın.
                </p>
                <a href='#' className='KampanyaButton'>Hemen Teklif Al {'>'}{'>'}</a>
              </div>
            </div>
          </div>
        </Container>
      </div>
    )
  }
}
