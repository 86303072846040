/* eslint-disable no-lone-blocks */
/* eslint-disable default-case */
//export const API_URL = "https://localhost:44333";
export const API_URL = "https://api2c.crm2c.gen.tr";


export const bransName = [
    { label: 'Ferdi Kaza Oto Kaza', value: 7 },
    { label: 'Hayat Sigortası', value: 8 },
    { label: 'Nakliyat Sigortası', value: 9 },
    { label: 'Mühendislik Sigortası', value: 10 },
    { label: 'Sorumluluk Sigortası', value: 11 },
    { label: 'Tarsim Sigortası', value: 12 },
    { label: 'Ferdi Kaza Oto Dışı', value: 13 },
    { label: 'Yangın Sigortası', value: 14 },
    { label: 'Yabancı Sağlık Sigortası', value: 15 },
    { label: 'Oto Dışı Kaza', value: 16 },
    { label: 'Biraysel Emeklilik', value: 17 },
    { label: 'Yangın Paket', value: 19 },
    { label: 'Hayvan Hayat Sigortası', value: 20 },
    { label: 'İnşaat Sigortası', value: 21 },
    { label: 'Alacak Sigortası', value: 22 },
    { label: 'Mesleki Sorumluluk Sigortası', value: 23 },
    { label: 'Kar Kaybı Sigortası', value: 24 },
    { label: 'Montaj Sigortası', value: 25 },
    { label: 'İhtiyari Mali Mesuliyet Sigortası', value:26},
    { label: 'Tekne/Yat Sigortaları', value:27},
]

export const bransName2 = [
    { label: 'Ferdi Kaza Oto Kaza', value: 7 },
    { label: 'Nakliyat Sigortası', value: 9 },
    { label: 'Mühendislik Sigortası', value: 10 },
    { label: 'Sorumluluk Sigortası', value: 11 },
    { label: 'Yangın Sigortası', value: 14 },
    { label: 'Yangın Paket', value: 19 },
    { label: 'Hayvan Hayat Sigortası', value: 20 },
    { label: 'İnşaat Sigortası', value: 21 },
    { label: 'Alacak Sigortası', value: 22 },
    { label: 'Mesleki Sorumluluk Sigortası', value: 23 },
    { label: 'Kar Kaybı Sigortası', value: 24 },
    { label: 'Montaj Sigortası', value: 25 },
    { label: 'İhtiyari Mali Mesuliyet Sigortası', value:26},
    { label: 'Tekne/Yat Sigortaları', value:27},
]

export const dataModel = [
    { label: "2023", value: "2023" },
    { label: "2022", value: "2022" },
    { label: "2021", value: "2021" }
];

export const kullanimTarziAl=[  
    { KullanimTarziAdi : "OTOMOBİL", TramerKullanimKodu : "01" },
    { KullanimTarziAdi : "TAKSİ", TramerKullanimKodu : "02" },
    { KullanimTarziAdi : "MİNİBÜS ", TramerKullanimKodu : "03" },
    { KullanimTarziAdi : "OTOBÜS(SÜRÜCÜ DAHİL 18 - 30 KOLTUK)", TramerKullanimKodu : "04" },
    { KullanimTarziAdi : "OTOBÜS(SÜRÜCÜ DAHİL 31 VE ÜSTÜ KOLTUK)", TramerKullanimKodu : "05" },
    { KullanimTarziAdi : "KAMYONET", TramerKullanimKodu : "06" },
    { KullanimTarziAdi : "KAMYON", TramerKullanimKodu : "07" },
    { KullanimTarziAdi : "İŞ MAKİNASI", TramerKullanimKodu : "08" },
    { KullanimTarziAdi : "TRAKTÖR", TramerKullanimKodu : "09" },
    { KullanimTarziAdi : "RÖMORK", TramerKullanimKodu : "10" },
    { KullanimTarziAdi : "MOTOSİKLET VE YÜK MOTOSİKLETİ", TramerKullanimKodu : "11" },
    { KullanimTarziAdi : "TANKER", TramerKullanimKodu : "12" },
    { KullanimTarziAdi : "ÇEKİCİ", TramerKullanimKodu : "13" },
    { KullanimTarziAdi : "ÖZEL AMAÇLI TAŞIT", TramerKullanimKodu : "14" },
    { KullanimTarziAdi : "TARIM MAKİNASI", TramerKullanimKodu : "15" },
    { KullanimTarziAdi : "DİĞER ARAÇLAR", TramerKullanimKodu : "20" },
    { KullanimTarziAdi : "MİNİBÜS - DOLMUŞ / HATLI(9 - 15 YOLCU)", TramerKullanimKodu : "17" },
    { KullanimTarziAdi : "OTOBUS - DOLMUŞ / HATLI(31 +)", TramerKullanimKodu : "19" },
    { KullanimTarziAdi : "OTOBÜS - DOLMUŞ / HATLI(16 - 30)", TramerKullanimKodu : "18" },
    { KullanimTarziAdi : "DOLMUŞ", TramerKullanimKodu : "16" },
]
      
export const kullanimSekliAl =[
        { TramerKullanimKodu: "01", KullanimTipAdi : "HUSUSİ OTO", KullanimTipKodu : "1" },
        { TramerKullanimKodu: "01", KullanimTipAdi : "RENT A CAR", KullanimTipKodu : "2" },
        { TramerKullanimKodu: "01", KullanimTipAdi : "JİP", KullanimTipKodu : "6" },
        { TramerKullanimKodu: "02", KullanimTipAdi : "TAKSİ", KullanimTipKodu : "3" },
            { TramerKullanimKodu: "03", KullanimTipAdi : "MİNİBÜS(SERVİS)", KullanimTipKodu : "13" },
            { TramerKullanimKodu: "03", KullanimTipAdi : "MİNİBÜS(PERSONEL)", KullanimTipKodu : "13" },
            { TramerKullanimKodu: "03", KullanimTipAdi : "MİNİBÜS(SÜRÜCÜ DAHİL 10 - 17 KOLTUK)", KullanimTipKodu : "12" },
            { TramerKullanimKodu: "04", KullanimTipAdi : "K.OTOBÜS", KullanimTipKodu : "17" },
            { TramerKullanimKodu: "04", KullanimTipAdi : "K.OTOBÜS(SERVİS)", KullanimTipKodu : "18" },
            { TramerKullanimKodu: "04", KullanimTipAdi : "K.OTOBÜS(ŞEHİRİÇİ)", KullanimTipKodu : "19" },
            { TramerKullanimKodu: "04", KullanimTipAdi : "K.OTOBÜS(TURİZM)", KullanimTipKodu : "21" },
            { TramerKullanimKodu: "05", KullanimTipAdi : "B.OTOBÜS", KullanimTipKodu : "22" },
            { TramerKullanimKodu: "05", KullanimTipAdi : "B.OTOBÜS(SERVİS)", KullanimTipKodu : "23" },
            { TramerKullanimKodu: "05", KullanimTipAdi : "B.OTOBÜS(ŞEHİRİÇİ)", KullanimTipKodu : "24" },
            { TramerKullanimKodu: "05", KullanimTipAdi : "B.OTOBÜS(TURİZM)", KullanimTipKodu : "25" },
            { TramerKullanimKodu: "06", KullanimTipAdi : "KAMYONET(AÇIK KASA)", KullanimTipKodu : "26" },
            { TramerKullanimKodu: "06", KullanimTipAdi : "KAMYONET(KAPALI KASA)", KullanimTipKodu : "27" },
            { TramerKullanimKodu: "06", KullanimTipAdi : "KAMYONET(PANEL / GLASS VAN)", KullanimTipKodu : "28" },
            { TramerKullanimKodu: "06", KullanimTipAdi : "KAMYONET(TÜPGAZ TAŞIYICI)", KullanimTipKodu : "29" },
            { TramerKullanimKodu: "06", KullanimTipAdi : "KAMYONET(AMBULANS)", KullanimTipKodu : "30" },
            { TramerKullanimKodu: "07", KullanimTipAdi : "KAMYON(AÇIK KASA)", KullanimTipKodu : "33" },
            { TramerKullanimKodu: "07", KullanimTipAdi : "KAMYON(KAPALI KASA)", KullanimTipKodu : "34" },
            { TramerKullanimKodu: "07", KullanimTipAdi : "KAMYON(TÜP TAŞIYICI)", KullanimTipKodu : "35" },
            { TramerKullanimKodu: "07", KullanimTipAdi : "KAMYON(ÇÖP)", KullanimTipKodu : "36" },
            { TramerKullanimKodu: "07", KullanimTipAdi : "KAMYON(İTFAİYE)", KullanimTipKodu : "37" },
            { TramerKullanimKodu: "07", KullanimTipAdi : "KAMYON(DAMPERLİ)", KullanimTipKodu : "38" },
            { TramerKullanimKodu: "07", KullanimTipAdi : "KAMYON(FRIGOFİRİK)", KullanimTipKodu : "39" },
            { TramerKullanimKodu: "07", KullanimTipAdi : "KAMYON(TRANSMİKSERLİ)", KullanimTipKodu : "40" },
            { TramerKullanimKodu: "07", KullanimTipAdi : "KAMYON(SİLOLU)", KullanimTipKodu : "41" },
            { TramerKullanimKodu: "07", KullanimTipAdi : "KAMYON(BETON POMPALI)", KullanimTipKodu : "42" },
            { TramerKullanimKodu: "07", KullanimTipAdi : "KAMYON(KAYA)", KullanimTipKodu : "43" },
            { TramerKullanimKodu: "07", KullanimTipAdi : "KAMYON(VİNÇLİ)", KullanimTipKodu : "44" },
            { TramerKullanimKodu: "07", KullanimTipAdi : "KAMYON(KURTARICI)", KullanimTipKodu : "45" },
            { TramerKullanimKodu: "08", KullanimTipAdi : "İŞ MAKİNASI", KullanimTipKodu : "57" },
            { TramerKullanimKodu: "08", KullanimTipAdi : "EKSKAVATÖR", KullanimTipKodu : "58" },
            { TramerKullanimKodu: "08", KullanimTipAdi : "LOADER", KullanimTipKodu : "59" },
            { TramerKullanimKodu: "08", KullanimTipAdi : "DOZER", KullanimTipKodu : "60" },
            { TramerKullanimKodu: "08", KullanimTipAdi : "SKREYPER", KullanimTipKodu : "61" },
            { TramerKullanimKodu: "08", KullanimTipAdi : "GREYDER", KullanimTipKodu : "62" },
            { TramerKullanimKodu: "08", KullanimTipAdi : "YOL SİLİNDİRİ", KullanimTipKodu : "63" },
            { TramerKullanimKodu: "08", KullanimTipAdi : "HAREKETLİ VİNÇ", KullanimTipKodu : "64" },
            { TramerKullanimKodu: "08", KullanimTipAdi : "KAPALI YER FORKLİFTİ", KullanimTipKodu : "65" },
            { TramerKullanimKodu: "08", KullanimTipAdi : "AÇIK YER FORKLİFTİ", KullanimTipKodu : "66" },
            { TramerKullanimKodu: "08", KullanimTipAdi : "HAREKETLİ KOMPRESÖR", KullanimTipKodu : "67" },
            { TramerKullanimKodu: "08", KullanimTipAdi : "HAREKETLİ TULUMBA", KullanimTipKodu : "68" },
            { TramerKullanimKodu: "08", KullanimTipAdi : "HAREKETLİ KAYNAK MAKİNESİ", KullanimTipKodu : "69" },
            { TramerKullanimKodu: "08", KullanimTipAdi : "MOBİL VİNÇ", KullanimTipKodu : "70" },
            { TramerKullanimKodu: "09", KullanimTipAdi : "TRAKTÖR", KullanimTipKodu : "54" },
            { TramerKullanimKodu: "09", KullanimTipAdi : "BİÇERDÖVER", KullanimTipKodu : "55" },
            { TramerKullanimKodu: "10", KullanimTipAdi : "RÖMORK", KullanimTipKodu : "52" },
            { TramerKullanimKodu: "10", KullanimTipAdi : "DAMPERLİ RÖMORK", KullanimTipKodu : "53" },
            { TramerKullanimKodu: "11", KullanimTipAdi : "MOTOSİKLET", KullanimTipKodu : "56" },
            { TramerKullanimKodu: "12", KullanimTipAdi : "TANKER", KullanimTipKodu : "46" },
            { TramerKullanimKodu: "12", KullanimTipAdi : "TANKER(ASİT TAŞIYICI)", KullanimTipKodu : "47" },
            { TramerKullanimKodu: "12", KullanimTipAdi : "TANKER(SU VE BENZ.MAD.TAŞ.)", KullanimTipKodu : "48" },
            { TramerKullanimKodu: "12", KullanimTipAdi : "KAMYONET(TÜPGAZ TAŞIYICI)", KullanimTipKodu : "29" },
            { TramerKullanimKodu: "12", KullanimTipAdi : "TANKER(PATLAYICI / PARLAYICI)", KullanimTipKodu : "49" },
            { TramerKullanimKodu: "13", KullanimTipAdi : "ÇEKİCİ", KullanimTipKodu : "50" },
            { TramerKullanimKodu: "13", KullanimTipAdi : "ÇEKİCİ(TANKER)", KullanimTipKodu : "51" },
            { TramerKullanimKodu: "14", KullanimTipAdi : "ÖZEL AMAÇLI TAŞIT", KullanimTipKodu : "71" },
            { TramerKullanimKodu: "17", KullanimTipAdi : "MİNİBÜS(HATLI)", KullanimTipKodu : "11" },
            { TramerKullanimKodu: "18", KullanimTipAdi : "K.OTOBÜS(HATLI)", KullanimTipKodu : "20" },
            { TramerKullanimKodu: "19", KullanimTipAdi : "B.OTOBÜS(HATLI)", KullanimTipKodu : "26" }]

export const trafikTeminatAl =[
    { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Araç Başına Maddi", Value: "1", KT: "01", Rapor: "100.000 TL" },
    { Teminat: "Teminatlar.KazaBaşınaMaddi", Name: "Kaza Başına Maddi", Value: "2", KT: "01", Rapor: "200.000 TL" },
    { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Kişi Başına Sağlık Giderleri", Value: "3", KT: "01", Rapor: "1.000.000 TL" },
    { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Kaza Başına Sağlık Giderleri", Value: "4", KT: "01", Rapor: "5.000.000 TL" },
    { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Kişi Başına Sakatlanma Ve Ölüm", Value: "5", KT: "01", Rapor: "1.000.000 TL" },
    { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Kaza Başına Sakatlanma Ve Ölüm", Value: "6", KT: "01", Rapor: "5.000.000 TL" },
    // { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Araç Başına Maddi", Value: "1", KT: "03", Rapor: "100.000" },
    // { Teminat: "Teminatlar.KazaBaşınaMaddi", Name: "Kaza Başına Maddi", Value: "2", KT: "03", Rapor: "200.000" },
    // { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Kişi Başına Sağlık Giderleri", Value: "3", KT: "03", Rapor: "1.000.000" },
    // { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Kaza Başına Sağlık Giderleri", Value: "4", KT: "03", Rapor: "10.000.000" },
    // { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Kişi Başına Sakatlanma Ve Ölüm", Value: "5", KT: "03", Rapor: "1.000.000" },
    // { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Kaza Başına Sakatlanma Ve Ölüm", Value: "6", KT: "03", Rapor: "10.000.000" },
    // { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Araç Başına Maddi", Value: "1", KT: "06", Rapor: "100.000" },
    // { Teminat: "Teminatlar.KazaBaşınaMaddi", Name: "Kaza Başına Maddi", Value: "2", KT: "06", Rapor: "200.000" },
    // { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Kişi Başına Sağlık Giderleri", Value: "3", KT: "06", Rapor: "1.000.000" },
    // { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Kaza Başına Sağlık Giderleri", Value: "4", KT: "06", Rapor: "10.000.000" },
    // { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Kişi Başına Sakatlanma Ve Ölüm", Value: "5", KT: "06", Rapor: "1.000.000" },
    // { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Kaza Başına Sakatlanma Ve Ölüm", Value: "6", KT: "06", Rapor: "10.000.000" },
    // { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Araç Başına Maddi", Value: "1", KT: "07", Rapor: "100.000" },
    // { Teminat: "Teminatlar.KazaBaşınaMaddi", Name: "Kaza Başına Maddi", Value: "2", KT: "07", Rapor: "200.000" },
    // { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Kişi Başına Sağlık Giderleri", Value: "3", KT: "07", Rapor: "1.000.000" },
    // { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Kaza Başına Sağlık Giderleri", Value: "4", KT: "07", Rapor: "10.000.000" },
    // { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Kişi Başına Sakatlanma Ve Ölüm", Value: "5", KT: "07", Rapor: "1.000.000" },
    // { Teminat: "Teminatlar.AraçBaşınaMaddi", Name: "Kaza Başına Sakatlanma Ve Ölüm", Value: "6", KT: "07", Rapor: "10.000.000" }
]