import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./Banner.css";

export default class Banner extends Component {
  render() {
    return (
        <div style={{backgroundColor: '#EBEBEB'}}>
        <Container>
          <div className='BannerContainer container'>
            <div className='BannerTitleBox'>
              <h2 className='BannerTitleBoxText'>Doğru Ürün.</h2>
              <h2 className='BannerTitleBoxText'>İyi Fiyat.</h2>
              <h2 className='BannerTitleBoxText'>7/24 Hizmet.</h2>
            </div>
            <div className='BannerContentBox'>
              <p className='BannerContentBoxText'>
              İhtiyacınızı karşılayacak en uygun fiyatlı sigorta poliçeleri turkhavaksigorta.com’da! İsterseniz internet sayfamız üzerinden, isterseniz 444 0 483 numaralı hattımız üzerinden, isterseniz de Whatsapp hattımız üzerinden istediğiniz sigorta ürününün teklifini alarak karşılaştırma yapabilir ve poliçenizi satın alabilirsiniz. 
              </p>
            </div>
            <div >
            <a className='BannerButtonBox' href="tel:4440483">
              <svg xmlns="http://www.w3.org/2000/svg"  width="55" height="57" viewBox="0 0 87.548 92.318">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="Rectangle_46" data-name="Rectangle 46" width="87.548" height="92.318" fill="none"/>
                  </clipPath>
                </defs>
                <g id="Group_74" data-name="Group 74" transform="translate(0 0)">
                  <g id="Group_73" data-name="Group 73" transform="translate(0 0)" clipPath="url(#clip-path)">
                    <path id="Path_111" data-name="Path 111" d="M34.031,39.705c2.794-2.273,3.494-6.413,2.246-10.729a19.377,19.377,0,0,0-3.616-6.789c-.34-.427-.7-.826-1.064-1.209-3.263-3.443-7.317-5.353-10.851-5.353a7.717,7.717,0,0,0-4.958,1.651l-.614.5c4.8.665,12.365,6.9,15.9,13.277l.039.067c1.913,3.475,2.528,6.7,1.831,9.473Z" transform="translate(44.204 45.514)" fill="#0a4cac"/>
                    <path id="Path_112" data-name="Path 112" d="M18.4,23.076l-.023.02.02-.016Z" transform="translate(53.526 67.217)" fill="#5a6e7e"/>
                    <path id="Path_113" data-name="Path 113" d="M20.25,24.143C24.237,20.9,23.967,13.867,19.843,7.9c-.309-.446-.634-.884-.982-1.315A17.665,17.665,0,0,0,9.164.27,11.1,11.1,0,0,0,6.907,0,7.926,7.926,0,0,0,2.849,1.056a11.132,11.132,0,0,1,4.48,2.121,33.209,33.209,0,0,1,7.611,8.4c1.319,2.019,5.114,8.338,4.594,13.151Z" transform="translate(8.299 -0.001)" fill="#0a4cac"/>
                    <path id="Path_114" data-name="Path 114" d="M71.94,88.526l-.012.012-.008,0a9.223,9.223,0,0,0,.935-.685c.227-.18.434-.372.638-.563l.023-.031a9.089,9.089,0,0,0,.747-.544c.2-.16.387-.337.575-.513,2.035-3.213,1.217-7.192-.927-10.952C70.183,68.734,62.443,62.88,58.573,62.88a2.337,2.337,0,0,0-1.491.442L55.8,64.371l-.755.614-.931.755a4.232,4.232,0,0,1-2.61,1.013,2.113,2.113,0,0,1-1.69-.72l-2.234-2.747c-1.737-3.737-5.627-9.571-10.639-15.738S26.992,36.39,23.69,33.925L21.5,31.244c-.865-1.068-.344-2.923,1.162-4.152l.278-.227.653-.532,1.2-.97,1.1-.9.141-.121c2.211-4.989-4.66-16.935-11.1-21.713A8.7,8.7,0,0,0,10.088.6a3.782,3.782,0,0,0-.947.117L8.03,1.627,7.6,1.979,6.234,3.087l-.192.157-.743.6-.47.38L3.8,5.063l.051.063C-5.055,14.348,1.938,38.968,20.1,61.292c14.689,18.066,32.34,29.273,44.29,29.273A13.637,13.637,0,0,0,71.8,88.619v0l.129-.086Z" transform="translate(-0.001 1.753)" fill="#5a6e7e"/>
                    <path id="Path_115" data-name="Path 115" d="M18.384,23.084l0,0,.016-.016Z" transform="translate(53.541 67.208)" fill="#fff"/>
                    <path id="Path_116" data-name="Path 116" d="M14.235,4.1l-.419-.11a1.486,1.486,0,0,0-1.632,1.326l-.563,5.423a1.46,1.46,0,0,0,.822,1.463A32.408,32.408,0,0,1,29.515,32.34a1.455,1.455,0,0,0,1.252,1.037l5.517.575a1.488,1.488,0,0,0,1.6-1.816A40.623,40.623,0,0,0,14.235,4.1" transform="translate(33.828 11.589)" fill="#0a4cac"/>
                    <path id="Path_117" data-name="Path 117" d="M14.691.062a1.489,1.489,0,0,0-1.636,1.323L12.5,6.722a1.466,1.466,0,0,0,.959,1.522A45.686,45.686,0,0,1,42.868,43.4a1.466,1.466,0,0,0,1.287,1.205l5.349.556a1.492,1.492,0,0,0,1.624-1.69A54.106,54.106,0,0,0,15.008.129Z" transform="translate(36.4 0.156)" fill="#5a6e7e"/>
                  </g>
                </g>
              </svg>
              <div>
                <h5 className='BannerButtonBoxText'>                
                Bizi
                </h5>
                <h5 className='BannerButtonBoxText'>
                Arayın
                </h5>
              </div>
                </a>
            </div>
          </div>
        </Container>
        </div>
    )
  }
}
