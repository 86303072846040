import React, { Component } from 'react'

export class Saglik extends Component {
  render() {
    return (
      <div>
        <p><b>Özel Sağlık Sigortası Nedir?</b></p>
        <p>Özel sağlık sigortası, hastalık ya da kaza durumlarında oluşabilecek sağlık masraflarını karşılayan ve sana özel olarak düzenlenen bir sigorta türüdür. Özel sağlık sigortası poliçesinde belirlenen şartlar kapsamında tedavin için ihtiyacın olan hizmetleri, ücretsiz ya da minimum ücret ödeyerek alabilirsin. Yani özel sağlık sigortası, normal koşullarda özel sağlık kuruluşlarına ödeyeceğin miktarın altında fiyatlarla bir sene boyunca hizmet almanı sağlar.</p>
        <p><b>Özel Sağlık Sigortası Nasıl Yaptırılır?</b></p>
        <p>Özel sağlık sigortası başvuru formunu doldurarak teklif alabilirsin. Başvurunu yaptıktan sonra uzman sigorta danışmanlarımız seni arayarak özel sağlık sigortası fiyat tekliflerini iletirler. Poliçelerin teminat detaylarını ve anlaşmalı hastaneleri uzmanlarımızdan öğrenerek kendin için en iyi özel sağlık sigortasını satın alabilirsin. İstersen 444 0 483 numaralı Sigorta Bilgi Hattımızı arayarak da özel sağlık sigortası 2022 fiyatları hakkında bilgi alabilirsin.</p>
        <p><b>Özel Sağlık Sigortası Fiyatları Ne Kadar?</b></p>
        <p>Özel sağlık sigortası fiyatları, kişisel sağlık durumuna ve poliçenin kapsadığı teminat limitlerine göre değişkenlik gösterir. Poliçenin anlaşmalı olduğu hastane ağının genişliği ve üst segment özel hastanelerde geçerli olması gibi nedenler de özel sağlık sigortası ücretlerini farklılaştırır. Bu nedenle kesin bir fiyat belirtmek doğru olmaz. Sağlık durumuna ve özel şartlarına göre en doğru teklifi almak için formu doldurup sağlık uzmanlarımızın sana ulaşmasını sağlayabilirsin.</p>
        <p><b>Özel Sağlık Sigortası Sorgulama Nasıl Yapılır?</b></p>
        <p>Özel sağlık sigortası sorgulamasını internetten yapabilirsin. E-devlet’e girip “Sağlık Sigortası Poliçe Bilgileri Sorgulama” ekranında güncel ve geçmişteki poliçe bilgilerini görebilirsin. Özel sağlık sigortanın bitiş tarihi ve poliçe numarası gibi bilgilerini sorgulama yaparak öğrenmen mümkün. Eğer özel sağlık sigortası fiyat sorgulaması yapmak istiyorsan ise yukarıdaki başvuru formuna bilgilerini girerek uzman sigorta danışmanlarımızın sana telefonla ulaşmasını sağlayabilirsin.</p>
      </div>
    )
  }
}

export default Saglik