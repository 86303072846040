import React, { Component } from 'react'

export class Trafik extends Component {
  render() {
    return (
      <div>
          <p><b>Trafik Sigortası Nedir?</b></p>
          <p>Trafik sigortası, aracınla kaza yapman durumunda karşı tarafa ve üçüncü kişilere verdiğin hasar masraflarını karşılayan zorunlu bir araç sigortasıdır. Yani karşı tarafta meydana gelebilecek maddi ve bedensel zararları teminat limitleri kapsamında ödeyerek bütçeni korur.</p>
          <p><b>Trafik Sigortası Sorgulama Nasıl Yapılır?</b></p>
          <p>turkhavaksigorta.com internet sitesinden trafik sigorta sorgulamasını tek bir ekranda yapabilir, sigorta şirketlerinin araç sigorta fiyat ve teminatlarını karşılaştırabilirsin. Araç sigortası sorgulama ve teklif adımlarında senden istenen bilgileri ilgili alanlara girerek 2 dakika gibi kısa bir süre içerisinde teklifleri görebilirsin.</p>
          <p><b>Trafik Sigortası Hesaplama Nasıl Yapılır?</b></p>
          <p>Trafik sigortası fiyatları hesaplanırken aracının yaşı, markası, modeli, trafiğe kayıtlı olduğu il gibi unsurlar dikkate alınır. Aynı şekilde yaşın ve hasarsızlık indirimin de trafik sigortası primini etkiler. Sen de araç sigorta hesaplaması yapmak için internetten teklif alabilir, aracının trafik sigortası fiyatını hemen öğrenebilirsin.</p>
          <p>Trafik sigortası teklifi aldığında seçim yapmanı daha da kolaylaştırmak için sana özel ürün önerileri sunuyoruz. Trafik sigortası fiyat ve teminatlarını uzun uzun incelemene gerek kalmadan kendine en uygun araç sigortasını anında görmeni amaçlıyoruz.</p>
          <p>Bütçe dostu bir trafik sigortası yaptırmak istiyorsan “en ucuz teklifi”, teminatların fazla olduğu bir poliçe istiyorsan “en kapsamlı teklifi”, en uygun fiyata en kapsamlı ürünü tercih etmek istiyorsan ise “önerilen teklifi” seçip online ödeme ile hızlıca sigorta güvenceni başlatabilirsin. Dilersen zorunlu trafik sigortanı 444 0 483 Sigorta Bilgi Hattımızı arayarak da satın alabilirsin.</p>
      </div>
    )
  }
}

export default Trafik