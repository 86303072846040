/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import { GET_PROPOSAL, CREATE_PROPOSAL, ADD_TO_CART, REMOVE_TO_CART, ADD_TO_BOX, REMOVE_TO_BOX, PROPOSAL_LIST, CREATE_PROPOSAL_PETITION, RESET_PROPOSAL } from "../actions/types";

const INITIAL_STATE = {
    proposal: {
        branch: 0,
        process: 0,
        vehicle: {
            plaka: "",
            tescilKod: "",
            tescilNo: "",
            markaKodu: "",
            tipKodu: "",
            modelYili: "",
            engelliAraciMi: false,
            aksesuar: 0,
            kullanimTarziKodu: "",
            kullanimSekli: "",
            yolcuSayisi: "",
            aracBedeli: "",
            aksesuarBedeli: "",
            sasiNo: "",
            motorNo: "",
            plakamYok: false
        },
        dask: {
            binaInsaYili: "",
            binaYapiTarzi: "",
            kullanimSekli: "",
            binaHasar: "",
            daskPoliceNumara: "",
            toplamKatSayisi: "",
            yuzOlcumM2: "",
            daskYenilemeMi: false,
            uavtAdresKodu: "",
            sigortaEttirenSifati: "",
            baslangicTarihi: ""
        },
        house: {
            binaInsaYili: "",
            binaYapiTarzi: "",
            kullanimSekli: "",
            binaHasar: "",
            daskPoliceNumara: "",
            toplamKatSayisi: "",
            yuzOlcumM2: "",
            daskYenilemeMi: true,
            uavtAdresKodu: "",
            sigortaEttirenSifati: "",
            bulunduguKat: ""
        },
        health: {
            height: "",
            weight: "",
            plan: "",
            network: "",
            urunID: 0,
            baslangicTarihi: "",
            bitisTarihi: ""
        },
        travelHealth: {
            plan: "",
            baslangicTarihi: "",
            bitisTarihi: "",
            ulke: "",
            isSchengen: true,
            isYurtdisi: false,
            sehir: "",
            seyahatNedeni: ""
        },
        isTss: false,
        musID: 0,
        isDainiMurtein: false,
        finansKurumKodu: "",
        bankaSubeKodu: ""
    },
    proposalID: 0,
    getProposal: {
        sirketKodu: "",
        teklifID: 0
    },
    offers: [],
    cart: [],
    box: [],
    proposalList: [],
    proposalPetition: {
        branch: 0,
        process: 0,
        otherProduct: {
            idendityNumber: "",
            birthDate: "",
            mobileNumber: "",
            email: "",
            job: "",
            isUser: true,
            requestIP: "",
            aciklama: "",
            baslangicTarihi: "",
            bitisTarihi: ""
        },
        isTalep: false
    },
    house: {
        binaInsaYili: "",
        binaYapiTarzi: "",
        kullanimSekli: "",
        binaHasar: "",
        daskPoliceNumara: "",
        toplamKatSayisi: "",
        yuzOlcumM2: 0,
        daskTabiMi: true,
        uavtAdresKodu: "",
        sigortaEttirenSifati: "",
        bulunduguKat: "",
        baslangicTarihi: "",
        esyaBedeli: 0,
        binaBedeli: 0,
        binaKullanimTarzi: ""
    },
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_PROPOSAL:
            return { ...state, proposalID: action.payload }
        case GET_PROPOSAL:
            return { ...state, offers: state.offers.concat(action.payload).sort(function (a, b) { return a.prim - b.prim }) }
        case ADD_TO_CART:
            return { ...state, cart: state.cart.concat(action.payload) }
        case ADD_TO_BOX:
            return { ...state, box: state.box.concat(action.payload) }
        case REMOVE_TO_CART:
            return { ...state, cart: state.cart.filter(r => r.bransNo !== action.payload.bransNo) }
        case REMOVE_TO_BOX:
            return { ...state, box: state.box.filter(r => r.sirketKodu !== action.payload.sirketKodu) }
        case PROPOSAL_LIST:
            return { ...state, proposalList: action.payload }
        case CREATE_PROPOSAL_PETITION:
            return { ...state, proposalPetition: action.payload }
        case RESET_PROPOSAL:
            return { ...state, offers: [], cart:[], box:[] }
        default:
            return state;
    }
}