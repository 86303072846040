/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import "./Sirketler.css";

const s1 = require('../../images/sirketler/Varlik1.jpg');
const s2 = require('../../images/sirketler/Varlik2.jpg');
const s3 = require('../../images/sirketler/Varlik3.jpg');
const s5 = require('../../images/sirketler/Varlik5.jpg');
const s6 = require('../../images/sirketler/Varlik6.jpg');
const s7 = require('../../images/sirketler/Varlik7.jpg');
const s8 = require('../../images/sirketler/Varlik8.jpg');
const s9 = require('../../images/sirketler/Varlik9.jpg');
const s10 = require('../../images/sirketler/Varlik10.jpg');
const s11 = require('../../images/sirketler/Varlik11.jpg');

export default class Sirketler extends Component {
  

  render() {
    return (
        <div className='CozumOrtaklari'>
            <Container>
                <div>
                    <h4 style={{textAlign: 'center', paddingTop:20, paddingBottom: 20}}>Sigorta Şirketleri</h4>
                    <div className='SirketImageBox'>
                      <img src={s1} alt='' className='SirketImage'/>
                      <img src={s2} alt='' className='SirketImage'/>
                      <img src={s3} alt='' className='SirketImage'/>
                      <img src={s5} alt='' className='SirketImage'/>
                      <img src={s6} alt='' className='SirketImage'/>
                      <img src={s7} alt='' className='SirketImage'/>
                      <img src={s8} alt='' className='SirketImage'/>
                      <img src={s9} alt='' className='SirketImage'/>
                      <img src={s10} alt='' className='SirketImage'/>
                      <img src={s11} alt='' className='SirketImage'/>
                    </div>
                    <marquee behavior='alternate' className='sirket-mobil'>
                      <img src={s1} alt='' className='SirketImage'/>
                      <img src={s2} alt='' className='SirketImage'/>
                      <img src={s3} alt='' className='SirketImage'/>
                      <img src={s5} alt='' className='SirketImage'/>
                      <img src={s6} alt='' className='SirketImage'/>
                      <img src={s7} alt='' className='SirketImage'/>
                      <img src={s8} alt='' className='SirketImage'/>
                      <img src={s9} alt='' className='SirketImage'/>
                      <img src={s10} alt='' className='SirketImage'/>
                      <img src={s11} alt='' className='SirketImage'/>
                    </marquee>
                </div>
            </Container>
        </div>
    );
  }
}
