import React, {Component} from 'react';
import  Bilgilerim  from './bilgilerim/Bilgilerim';
import Policelerim from './policelerim/Policelerim';
import "./Profil.css";
import Tekliflerim from './tekliflerim/Tekliflerim';
import * as actions from '../../redux/actions';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';

const user = require("../images/user.png");

class Profil extends Component {


    constructor (props) {
        super(props);
    }

    tabButton = (id) => {
        let tabs = document.getElementsByTagName('section');

        if(id === 'bilgilerim') {
            document.getElementById("btn1").className = "isActive";
            document.getElementById("btn2").className = "isPasive";
            document.getElementById("btn3").className = "isPasive";
        } else if (id === 'policelerim') {
            document.getElementById("btn1").className = "isPasive";
            document.getElementById("btn2").className = "isActive";
            document.getElementById("btn3").className = "isPasive";
        } else if (id === 'tekliflerim') {
            document.getElementById("btn1").className = "isPasive";
            document.getElementById("btn2").className = "isPasive";
            document.getElementById("btn3").className = "isActive";
        }

        for (var i = 0; i < tabs.length; i++) {
            if(tabs[i].id === id){
                tabs[i].className = "active";
            }
            else tabs[i].className = "inactive";
        }
    }

    componentDidMount(){
        let tab = sessionStorage.getItem('profil_tab');
        if (tab != null) {
            this.tabButton(tab);
            sessionStorage.removeItem('profil_tab');
        }
    }

    logOut = () => {
        this.props.userLogout();
        window.location = "/"
    }
    render() {
        return (
            <div className='container profil-flex'>
                <div className='profil-menu'>
                    <div className='profil-menu-box'>
                        <div className='profil-menu-header'>
                            <h4>Hesabım</h4>
                            <div className='profil-menu-circle'>
                            <img
                    src={user}
                    alt=""
                    style={{ width: 80, marginRight: 0 }}
                  />
                            </div>
                        </div>
                        <hr/>
                        <div className='profil-menu-content'>
                            <ul>
                                <li>
                                    <div className='isActive' id='btn1' onClick={() => this.tabButton('bilgilerim')}>
                                        {'>'} Bilgilerim
                                    </div>
                                </li>
                                <li>
                                    <div className='isPasive' id='btn2' onClick={() => this.tabButton('policelerim')}>
                                        {'>'} Poliçelerim
                                    </div>
                                </li>
                                <li>
                                    <div className='isPasive' id='btn3' onClick={() => this.tabButton('tekliflerim')}>
                                        {'>'} Tekliflerim
                                    </div>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div onClick={()=> this.logOut()}>
                                        {'>'} Çıkış
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='profil-content'>
                    <div className='profil-content-box'>
                        <section className='bilgilerim active' id='bilgilerim'>
                            <div>
                                <h4>Bilgilerim</h4>
                                <Bilgilerim/>
                            </div>
                        </section>
                        <section className='policelerim inactive' id='policelerim'>
                            <div>
                                <h4>Poliçelerim</h4>
                                <Policelerim/>
                            </div>
                        </section>
                        <section className='tekliflerim inactive' id='tekliflerim'>
                            <div>
                                <h4>Tekliflerim</h4>
                                <Tekliflerim/>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
          )
    }
}
const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
}

export default connect(mapStateToProps,actions)(Profil);