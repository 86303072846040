import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from '../home/Home';
import Kasko from '../teklif/kasko/Kasko';
import AppLayout from "../layout";
import Dask from '../teklif/dask/Dask';
import Trafik from '../teklif/trafik/Trafik';
import TamamlayiciSaglik from '../teklif/saglik/TamamlayiciSaglik';
import Seyahat from '../teklif/seyahat/Seyahat';
import Profil from '../profil/Profil';
import Konut from '../teklif/konut/Konut';
import OzelSaglik from '../teklif/ozelSaglik/OzelSaglik';
import TeklifForm from '../teklif/teklifForm/TeklifForm';
import Information from '../information/Information';
import Contact from '../contact/Contact';
import About from '../about/About';
import Blog from '../blog/Blog';
import SigortaSepeti from '../sigortasepeti/SigortaSepeti';
import Guide from '../guide/Guide';
import SatinAl from '../sigortasepeti/SatinAl';
import OfferLink from '../linkPage/OfferLink';
import BlogLink from '../linkPage/BlogLink';

const appRoutes = [
  { key: 1, path: "/", element: <Home /> },
  { key: 2, path: "/kasko", element: <Kasko /> },
  { key: 3, path: "/trafik", element: <Trafik/> },
  { key: 4, path: "/dask", element: <Dask /> },
  { key: 5, path: "/tamamlayici-saglik", element: <TamamlayiciSaglik/> },
  { key: 6, path: "/seyahat", element: <Seyahat/> },
  { key: 7, path: "/teklif-form", element: <TeklifForm/> },
  { key: 8, path: "/hesabim", element: <Profil/> },
  { key: 9, path: "/konut", element: <Konut/> },
  { key: 10, path: "/ozel-saglik", element: <OzelSaglik/> },
  { key: 11, path: "/bilgi-merkezi", element: <Information/> },
  { key: 12, path: "/iletisim", element: <Contact/> },
  { key: 13, path: "/hakkimizda", element: <About/> },
  { key: 14, path: "/bilmeniz-gerekenler", element: <Blog/> },
  { key: 15, path: "/sigorta-sepeti", element: <SigortaSepeti/> },
  { key: 16, path: "/rehber", element: <Guide/> },
  { key: 17, path: "/satin-al", element: <SatinAl/> },
  { key: 18, path: "/kampanya/:link", element: <OfferLink/>},
  { key: 19, path: "/blog/:link", element: <BlogLink/>}
]

class Pages extends Component {
  render() {
    return (
        <Router>
         <AppLayout>
          <Routes>
            {appRoutes.map((route) => (
              <Route {...route} />
            ))}
          </Routes>
         </AppLayout>
      </Router>
    )
  }
}
export default Pages;