import React, { Component } from 'react';
import { connect } from 'react-redux';
import "../profil/Profil.css";
import Dask from './dask/Dask';
import Kasko from './kasko/Kasko';
import Konut from './konut/Konut';
import Saglik from './saglik/Saglik';
import Trafik from './trafik/Trafik';
import Tss from './tss/Tss';

export class Guide extends Component {
    componentDidMount() {
        this.tabButton(this.props.query)
    }
    tabButton = (id) => {
        let tabs = document.getElementsByTagName('section');
        if(id === 'trafik') {
            document.getElementById("btn1").className = "isActive";
            document.getElementById("btn2").className = "isPasive";
            document.getElementById("btn3").className = "isPasive";
            document.getElementById("btn4").className = "isPasive";
            document.getElementById("btn5").className = "isPasive";
            document.getElementById("btn6").className = "isPasive";
        } else if (id === 'tss') {
            document.getElementById("btn1").className = "isPasive";
            document.getElementById("btn2").className = "isActive";
            document.getElementById("btn3").className = "isPasive";
            document.getElementById("btn4").className = "isPasive";
            document.getElementById("btn5").className = "isPasive";
            document.getElementById("btn6").className = "isPasive";
        } else if (id === 'kasko') {
            document.getElementById("btn1").className = "isPasive";
            document.getElementById("btn2").className = "isPasive";
            document.getElementById("btn3").className = "isActive";
            document.getElementById("btn4").className = "isPasive";
            document.getElementById("btn5").className = "isPasive";
            document.getElementById("btn6").className = "isPasive";
        } else if (id === 'dask') {
            document.getElementById("btn1").className = "isPasive";
            document.getElementById("btn2").className = "isPasive";
            document.getElementById("btn3").className = "isPasive";
            document.getElementById("btn4").className = "isActive";
            document.getElementById("btn5").className = "isPasive";
            document.getElementById("btn6").className = "isPasive";
        } else if (id === 'konut') {
            document.getElementById("btn1").className = "isPasive";
            document.getElementById("btn2").className = "isPasive";
            document.getElementById("btn3").className = "isPasive";
            document.getElementById("btn4").className = "isPasive";
            document.getElementById("btn5").className = "isActive";
            document.getElementById("btn6").className = "isPasive";
        } else if (id === 'saglik') {
            document.getElementById("btn1").className = "isPasive";
            document.getElementById("btn2").className = "isPasive";
            document.getElementById("btn3").className = "isPasive";
            document.getElementById("btn4").className = "isPasive";
            document.getElementById("btn5").className = "isPasive";
            document.getElementById("btn6").className = "isActive";
        }

        for (var i = 0; i < tabs.length; i++) {
            if(tabs[i].id === id){
                tabs[i].className = "active";
            }
            else tabs[i].className = "inactive";
        }
    }
  render() {
    return (
        <div className='container profil-flex'>
        <div className='info-menu'>
            <div className='profil-menu-box'>
                <div className='profil-menu-header'>
                    <h4>Rehber</h4>
                </div>
                <hr/>
                <div className='profil-menu-content'>
                    <ul>
                        <li>
                            <div className='isActive' id='btn1' onClick={() => this.tabButton('trafik')}>
                                {'>'} Zorunlu Trafik Sigortası Nedir?
                            </div>
                        </li>
                        <li>
                            <div className='isPasive' id='btn3' onClick={() => this.tabButton('kasko')}>
                                {'>'} Araç Kasko Sigortası Nedir?
                            </div>
                        </li>
                        <li>
                            <div className='isPasive' id='btn4' onClick={() => this.tabButton('dask')}>
                                {'>'} DASK Sigortası Nedir?
                            </div>
                        </li>
                        <li>
                            <div className='isPasive' id='btn5' onClick={() => this.tabButton('konut')}>
                                {'>'} Konut Sigortası Nedir?
                            </div>
                        </li>
                        <li>
                            <div className='isPasive' id='btn2' onClick={() => this.tabButton('tss')}>
                                {'>'} Tamamlayıcı Sağlık Sigortası Nedir?
                            </div>
                        </li>
                        <li>
                            <div className='isPasive' id='btn6' onClick={() => this.tabButton('saglik')}>
                                {'>'} Özel Sağlık Sigortası Nedir?
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className='info-content'>
            <div className='profil-content-box'>
                <section className='trafik active' id='trafik'>
                    <div>
                        <h2>Zorunlu Trafik Sigortası</h2>
                        <Trafik/>
                    </div>
                </section>
                <section className='tss inactive' id='tss'>
                    <div>
                        <h2>Tamamlayıcı Sağlık Sigortası</h2>
                        <Tss/>
                    </div>
                </section>
                <section className='kasko inactive' id='kasko'>
                    <div>
                        <h2>Araç Kasko Sigortası</h2>
                        <Kasko/>
                    </div>
                </section>
                <section className='dask inactive' id='dask'>
                    <div>
                        <h2>DASK Sigortası</h2>
                        <Dask/>
                    </div>
                </section>
                <section className='konut inactive' id='konut'>
                    <div>
                        <h2>Konut Sigortası</h2>
                        <Konut/>
                    </div>
                </section>
                <section className='saglik inactive' id='saglik'>
                    <div>
                        <h2>Özel Sağlık Sigortası</h2>
                        <Saglik/>
                    </div>
                </section>
            </div>
        </div>
    </div>
    )
  }
}



const mapStateToProps = state => {
    let query = window.location.search.substring(7);
    return {
        query,
    }
}

export default connect(mapStateToProps, {}) (Guide);