/* eslint-disable import/no-anonymous-default-export */
import { GET_ARAC_BEDELI, GET_LIST_MARKA, GET_BY_MARKA_AND_MODEL, GET_IL_LIST, GET_ILCE_LIST, GET_ULKE_LIST } from "../actions/types";

const INITIAL_STATE = {
    markalar: [],
    tipler: [],
    aracBedeli:'',
    iller: [],
    ilceler: [],
    ulkeler: [],
}

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_LIST_MARKA:
            return {...state, markalar: action.payload}
        case GET_BY_MARKA_AND_MODEL:
            return {...state, tipler: action.payload}
        case GET_IL_LIST:
            return {...state, iller: action.payload}
        case GET_ILCE_LIST:
            return {...state, ilceler: action.payload}
        case GET_ARAC_BEDELI:
            return {...state, aracBedeli: action.payload}
        case GET_ULKE_LIST:
            return {...state, ulkeler: action.payload}
        default:
            return state;
    }
}