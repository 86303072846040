/* eslint-disable import/no-anonymous-default-export */
import { GET_BLOG_LIST, GET_SLIDE_LIST, GET_CONTENT_LIST } from "../actions/types";

const INITIAL_STATE = {
    blogList: [],
    slideList: [],
    contentList: []
}

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_BLOG_LIST:
            return {...state, blogList: action.payload}
        case GET_SLIDE_LIST:
            return {...state, slideList: action.payload}
        case GET_CONTENT_LIST:
            return {...state, contentList: action.payload}
        default:
            return state;
    }
}