import React, { Component } from 'react';
import "./TopHeader.css";
import { Link } from 'react-router-dom';

const email = require('../../images/icon/email.png');
const whatsapp = require('../../images/icon/whatsapp.png');
const phone = require('../../images/icon/phone.png');

export default class TopHeader extends Component {
  render() {
    return (
      <div className='top-bar'>
          <ul className='container-fluid TopFlex' >
              <li >
                  <a className='TopBarButton' href="mailto:info@turkhavaksigorta.com">
                  <img src={email} alt="Email" className='TopBarImage'/>
                    info@turkhavaksigorta.com
          </a>
              </li>
              <li>
              <a className='TopBarButton' target='_blank' href="https://wa.me/905331234188">
              <img src={whatsapp} alt="Whatsapp" className='TopBarImage' style={{width: 20}}/>
              0533 1234 188
          </a>
              </li>
              <li>
              <a className='TopBarButton' href="tel:4440483">
              <img src={phone} alt="Phone" className='TopBarImage' style={{width: 20}}/>
                    444 0 483
          </a>
              </li>
          </ul>
      </div>
    )
  }
}
