import React, { Component } from 'react';
import './Contact.css'
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Button } from "react-bootstrap";
import Modal from "react-modal";
import * as actions from "../../redux/actions";
import { connect } from "react-redux";

const mobilePhone = require('../images/icon/iphone.png');
const phone = require('../images/icon/phoneS.png');
const whatsapp = require('../images/icon/whatsappS.png');
const address = require('../images/icon/pin.png');
const email = require('../images/icon/mail.png');

const App = () => {
  function onChange(value) {
   
  }
}

class Contact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hataModalIsOpen: false,
      hata: "",
      hatabaslik: "",
      adSoyad: "",
      telefon: "",
      mail: "",
      konu:""
    };
  }

  openHataModal = (baslik,problem) => {
    this.setState({ hataModalIsOpen: true });
    this.setState({ hata: problem });
    this.setState({ hatabaslik: baslik });
  };

  closeModal = () => {
    this.setState({
      hataModalIsOpen: false,
    });
  };

  isNumericInput = (event) => {
    const key = event.keyCode;
    return (
      (key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };

  isModifierKey = (event) => {
    const key = event.keyCode;
    return (
      event.shiftKey === true ||
      key === 35 ||
      key === 36 || // Allow Shift, Home, End
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 46 || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      // Allow Ctrl/Command + A,C,V,X,Z
      ((event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
    );
  };

  enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!this.isNumericInput(event) && !this.isModifierKey(event)) {
      event.preventDefault();
    }
  };

  formatToPhone = (event) => {
    if (this.isModifierKey(event)) {
      return;
    }

    const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    if (input.length === 1 && input === "0") {
      event.target.value = "";
      return;
    }
    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      event.target.value = `(${areaCode}) ${middle} - ${last}`;
    } else if (input.length > 3) {
      event.target.value = `(${areaCode}) ${middle}`;
    } else if (input.length > 0) {
      event.target.value = `(${areaCode}`;
    }
  };

  sendForm = () => {

    let problem = "";
    if (this.state.adSoyad == "") {
      problem = problem + "Ad Soyadı boş geçilemez? </br>";
    }
    if (
      this.state.telefon == "" ||
      this.state.telefon.length < 16 ||
      this.state.telefon.length > 16
    ) {
      problem = problem + "Telefon numaranız hatalı? </br>"; 
    }
    if (this.state.mail == "") {
      problem = problem + "Mail adresi boş geçilemez? </br>";
    }
    if (this.state.konu == "") {
      problem = problem + "Konu boş geçilemez? </br>";
    }
    
    if (problem != "") {
      this.openHataModal("Eksik Bilgi!",problem);
    } else {

    let mail = {
      email: this.state.mail,
      body: "AdSoyad: " + this.state.adSoyad + "\n\n " + "Telefon: " + this.state.telefon.replace(" ", "")
      .replace("(", "")
      .replace(")", "")
      .replace("-", "")
      .replace("  ", "") + "\n\n " + "Konu: " + this.state.konu,
      subject: "İletişim Formu",
      isSuccess: false
    };
    this.props.customerSendMail(mail);

          this.setState = {
            adSoyad: "",
            telefon: "",
            mail: "",
            konu:""
          };
        
          this.openHataModal("Bilgi!","Form başarılı gönderilmiştir.");
    }
  };

  render() {
    return (
      <div>
         <Modal
          isOpen={this.state.hataModalIsOpen}
          onRequestClose={() => this.closeModal()}
          className="form-box"
          // style={customStyles}
          ariaHideApp={false}
        >
          <div className="hata-modal-container">

            <h4>
              <b>{this.state.hatabaslik}</b>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: this.state.hata }}>
         </div>
         <br></br>
         <Button onClick={() => this.closeModal()}>Tamam</Button>
          </div>
        </Modal>

      <div className='container-fluid'>
        <div className='map-container'>

        <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12047.672688992705!2d28.82377!3d40.9832726!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe517c63d98440701!2sT%C3%BCrk%20Havak%20Sigorta!5e0!3m2!1str!2str!4v1668694323790!5m2!1str!2str"
              width="100%"
              height="300"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
          </div>
        <div className='contact-container container'>
          <div className='contact-info-box'>
            <div className='contact-box'>
              <h5 className='contact-title'>İletişim</h5>
              <div className='info-box-container'>
                <div className='info-box'>
                  <img src={address} alt="" className='info-box-icon' />
                  <div className='info-box-line'></div>
                  <span className='info-box-text'>THY A.O Genel Müdürlük Binası Yanı<br/>Atatürk Havalimanı Yeşilköy, Bakırköy, İstanbul</span>
                </div>
                <div className='info-box'>
                  <img src={phone} alt="" className='info-box-icon' />
                  <div className='info-box-line'></div>
                  <span className='info-box-text'>444 0 483 / Dahili 3</span>
                </div>
                <div className='info-box'>
                    <img src={mobilePhone} alt="" className='info-box-icon' />
                    <img src={whatsapp} alt="" className='info-box-icon' />
                <div>
                  <span className='info-box-text'>0533 1234 188</span><br/>
                  <span className='info-box-text'>0533 1234 189</span><br/>
                  <span className='info-box-text'>0533 1234 188</span>
                </div>
                </div>
                <div className='info-box'>
                  <img src={email} alt="" className='info-box-icon' />
                  <div className='info-box-line'></div>
                  <span className='info-box-text'>info@turkhavaksigorta.com</span>
                </div>
              </div>
            </div>
            <br></br>
            <div className='contact-box'>
              <h5 className='contact-title'>Şirket Bilgileri</h5>
              <div className='info-box-container' style={{marginTop:20}}>
                <div className='company-box'>
                <div className='info-box-title'>Ünvan</div>
                  <span className='info-box-text'>Türk Havak Sigorta Acenteliği</span>
                </div>
                <div className='company-box'>
                <div className='info-box-title'>Ticaret Sicil No</div>
                  <span className='info-box-text'>935717</span>
                </div>
                <div className='company-box'>
                <div className='info-box-title'>Vergi Dairesi</div>
                  <span className='info-box-text'>Bakırköy</span>
                </div>
                <div className='company-box'>
                <div className='info-box-title'>Vergi No</div>
                  <span className='info-box-text'>8790654035</span>
                </div>
                <div className='company-box'>
                <div className='info-box-title'>Mersis</div>
                  <span className='info-box-text'>0879065403500018</span>
                </div>
              </div>
            </div>
          </div>
          <div className='contact-form-box'>
          <h5 className='contact-title' >İletişim Formu</h5>
          <div className='contact-bilgi-box'>
              <div className='title-box'>
                <label className='info-box-title'>Ad Soyad</label>
                </div>
                <div className='text-box'>
                    <input type="text" 
                    className="input-box"
                    value={this.state.adSoyad}
                    onChange={(event) =>
                       this.setState({ adSoyad: event.target.value })
                    }/>
                </div>
              </div>
              <div className='contact-bilgi-box'>
              <div className='title-box'>
                <label className='info-box-title'>Telefon</label>
                </div>
                <div className='text-box'>
                <input
                      type="phone"
                      className="input-box"
                      required
                      placeholder="(5__) ___ ____"
                      onKeyDown={(event) => this.enforceFormat(event)}
                      onKeyUp={(event) => this.formatToPhone(event)}
                      value={this.state.telefon}
                      onChange={(event) =>
                        this.setState({ telefon: event.target.value })
                     }/>
                </div>
              </div>
              <div className='contact-bilgi-box'>
              <div className='title-box'>
                <label className='info-box-title'>Mail</label>
                </div>
                <div className='text-box'>
                    <input type="text" className="input-box"  value={this.state.mail}
                    onChange={(event) =>
                      this.setState({ mail: event.target.value })
                   }/>
                </div>
              </div>
              <div className='contact-bilgi-box'>
              <div className='title-box'>
                <label className='info-box-title'>Konu</label>
                </div>
                <div className='text-box'>
                    <textarea className="input-box"
                    style={{minHeight:100}}
                    rows="5"
                    cols="60"
                    value={this.state.konu}
                    onChange={(event) =>
                      this.setState({ konu: event.target.value })
                   }/>
                  </div>
              </div>
              <div className="App google-captcha" >
      <ReCAPTCHA
        sitekey="6Lc-VkUjAAAAANHCxH2aaQNiC5JeV7z6WVMyTWNY"
        onChange={this.onChange}
      />
      <br></br>
         <Button onClick={() => this.sendForm()}>Gönder</Button>
    </div>
            </div>
        </div>
      </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    mail: state.mail,
  };
};

export default connect(mapStateToProps, actions)(Contact);