/* eslint-disable import/no-anonymous-default-export */
import {USER_REGISTER, USER_LOGIN, USER_ERROR, USER_LOGOUT,USER_NAME,USER_SEND_SMS,USER_CHANGE_PASSWORD} from '../actions/types';

const INITIAL_STATE = {
    token:null,
    username:null,
    sms:null,
    pass:null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_REGISTER:
            return {...state, token: action.payload}
        case USER_LOGIN:
            return {...state, token: action.payload}
        case USER_ERROR:
            return {...state, token: null}
        case USER_LOGOUT:
            return {...state, token: null}
        case USER_NAME:
            return {...state, username: action.payload}
        case USER_SEND_SMS:
            return {...state, sms: action.payload}
        case USER_CHANGE_PASSWORD:
            return {...state, pass: action.payload}
        default:
            return state;
    }
}