import axios from "axios";
import { API_URL } from "./definitions";
import {USER_REGISTER, USER_LOGIN, USER_ERROR, USER_LOGOUT,USER_NAME,USER_SEND_SMS,USER_CHANGE_PASSWORD} from './types';

export const  userRegister = (user) => {
    return (dispatch) => {
        let request = {
            'email': user.email,
            'tc': user.tc,
            'phoneNumber': user.phoneNumber,
            'password': user.password,
            'firstName': user.firstName,
            'lastName': user.lastName,
            'dogumTarihi': user.dogumTarihi,
            'smsCode': user.smsCode,
            'userName': user.userName,
            'vergiNo':user.vergiNo
        }
        axios.post(API_URL + '/api/Auth/register', request)
        .then(response => {
            sessionStorage.removeItem('user')
            dispatch ({
                type: USER_REGISTER,
                payload: response.data
            })
        }).catch(error => {
            user.Message = error.response.data.Message;
            dispatch({
                type: USER_ERROR,
                payload: error
            })
         })
    }
}

export const  userLogin = (user) => {
    return (dispatch) => {
        axios.post(API_URL + '/api/Auth/login', {
            phoneNumber: user.phoneNumber,
            password: user.password,
        })
        .then(response => {
            user.token = response.data;
            sessionStorage.setItem('user', JSON.stringify(response.data));
            dispatch ({
                type: USER_LOGIN,
                payload: response.data
            })
        }).catch(error => {
            user.errorMessage = error.response.data
            dispatch({
                type: USER_ERROR,
                payload: error
            })
         })
    }
}

export const userLogout = () => {
    return (dispatch) => {
        sessionStorage.removeItem('user')
        dispatch({
            type: USER_LOGOUT
        })
    } 
}

export const getUserName = (user) => {
    return async (dispatch) => {
       await axios.get(API_URL + '/api/Auth/GetUserName', {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then(response=> {
        sessionStorage.setItem('username', JSON.stringify(response.data));
        dispatch({
              type: USER_NAME,
              payload: response.data
          })
        }).catch(error => {
            user.errorMessage = error.response.data
            dispatch({
                type: USER_ERROR,
                payload: error
            })
         })
    }
}

export const forgetPasswordSms = (user) => {
    return (dispatch) => {
        axios.post(API_URL + '/api/Auth/ForgetPasswordSms', user)
        .then(response => {
            dispatch({
                type: USER_SEND_SMS,
                payload: response.data
            })
        })
    }
}

export const changePassword = (user) => {
    return (dispatch) => {
        axios.post(API_URL + '/api/Auth/ChangePassword', user)
        .then(response => {
            dispatch({
                type: USER_CHANGE_PASSWORD,
                payload: response.data
            })
        })
    }
}