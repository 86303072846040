import React, { Component } from 'react'

class AcikRizaMetni extends Component {
  render() {
    return (
      <div>
          <p>6698 sayılı “Kişisel Verilerin Korunması Kanunu” ve “Kişisel Sağlık Verilerinin İşlenmesi ve Mahremiyetinin Sağlanması Hakkında Yönetmelik” gereğince, kişisel verilerimin, özel nitelikli kişisel verilerimin, sağlık verilerimin “ TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ.” ‘ye tarafımca sözlü/ yazılı ve/veya elektronik ortamda verilen kimliğimi belirleyen veya belirlemeye yarayanlar da dahil olmak üzere her türlü kişisel verimin, özel nitelikli kişisel verimin, kişisel sağlık verilerimin “ TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ.”  “ tarafından işlenmesine, iş gereği ile sağlık hizmeti, asistan hizmeti ve fırsat avantajı sağlayan şirket bilgilendirmeleri için verebilmesi ve bu hizmetlerin planlaması amacıyla, ilgili mevzuatlar kapsamında paylaşımın gerekli olduğu diğer kişi ve kuruluşlar ile paylaşılmasına; kişisel veriler, özel nitelikli kişisel veriler, sağlık verilerimin 6698 sayılı “Kişisel Verilerin Korunması Kanunu” nda ve “Kişisel Sağlık Verilerinin İşlenmesi ve Mahremiyetinin Sağlanması Hakkında Yönetmelik” te tanımlanan kapsamda aşağıda detayları verilen kişisel ve özel nitelikli kişisel verilerin işlenmesine,</p>
          <p> ¬ Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; kişinin kimliğine dair bilgilerin bulunduğu verilerdir; ad- soyad, T.C. Kimlik numarası, uyruk bilgisi, anne adı baba adı, doğum yeri, doğum tarihi, cinsiyet gibi bilgileri içeren ehliyet, nüfus cüzdanı ve pasaport gibi belgeler ile vergi numarası, SGK numarası, kredi kart bilgileri, imza bilgileri, taşıt plakası vb. bilgiler, </p>
          <p>¬ Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; telefon numarası, adres, e-mail adresi, faks numarası, IP adresi gibi bilgiler,</p>
          <p> ¬ Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; fiziksel mekâna girişte, fiziksel mekanın içerisinde kalış sırasında alınan kayıtlar ve belgelere ilişkin kişisel veriler; kamera kayıtları, parmak izi kayıtları ve güvenlik noktasında alınan kayıtlar,</p>
          <p> ¬ Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; fotoğraf ve kamera kayıtları (Fiziksel Mekân Güvenlik Bilgisi kapsamında giren kayıtlar hariç), kişisel veri içeren belgelerin kopyası niteliğindeki belgelerde yer alan veriler,</p>
          <p> ¬ Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; Kişisel Verilerin Korunması Kanunu’ nun 6. Maddesinde belirtilen veriler (örn. Kan grubu da dâhil sağlık verileri, biyometrik veriler vb.)</p>
          <p> ¬ Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ.” ’ ye yöneltilmiş olan her türlü talep veya şikâyetin alınması ve değerlendirilmesine ilişkin kişisel veriler, TÜRKHAVAK SİGORTA ACENTELİĞİ LTD.ŞTİ.”  Kişisel Verilerin Korunması ve İşlenmesi Hakkında ayrıca web sitesinde de bulunan Bilgilendirme metnini ve haklarımı okudum ve anladım.</p>
      </div>
    )
  }
}

export default AcikRizaMetni