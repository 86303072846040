import React, { Component } from 'react'

export default class TeklifBanner extends Component {
  render() {
    return (
        <div className="diger-banner">
            <div className="container seyahat-banner-content">
                {/* <div className="seyahat-banner-title">
                    <h1>Seyahat Sigortanı</h1>
                    <h1>2 dakikada yapalım</h1>
                </div>
                <div className="seyahat-banner-title">
                    <h1>Yıllardır</h1>
                    <h1>Güven Sağlıyoruz</h1>
                </div> */}
            </div>
        </div>
    )
  }
}
