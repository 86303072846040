import { CREATE_POLICY, GET_PRINT, POLICY_LIST } from "../actions/types";

const INITIAL_STATE = {
    policy: {
        teklifID: 0,
        teklifDetayID: 0,
        taksitSayisi: 0,
        krediKartiBilgisi: {
          kartSahibiAdi: "",
          kartSahibiSoyadi: "",
          kartNumarasi: "",
          ay: "",
          yil: "",
          cvv: ""
        }
    },
    print: {},
    policyList: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_POLICY:
            return {...state, policy: action.payload}
        case GET_PRINT:
            return {...state, print: action.payload}
        case POLICY_LIST:
            return {...state, policyList: action.payload}
        default:
            return state;
    }
}