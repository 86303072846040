import React, { Component } from 'react';
import "../profil/Profil.css";
import { connect } from 'react-redux';
import AcikRizaMetni from './acikRiza/AcikRizaMetni';
import Gizlilik from './gizlilik/Gizlilik';
import Kvkk from './kvkk/Kvkk';
import MesafeliSatis from './mesafeliSatis/MesafeliSatis';

class Information extends Component {
    componentDidMount() {
        let query = window.location.search.substring(7);
        this.tabButton(query)
    }
    tabButton = (id) => {
        let tabs = document.getElementsByTagName('section');

        if(id === 'acik-riza-metni') {
            document.getElementById("btn1").className = "isActive";
            document.getElementById("btn2").className = "isPasive";
            document.getElementById("btn3").className = "isPasive";
            document.getElementById("btn4").className = "isPasive";
        } else if (id === 'kvkk') {
            document.getElementById("btn1").className = "isPasive";
            document.getElementById("btn2").className = "isActive";
            document.getElementById("btn3").className = "isPasive";
            document.getElementById("btn4").className = "isPasive";
        } else if (id === 'gizlilik-cerez-korumasi') {
            document.getElementById("btn1").className = "isPasive";
            document.getElementById("btn2").className = "isPasive";
            document.getElementById("btn3").className = "isActive";
            document.getElementById("btn4").className = "isPasive";
        } else if (id === 'mesafeli-satis-sozlesmesi') {
            document.getElementById("btn1").className = "isPasive";
            document.getElementById("btn2").className = "isPasive";
            document.getElementById("btn3").className = "isPasive";
            document.getElementById("btn4").className = "isActive";
        }

        for (var i = 0; i < tabs.length; i++) {
            if(tabs[i].id === id){
                tabs[i].className = "active";
            }
            else tabs[i].className = "inactive";
        }
    }
  render() {
    return (
        <div className='container profil-flex'>
        <div className='info-menu'>
            <div className='profil-menu-box'>
                <div className='profil-menu-header'>
                    <h4>Bilgi Merkezi</h4>
                </div>
                <hr/>
                <div className='profil-menu-content'>
                    <ul>
                        <li>
                            <div className='isActive' id='btn1' onClick={() => this.tabButton('acik-riza-metni')}>
                                {'>'} Açık Rıza Metni
                            </div>
                        </li>
                        <li>
                            <div className='isPasive' id='btn2' onClick={() => this.tabButton('kvkk')}>
                                {'>'} Kişisel Verilerin Korunması Kanunu
                            </div>
                        </li>
                        <li>
                            <div className='isPasive' id='btn3' onClick={() => this.tabButton('gizlilik-cerez-korumasi')}>
                                {'>'} Gizlilik ve Çerez Koruması
                            </div>
                        </li>
                        <li>
                            <div className='isPasive' id='btn4' onClick={() => this.tabButton('mesafeli-satis-sozlesmesi')}>
                                {'>'} Mesafeli Satış Sözleşmesi
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className='info-content'>
            <div className='profil-content-box'>
                <section className='acik-riza-metni active' id='acik-riza-metni'>
                    <div>
                        <h4>Açık Rıza Metni</h4>
                        <AcikRizaMetni/>
                    </div>
                </section>
                <section className='kvkk inactive' id='kvkk'>
                    <div>
                        <h4>Kişisel Verilerin Korunması Kanunu</h4>
                        <Kvkk/>
                    </div>
                </section>
                <section className='gizlilik-cerez-korumasi inactive' id='gizlilik-cerez-korumasi'>
                    <div>
                        <h4>Gizlilik ve Çerez Koruması</h4>
                        <Gizlilik/>
                    </div>
                </section>
                <section className='mesafeli-satis-sozlesmesi inactive' id='mesafeli-satis-sozlesmesi'>
                    <div>
                        <h4>Mesafeli Satış Sözleşmesi</h4>
                        <MesafeliSatis/>
                    </div>
                </section>
            </div>
        </div>
    </div>
    )
  }
}

export default Information;
