import axios from 'axios';
import { API_URL } from "./definitions";
import { CREATE_POLICY, GET_PRINT, POLICY_LIST } from './types';

export const createPolicy = (policy) => {
    return async (dispatch) => {
        await axios.post(API_URL + '/api/Policy/CreatePolicy', policy)
        .then(response => {
            policy = response.data
            dispatch({
                type: CREATE_POLICY,
                payload: policy,
            })
        }).catch(error => {
            policy = error.response.data
            dispatch({
                type: CREATE_POLICY,
                payload: policy
            })
         })
    }
}

export const getPrint = (teklifDetay) => {
    return async (dispatch) => {
        await axios.post( API_URL + '/api/Print/GetPrint', {
            teklifDetayID: teklifDetay.teklifDetayID,
            sirketKodu: teklifDetay.sirketKodu,
            policeNo: teklifDetay.policeNo,
            yenilemeNo: teklifDetay.yenilemeNo,
            zeyilNo: teklifDetay.zeyilNo
        })
        .then(response => {

            dispatch({
                type: GET_PRINT,
                payload:  response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: GET_PRINT,
                payload: error
            })
         })
    }
}

export const policyList = (user) => {
    return async (dispatch) => {
       await axios.get(API_URL + '/api/Policy/PolicyList', {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then(response=> {
          dispatch({
              type: POLICY_LIST,
              payload: response.data
          })
      })
    }
}

