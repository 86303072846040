import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "./Urun.css";

const trafik = require('../../images/icon/trafik.png');
const kasko = require('../../images/icon/kasko.png');
const dask = require('../../images/icon/dask.png');
const tsaglik = require('../../images/icon/tsaglik.png');
const seyahat = require('../../images/icon/seyahat.png');

export default class Urun extends Component {
  render() {
    return (
      <div className="container">
          <div className='UrunContainer'>
              <div className='UrunTitleContainer'>
                <div>
                  <h2 className='UrunTitle'>Hızlı Teklif Al</h2>
                  <h2 className='UrunTitle'>Hemen Poliçeni Al</h2>
                </div>
                <div className='shake'>
                <svg xmlns="http://www.w3.org/2000/svg" width="65" height="63" viewBox="0 0 69.03 67.776" style={{marginRight: 10, marginLeft: 15}}>
                    <g id="Group_49" data-name="Group 49" transform="translate(0 0)">
                      <path id="Path_61" data-name="Path 61" d="M30.413,0,47.88,33.887,30.413,67.776H56.031L73.5,33.887,56.031,0Z" transform="translate(-4.47 0)" fill="#0a4cac"/>
                      <path id="Path_62" data-name="Path 62" d="M15.118,0,32.585,33.887,15.118,67.776H23.48L40.947,33.887,23.48,0Z" transform="translate(-1.418 0)" fill="#0a4cac"/>
                      <path id="Path_63" data-name="Path 63" d="M0,0,17.467,33.887,0,67.776H8.362L25.83,33.887,8.362,0Z" transform="translate(0 0)" fill="#0a4cac"/>
                    </g>
                </svg> 
                </div>
              </div>
            <div className='UrunButtonContainer'>
            <div className='UrunButton resUrunButton'>
                <Link to="/trafik">
                  <img src={trafik} alt='Tafik' className='UrunButtonImage'/>
                  <p className='UrunText'>Trafik</p>
                </Link>
              </div>
              <div className='UrunButton resUrunButton'>
               <Link to="/kasko">
                  <img src={kasko} alt='Kasko' className='UrunButtonImage'/>
                  <p className='UrunText'>Kasko</p>
               </Link>
              </div>
              <div className='UrunButton resUrunButton'>
                <Link to="/dask">
                  <img src={dask} alt='Dask' className='UrunButtonImage'/>
                  <p className='UrunText'>Dask</p>
                </Link>
              </div>
              <div className='UrunButton resUrunButton'>
                <Link to="/konut">
                  <img src={dask} alt='Konut' className='UrunButtonImage'/>
                  <p className='UrunText'>Konut</p>
                </Link>
              </div>
              <div className='UrunButton resUrunButton'>
                <Link to="/tamamlayici-saglik">
                  <img src={tsaglik} alt='Tamamlayıcı Sağlık' className='UrunButtonImage'/>
                  <p className='UrunText'><span>Tamamlayıcı</span> Sağlık</p>
                </Link>
              </div>
              <div className='UrunButton'>
                <Link to="/seyahat">
                  <img src={seyahat} alt='Seyahat' className='UrunButtonImage'/>
                  <p className='UrunText'>Seyahat</p>
                </Link>
              </div>
            </div>
          </div>
      </div>
    )
  }
}
