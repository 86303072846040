import axios from 'axios';
import { API_URL } from "./definitions";
import { CREATE_PROPOSAL, GET_PROPOSAL, ADD_TO_CART, REMOVE_TO_CART, ADD_TO_BOX,REMOVE_TO_BOX, PROPOSAL_LIST, CREATE_PROPOSAL_PETITION ,RESET_PROPOSAL} from './types';

export const createProposal = (proposal) => {
    return async (dispatch) => {
        await axios.post(API_URL + '/api/Proposal/CreateProposal', proposal)
        .then(response => {
            dispatch({
                type: CREATE_PROPOSAL,
                payload: response.data,
            })
        }).catch(error => {
            proposal.hataLog=error.response.data.message;
            dispatch({
                type: CREATE_PROPOSAL,
                payload: proposal
            })
         })
    }
}

export const getProposal = (teklif) => {
    return async (dispatch) => {
        await axios.post(API_URL + '/api/Proposal/GetProposal', teklif)
        .then(response => {
            dispatch({
                type: GET_PROPOSAL,
                payload: response.data,
            })
        })
    }
}

export const addToCart = (offer) => {
    return (dispatch) => {
        dispatch ({
            type: ADD_TO_CART,
            payload: offer,
        })
    }
}

export const addToBox = (offer) => {
    return (dispatch) => {
        dispatch ({
            type: ADD_TO_BOX,
            payload: offer,
        })
    }
}

export const removeToCart = (offer) => {
    return {
        type: REMOVE_TO_CART,
        payload: '',
    }
}

export const removeToBox = (offer) => {
    return {
        type: REMOVE_TO_BOX,
        payload: '',
    }
}

export const proposalList = (user) => {
    return async (dispatch) => {
       await axios.get(API_URL + '/api/Proposal/ProposalList', {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then(response=> {
          dispatch({
              type: PROPOSAL_LIST,
              payload: response.data
          })
      })
    }
}

export const createProposalPetition = (proposal) => {
    return async (dispatch) => {
        await axios.post(API_URL + '/api/Proposal/CreateProposalPetition', proposal)
        .then(response => {
            dispatch({
                type: CREATE_PROPOSAL_PETITION,
                payload: response.data
            })
        })
    }
}

export const resetProposal = () => {

    return async (dispatch) => {
        dispatch({
            type: RESET_PROPOSAL,
            payload: ''
        })
    }
    
}