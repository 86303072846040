import React, { Component } from 'react';
import '../teklif/kasko/Kasko.css';

class AboutBanner extends Component {
  render() {
    return (
        <div className="kasko-banner">
            <div className="container kasko-banner-content">
                <div className="kasko-banner-title">
                   {/*  <h1>Daskınızı</h1>
                    <h1>2 dakikada yapalım</h1> */}
                </div>
                <div className="kasko-banner-title">
                    {/* <h1>Yıllardır</h1>
                    <h1>Güven Sağlıyoruz</h1> */}
                </div>
            </div>
        </div>
    )
  }
}

export default AboutBanner