import React, { Component } from 'react';
import * as actions from '../../../redux/actions';
import {connect} from 'react-redux';
import Pagination from "react-pagination-js";
import Modal from "react-modal";
import "react-pagination-js/dist/styles.css";

const sirketImgURL = "https://web2c.biz/sirket/";
const wait2 = require("../../images/turkhavak.gif");

const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

class Policelerim extends Component {
    state={
        yukleniyor: false,
        currentPage: 1,
        data: [],
        sayfaSayisi: 0,
    }

    async componentDidMount(){
       await  this.props.policyList(this.props.auth.token);
        this.changeCurrentPage(1)
    }

    changeCurrentPage = numPage => {
        this.setState({ currentPage: numPage });
        let a = this.props.policy.policyList;
        var data = a.slice((numPage - 1) * 4, numPage * 4);
        this.setState({data: data});
    };

    openBase64NewTab(policeNo, sirketKodu){
        let police = {
            teklifDetayID: 1,
            policeNo: policeNo,
            yenilemeNo: "0",
            zeyilNo: "0",
            SirketKodu: sirketKodu
        }

        this.openYukleniyorModal();

        this.props.getPrint(police);
        let base64Pdf = this.props.policy.print.base64;

        let timer = setInterval(()=> {
            if(base64Pdf !== undefined){
                this.closeYukleniyorModal();
                clearInterval(timer)
                var blob = this.base64ToBlob(base64Pdf);
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                  } else {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                  }
            } 
        }, 5000)
        
        
    }

    base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: 'application/pdf' });
    }

    openYukleniyorModal = () => {
        this.setState({ yukleniyor: true });
      };
    
      closeYukleniyorModal = () => {
        this.setState({ yukleniyor: false });
      };

    tarihChange = (tarih) => {
    let yil = tarih.substring(0, 4);
    let ay = tarih.substring(5, 7);
    let gun = tarih.substring(8, 10);
    return gun + ' / ' + ay + ' / ' + yil
    }

    policeDegisikligi = (item) => {
        let police = {
            brans: item.brans,
            sigortaliUnvan: item.sigortaliUnvan,
            tanzimTarih: this.tarihChange(item.tanzimTarih),
            policeNo: item.policeNo,
            prim: item.prim
        }
        this.props.policeDegisikligi(police);
    }

    policeIade = (item) => {
        let police = {
            brans: item.brans,
            sigortaliUnvan: item.sigortaliUnvan,
            tanzimTarih: this.tarihChange(item.tanzimTarih),
            policeNo: item.policeNo,
            prim: item.prim
        }
        this.props.policeIade(police);
    }
    
  render() {
    return (
      <div>
                 <Modal
          isOpen={this.state.yukleniyor}
          onRequestClose={() => this.closeModal()}
          className="yukleniyor-modal-box"
          style={customStyles}
          ariaHideApp={false}
        >

          <div id="yukleniyor">
                        <img src={wait2} alt="" />
                      </div>
                      <label id="yukleniyorLabel">Lütfen Bekleyiniz...</label>
        </Modal>
          {this.state.data.map((item) =>
            <div className='profil-police-box'>
                <div className='police-column'>
                    <div className='police-title-box'>
                        <label>{item.brans} Poliçeniz</label>
                        <div className='line'></div>
                    </div>
                    <img src={sirketImgURL + item.sirketKodu + ".png"} alt="" className='police-img' />
                    <p style={{marginTop:23}}>{item.sigortaliUnvan}</p>
                </div>
                <div className='police-column'>
                    <div className='police-title-box'>
                        <label>Poliçe Vadesi</label>
                        <div className='line'></div>
                        <p>{this.tarihChange(item.tanzimTarih)}</p>
                    </div>
                    <div className='police-title-box'>
                        <label>Poliçe No</label>
                        <div className='line'></div>
                        <p>{item.policeNo}</p>
                    </div>
                </div>
                <div className='police-column'>
                    <div className='police-title-box'>
                        <label>Prim</label>
                        <div className='line'></div>
                        <p> {new Intl.NumberFormat('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.prim)} TL</p>
                    </div>
                </div>
                <br></br>
                <div className='police-column button-center'>
                    <div className='police-button' onClick={() => this.openBase64NewTab(item.policeNo, item.sirketKodu)}>Poliçe İndir</div>
                    <div className='police-button'>Teklif Çalış</div>
                    <div className='police-button' onClick={()=> this.policeDegisikligi(item)}>Değişiklik Talebi</div>
                    <div className='police-button' onClick={()=> this.policeIade(item)}>İptal Talebi</div>
                </div>
            </div>
          )}
          <Pagination
                currentPage={this.state.currentPage}
                sizePerPage={4}
                changeCurrentPage={this.changeCurrentPage}
                totalSize={this.props.policy.policyList.length}
            />
      </div>
    )
  }
}
const mapStateToProps = state => {
    return {
        auth: state.auth,
        policy: state.policy,
    }
}

export default connect(mapStateToProps,actions)(Policelerim);