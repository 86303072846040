import { combineReducers } from 'redux';
import authReducer from './authReducer';
import commonReducer from './commonReducer';
import customerReducer from './customerReducer';
import proposalReducer from './proposalReducer';
import bilgiBankasiReducer from './bilgiBankasiReducer';
import policyReducer from './policyReducer';
import adminReducer from './adminReducer';

export default combineReducers({
    auth: authReducer,
    common: commonReducer,
    customer: customerReducer,
    proposal: proposalReducer,
    bilgiBankasi: bilgiBankasiReducer,
    policy: policyReducer,
    admin: adminReducer
});