import React, { Component } from 'react'

export class Konut extends Component {
  render() {
    return (
      <div>
        <p><b>Konut Sigortası Nedir?</b></p>
        <p>Konut sigortası, hırsızlık, yangın ve su baskını gibi birçok risk kapsamında evini koruma altına alan sigorta türüdür. Örneğin konut sigortası, istenmeyen olaylar sonucunda evin oturulamaz hâle geldiğinde taşınma ve enkaz kaldırma giderlerini karşılama konusunda hayatını kolaylaştırır.</p>
        <p><b>Konut Sigortası Fiyatları Neye Göre Belirlenir?</b></p>
        <p>Konut sigortası fiyatları yani ödenecek primler, konutunun bulunduğu il ve ilçeye, metrekaresine, binanın yapı tarzına, konutunun bulunduğu kata, alınan güvenlik önlemlerine ve poliçede yer alan teminatlara göre belirlenir. Örneğin poliçende bina teminatı olmadan sadece evinin içindeki eşyaları da teminat altına alabilirsin.</p>
        <p><b>Konut Sigortası Zorunlu mu?</b></p>
        <p>Konut sigortası; yaptırma gibi bir zorunluluğun yoktur. Ancak yasal zorunluluk olmasa bile geniş güvenceler sunduğu için sıklıkla tercih edilen bir sigorta türüdür.</p>
      </div>
    )
  }
}

export default Konut