import React, { Component } from 'react';
import './OzelSaglik.css';

export default class OzelSaglikBanner extends Component {
  render() {
    return (
        <div className="saglik-banner">
            <div className="container saglik-banner-content">
                <div className="saglik-banner-title">
                    <h1>Sağlık Poliçeniz için</h1>
                    <h1>En uygun teklifler</h1>
                    <h1>sunalım</h1>
                </div>
            </div>
        </div>
    )
  }
}
