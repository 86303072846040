import React, { Component } from 'react';
import AboutBanner from './AboutBanner';
import '../profil/Profil.css';

const turkHavakLogo = require('../images/turklogo.png');

class About extends Component {
  render() {
    return (
      <div>
        <AboutBanner/>
        <div className='container about-flex'>
          <div className='about-menu'>
            <div className='turkhavak-box'>
              <img src={turkHavakLogo} alt='' className='turkhavak-logo' />
            </div>
          </div>
          <div className='about-content'>
            <div className='about-content-box'>
              <h3>Hakkımızda</h3>
              <div>
                <p>Türk Havak Sigorta, sigorta sektörünün öncü şirketlerinden olan Mapfre Sigorta, Sompo Sigorta, Groupama Sigorta,
                  Ray Sigorta, Eureko Sigorta, Türkiye Sigorta gibi 6 büyük sigorta şirketinin acentesi olarak: </p>
                <ul>
                  <li>- Sağlık / Seyahat Sağlık / Ferdi Kaza / Eğitim / Birikim Sigortaları</li>
                  <li>- Aracınıza Kasko / Trafik / Yurtdışı Araçla Seyahatler için Yeşilkart Sigortaları</li>
                  <li>- Evinize Dask / Konut Sigortaları</li>
                  <li>- İşyerinizin tüm risklerine karşı İşyeri All Risk Sigortaları</li>
                  <li>- Nakliyat Sigortaları</li>
                  <li>- Site Ortak Alan Sigortaları</li>
                  <li>- İnşaat All Risk Sigortaları</li>
                  <li>- Dijital Güvenlik Sigortaları</li>
                  <li>- Mesleki Sorumluluk Sigortaları</li>
                </ul>
                <p>konularında Vakıf Merkezi’nde hizmet vermektedir. Bireysel ve kurumsal olarak her alandaki poliçelerinizi yaptırabileceğiniz
                  Türk Havak Sigorta’da acentesi olduğumuz sigorta şirketlerinden alınan, üyelerimize özel indirimler ile
                  “maksimum teminat, en uygun fiyat” politikası ile en uygun teklifler hazırlanır.</p>
                <p>Son 10 yılda Türk Havak Sigorta’da 32.000 adetin üzerinde poliçe düzenlenmiştir.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default About